import React, { useState, useEffect } from "react";
import Navbar from "../../components/global-components/navbar";
import Banner from "../../components/section-components/banner";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { baseUrl } from "../../services/config";
import { appColors } from "../../utils/colors";
import { BeatLoader } from "react-spinners";
import { FETCH_AGENCY } from "../../redux/actions/types";
import CustomNavbar from "../../components/global-components/custom-navbar";
import { Divider } from "@material-ui/core";

const AgencyForm = (props) => {
  //redux actions states
  const dispatch = useDispatch();

  // react router dom history var
  const history = useHistory();

  //toast
  const { addToast } = useToasts();

  //component states
  const [loading, setloading] = useState(false);
  const [states, setstates] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedState, setselectedState] = useState("");
  const [logo, setlogo] = useState("");
  const [stateError, setstateError] = useState(false);
  //auth token
  const authToken = localStorage.getItem("userToken");

  const initialValues = {
    phone: "",
    address: "",
    // zip_code: "",
    state_id: "",
    city_id: "",
    about: "",
    name: "",
    email: "",
    website: "",
    description: "",
  };




  const userAgency = useSelector((state) => state.User.agency);


  useEffect(() => {

    if (userAgency) {
      history.push('/add-property')
    }
  }, [])


  useEffect(() => {
    fetch(`${baseUrl}/agency`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + authToken
      }
    }).then(res => res.json())
      .then(data => {
        if (data.metadata.payload) {
          history.push('/add-property')
        }
      })
  }, [])


  useEffect(() => {
    getStates();
  }, []);

  const getStates = () => {
    axios
      .get(`${baseUrl}/list/states`)
      .then((resp) => {

        setstates(resp.data.payload);

      })
      .catch((err) => {
        console.log(err);
      });
  };



  //formik touched/blue css

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const AgencyFormSchema = Yup.object().shape({
    phone: Yup.string()
      .required("Phone number is required")
      .min(10, "Minimum Length 10")
      .max(20, 'Phone No length Maximum 20 ')
    ,
    // cell: Yup.string()
    //   .notRequired('Cell is required'),
    // fax: Yup.string()
    //   .notRequired('fax is required'),
    address: Yup.string().required("Address is required"),
    // zip_code: Yup.string()
    //   .required("Zip code is required")
    //   .min(5, "Zip code should be 5 digits")
    //   .max(5, "Zip code should be 5 digits"),
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    name: Yup.string().required("Name is required"),
    city_id: Yup.string().required("City is required"),
    description: Yup.string().required("Description is required"),
    about: Yup.string().notRequired("about").max(150, "length is 150"),
    website: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      )
      ,
  });




  const formik = useFormik({
    initialValues,
    validationSchema: AgencyFormSchema,
    

    onSubmit: (values, { setStatus, setSubmitting }) => {



      if (selectedState == "Select your State") {
        setstateError(true)

      } else {

        setloading(true);

        let formData = new FormData();

        formData.append("phone", values.phone);
        // formData.append('cell', values.cell)
        formData.append("state_id", selectedState);
        formData.append("city_id", values.city_id);
        formData.append("name", values.name);
        formData.append("website", values.website);
        formData.append("email", values.email);
        formData.append("description", values.description);
        // formData.append('fax', values.fax)
        formData.append("address", values.address);

        //   var photo = {
        //     uri: logo,
        //     type: 'image/jpeg',
        //     name: 'Image.jpg',
        // };

        formData.append("logo", logo);

console.log("selected state",selectedState)
console.log("selected city",values.city_id)

        fetch(`${baseUrl}/agency/update`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,

          },
          body: formData
        }).then(resp => resp.json())
          .then(res => {
            if (res.metadata.success) {
              addToast(res.metadata.message, {
                appearance: "success",
                autoDismiss: true,
              });
              dispatch({
                type: FETCH_AGENCY,
                payload: {
                  agency: res.payload,
                },
              });
              setloading(false);

              addToast("Now you can add your property", {
                appearance: "info",
                autoDismiss: true,
              });

              history.push("/add-property");

            } else if (!res.metadata.success) {
              if (typeof (Object.values(res.metadata.message)) === 'object') {
                Object.values(res.metadata.message).map(msg => {
                  addToast(msg, {
                    appearance: "error",
                    autoDismiss: true,
                  })
                  setloading(false)
                })

              } else {
                addToast(res.metadata.message, {
                  appearance: "error",
                  autoDismiss: true,
                })
                setloading(false)

              }

            }
          })
          .catch((err) => {
            setloading(false);
            addToast(err, {
              appearance: "warning",
              autoDismiss: true,
            });

            console.log("<==Profile_create_Error==>", err);
          });


        // axios
        //   .post(`${baseUrl}/agency`, formData, {
        //     headers: {
        //       Authorization: `Bearer ${authToken}`,
        //     },
        //   })
        //   .then((res) => {

        //     if (res.data.metadata.success) {
        //       addToast(res.data.metadata.message, {
        //         appearance: "success",
        //         autoDismiss: true,
        //       });
        //       dispatch({
        //         type: FETCH_AGENCY,
        //         payload: {
        //           agency: res.data.payload,
        //         },
        //       });
        //       setloading(false);

        //       addToast("Now you can add your property", {
        //         appearance: "info",
        //         autoDismiss: true,
        //       });

        //       history.push("/add-property");

        //     } else if (!res.data.metadata.success) {
        //       if (typeof (Object.values(res.data.metadata.message)) === 'object') {
        //         Object.values(res.data.metadata.message).map(msg => {
        //           addToast(msg, {
        //             appearance: "error",
        //             autoDismiss: true,
        //           })
        //           setloading(false)
        //         })

        //       } else {
        //         addToast(res.data.metadata.message, {
        //           appearance: "error",
        //           autoDismiss: true,
        //         })
        //         setloading(false)

        //       }

        //     }

        //   })
        //   .catch((err) => {
        //     setloading(false);
        //     addToast(err, {
        //       appearance: "warning",
        //       autoDismiss: true,
        //     });

        //     console.log("<==Profile_create_Error==>", err);
        //   });

      }
    }
  });

  const onchangeState = (id) => {

    setstateError(false)
    setselectedState(id);
    axios
      .get(`${baseUrl}/list/states/${id}/cities`)
      .then((resp) => {
        setcityList(resp.data.payload);
      })
      .catch((err) => console.log(err));
  };

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div>
      {/* <Navbar /> */}
      <CustomNavbar />
      <Banner isProfile={true} />
      <div className="signin-page-area">
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework signin-inner"
        >
          <div
            className="container"
            style={{ marginTop: "2%", marginBottom: "2%" }}
          >
            {formik.status ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            ) : null}
            <div className="container border rounded">
              <div className="row" style={{ padding: 10 }}>
                <h2 className="col-md-12 text-center">
                  Add your agency information
                </h2>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center center-my-profile"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">Agency logo</div>
                <div className="col-md-6">
                  <input
                    onChange={(e) => setlogo(e.target.files[0])}
                    style={{ display: "none" }}
                    className="btn btn-base"
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                  />
                  <label htmlFor="imageUpload">
                    <img
                      src={
                        logo
                          ? URL.createObjectURL(logo)
                          : publicUrl + "assets/img/upload.png"
                      }
                      alt="..."
                      style={{
                        height: "150px",
                        width: "150px",
                        border: '2px solid white',
                        borderRadius: "3%",
                        // borderWidth:2,
                        // borderColor:'black',
                        boxShadow:
                          "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                      }}
                    />
                  </label>
                </div>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">
                <span className="">Your Phone</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                </div>
                <div className="col-md-6">
                  <input
                    placeholder="Enter Phone"
                    type="number"
                    className={`form-control ${getInputClasses("phone")}`}
                    name="phone"
                    {...formik.getFieldProps("phone")}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.phone}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">
                <span className="">Your Name</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                  </div>
                <div className="col-md-6">
                  <input
                    placeholder="Enter Name"
                    type="text"
                    className={`form-control ${getInputClasses("name")}`}
                    name="name"
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.name}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">
                <span className="">Your Email</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                </div>
                <div className="col-md-6">
                  <input
                    placeholder="Enter Email"
                    type="email"
                    className={`form-control ${getInputClasses("email")}`}
                    name="email"
                    {...formik.getFieldProps("email")}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.email}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">
                <span className="">Address</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                </div>
                <div className="col-md-6">
                  <input
                    placeholder="Enter valid Address"
                    type="text"
                    className={`form-control ${getInputClasses("address")}`}
                    name="address"
                    {...formik.getFieldProps("address")}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.address}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {/* <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">Zip-code</div>
                <div className="col-md-6">
                  <input
                    placeholder="Enter Zip-code"
                    type="text"
                    className={`form-control ${getInputClasses("zip_code")}`}
                    name="zip_code"
                    {...formik.getFieldProps("zip_code")}
                  />
                  {formik.touched.zip_code && formik.errors.zip_code ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.zip_code}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div> */}

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">
                <span className="">State</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                </div>
                <div className="col-md-6">
                  <select

                    // defaultChecked={selectedState}
                
                    onBlur={() => {
                      if (selectedState == "") {
                        setstateError(true)
                      }
                    }}
                    onChange={(e) => {
                        // console.log(e.target.value);
                      // formik.setFieldValue("state_id", e.target.value);
                      
                      onchangeState(e.target.value)
                    }}
                    name="state_id"
                    // className={`form-control ${getInputClasses("state_id")}`}
                    className={`form-control ${stateError ? 'is-invalid' : 'is-valid'}`}

                    // {...formik.getFieldProps("state_id")}
                  >
                    <option value="Select your State" selected>
                      Select your State
                    </option>
                    {
                      states && states.map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                  </select>

                  {stateError && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        State is Required
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">
                <span className="">City</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                </div>
                <div className="col-md-6">
                  <select
                    name="city_id"
                    className={`form-control ${getInputClasses("city_id")}`}
                    {...formik.getFieldProps("city_id")}
                  >
                    <option value="" disabled selected>
                      Select your City
                    </option>
                    {cityList &&
                      cityList.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                  </select>

                  {formik.touched.city_id && formik.errors.city_id ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.city_id}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">
                <span className="">Desciption</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                </div>
                <div className="col-md-6">
                  <textarea
                    rows="4"
                    cols="50"
                    style={{ height: 150 }}
                    placeholder="Enter Description"
                    type="text"
                    className={`form-control h-auto py-3 px-3 ${getInputClasses(
                      "description"
                    )}`}
                    name="description"
                    {...formik.getFieldProps("description")}
                  />
                  {formik.touched.description && formik.errors.description ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.description}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">
               Website
                    
                </div>
                <div className="col-md-6">
                  <input
                    placeholder="Enter Website"
                    type="text"
                    className={`form-control h-auto py-3 px-3 ${getInputClasses(
                      "website"
                    )}`}
                    name="website"
                    {...formik.getFieldProps("website")}
                  />
                  {formik.touched.website && formik.errors.website ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.website}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {/* <Divider
                style={{
                  margin: 10,
                }}
              /> */}
              {/* <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col">About</div>
                <div className="col">
                  <textarea
                    rows="4"
                    cols="50"
                    maxLength={150}
                    style={{ height: 150 }}
                    placeholder="About yourself"
                    type="text"
                    className={`form-control h-auto py-3 px-3 ${getInputClasses(
                      "about"
                    )}`}
                    name="about"
                    {...formik.getFieldProps("about")}
                  />
                  {formik.touched.about && formik.errors.about ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.about}
                      </div>
                    </div>
                  ) : null}
                  <span className="text-info">Max Length 150 Words</span>

                </div>
              </div> */}

              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                {loading ? (
                  <BeatLoader color={appColors.bluecolor} />
                ) : (
                  <button
                    style={{ backgroundColor: appColors.bluecolor }}
                    type="submit"
                    className="btn btn-base w-100"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AgencyForm;
