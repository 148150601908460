import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from "redux-thunk";
import './index.css'
// import 'bootstrap/dist/css'
import rootReducer from './redux/reducers'


// Redux Persist
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "rootblvdnow",
  storage,
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const middleware = [thunk];
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer,  composeEnhancers(
  applyMiddleware(...middleware),

));
let persistor = persistStore(store);




const root = createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>

      <App />

    </PersistGate>

  </Provider>
  // </React.StrictMode>
);


// ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('mingrand'));
