import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  Marker,
  Popup,

} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet-draw/dist/leaflet.draw.css";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import L from 'leaflet';
import { MAP_API_KEY } from "../../constants/mapApiKey";
import './map.style.css'



const markerIcon = new L.Icon({
  iconUrl: require('./locationlogo.png'),
  iconSize: [30, 45]
})




export const SimpleMap = ({ latlngVal, dragable, onLocationChange }) => {


  const [map, setMap] = useState(null)
  const [mapLayer, setMapLayer] = useState([]);
  const [latlng, setLatlng] = useState(latlngVal);

  useEffect(() => {
    setLatlng(latlngVal);
    console.log(latlng)
  }, [latlngVal]);


  function DisplayPosition({ map }) {
    const [position, setPosition] = useState(() => map.getCenter());

    const onMove = useCallback(() => {
      setPosition(map.getCenter());
    }, [map]);

    useEffect(() => {
      map.setView([latlngVal.lat, latlngVal.lng], 11);

      map.on("move", onMove);
      return () => {
        map.off("move", onMove);
      };
    }, [map, onMove, latlngVal]);

    return <></>;
  }


  function DraggableMarker() {
    const [draggable, setDraggable] = useState(dragable)
    const [position, setPosition] = useState(latlngVal)
    const markerRef = useRef(null)
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            onLocationChange(marker.getLatLng())
          }
        },
      }),
      [],
    )
    const toggleDraggable = useCallback(() => {
      setDraggable((d) => !d)
    }, [])

    return (
      <Marker
        icon={markerIcon}
        draggable={draggable}
        eventHandlers={eventHandlers}
        position={latlngVal}
        ref={markerRef}>
        <Popup minWidth={90}>
          <span onClick={toggleDraggable}>
            {draggable
              ? 'Marker is draggable'
              : 'Click here to make marker draggable'}
          </span>
        </Popup>
      </Marker>
    )
  }




  return (
    <div className="map">
      {map ? <DisplayPosition map={map} /> : null}
      <MapContainer

        className="simple__map"
        center={[latlng.lat, latlng.lng]}
        zoom={15}
        scrollWheelZoom={true}
        ref={setMap}

        dragging={true}
        style={{ width: "100%" }}

      >
        <ReactLeafletGoogleLayer

          apiKey={MAP_API_KEY} type={"roadmap"}>

          <TileLayer

            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />

          <DraggableMarker />

        </ReactLeafletGoogleLayer>
      </MapContainer>
    </div>
  )
}


