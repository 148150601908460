import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeV1 from './components/home-v1';
import HomeV2 from './components/home-v2';
import HomeV3 from './components/home-v3';
import ProptertyDetails from './components/property-details';
import ProptertyGrid from './components/property-grid';
import Propterty from './components/property';
import About from './components/about';
import Team from './components/team';
import SignIn from './Pages/Signin/sign-in';
import SignUp from './Pages//Signup/sign-up';
// import AddProperty from './Pages/Property/AddProperty';
import Contact from './components/contact';
import Blog from './components/blog';
import BlogDetails from './components/blog-details';
// import store from './redux/store'
// import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { FETCH_AGENCY, FETCH_PROFILE, FETCH_USER } from './redux/actions/types';
import { ToastProvider } from 'react-toast-notifications';
import ProfileForm from './Pages/Profile/ProfileForm/ProfileForm';
import AgencyForm from './Pages/AgencyProfile/AgencyForm';
import AddNewProperty from './Pages/Property/AddProperty';
import Profile from './Pages/Profile/Profile';
import "./App.css"
import ForgetPassword from './Pages/Forgetpassword/ForgetPassword';
import EditProfileForm from './Pages/Profile/ProfileForm/EditProfileForm';
import AgencyFromUpdate from './Pages/AgencyProfile/AgencyFromUpdate';
import { SearchProperties } from './Pages/MapSearch/SearchProperties';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import FavProperties from './Pages/FavProperties/FavProperties';
import ScrollToTop from "react-scroll-to-top";
import EditProperty from './Pages/EditProperty/EditProperty';
import ResetPassword from './Pages/ResetPassword/ResetPassword';



function App() {

    const dispatch = useDispatch()

    //geeting states
    // const isAuthenticated = useSelector(state => state.User.isAuthenticated)

    let user = localStorage.getItem('user')
    let newUser = JSON.parse(user)


    return (
        <ToastProvider
            autoDismiss
            autoDismissTimeout={4000}
            placement="top-center"
        >
            <div>



                <Router>
                    <div>

                        <Switch>
                            <Route exact path="/sign-in" component={SignIn} />
                            <Route exact path="/sign-up" component={SignUp} />
                            <Route exact path="/forget-password" component={ForgetPassword} />
                            <Route exact path="/" component={HomeV1} />
                            <Route path="/home-v2" component={HomeV2} />
                            <Route path="/home-v3" component={HomeV3} />
                            <Route path="/profile" component={Profile} />
                            <Route path="/property-details/:id" render={props => <ProptertyDetails {...props} />} />
                            <Route path="/commercial-listing" component={ProptertyGrid} />
                            <Route path="/property" component={Propterty} />
                            <Route path="/about" component={About} />
                            <Route path="/favorite" component={FavProperties} />
                            <Route path="/team" component={Team} />
                            <Route path="/add-property" component={AddNewProperty} />
                            <Route path="/create-profile" component={ProfileForm} />
                            <Route path="/edit-profile" component={EditProfileForm} />
                            <Route path="/editproperty/:id" render={props => <EditProperty {...props} />} />
                            <Route path="/create-agency-profile" component={AgencyForm} />
                            <Route path="/update-agency-profile" component={AgencyFromUpdate} />
                            <Route path="/contact" component={Contact} />
                            <Route path="/blog" component={Blog} />
                            <Route path="/blog-details" component={BlogDetails} />
                            <Route path="/search-properties" component={SearchProperties} />
                            <Route path="/privacy-policy" component={PrivacyPolicy} />
                            <Route path="/reset-password/:token/:email" render={props => <ResetPassword {...props} />} />
                            <Route path={'*'} component={() => (<div>Not found</div>)} />
                        </Switch>


                    </div>
                </Router>
                <ScrollToTop
                    style={{ backgroundColor: '#E83D3D', height: 50, width: 50, cursor: 'pointer' }}
                    smooth color="#fff" />
            </div>
        </ToastProvider>
    )
}

export default App

