import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import ContactForm from './section-components/contact-form';
import Footer from './global-components/footer-v2';
import CustomNavbar from './global-components/custom-navbar';

const ContactPage = () => {

    React.useEffect(() => {

		window.scroll({
			behavior:'smooth',
			top:0
		})

	}, [])
	
    return <div>
        {/* <Navbar /> */}
        <CustomNavbar />
        <PageHeader headertitle="Contact"  />
        <ContactForm />
        <Footer />
    </div>
}

export default ContactPage

