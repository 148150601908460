import React, { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import Navbar from "../../components/global-components/navbar";
import Page_header from "../../components/global-components/page-header";
import "./profile.style.css";
import { Link } from "react-router-dom";
import { baseUrl } from "../../services/config";
import { Divider, Paper } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import axios from "axios";
import { Colors } from "../../constants/colors";
import CustomNavbar from "../../components/global-components/custom-navbar";
import { appColors } from "../../utils/colors";
import UsePagination from '../../components/usePagination/UsePagination'
import { Spinner, Modal } from "react-bootstrap";

let demoimg = `https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg`;

const Profile = () => {
  const authToken = localStorage.getItem("userToken");
  // const userInfo = localStorage.getItem("UserInformation");



  // const User = JSON.parse(localStorage.getItem("user"));
  const [myListing, setmyListing] = useState([]);

  const [deleteModal, setdeleteModal] = useState(false)

  // const UserProfile = JSON.parse(localStorage.getItem("UserProfile"));
  // const UserAgency = JSON.parse(localStorage.getItem("Agencyprofile"));

  const UserProfile = useSelector((state) => state.User.profile);
  const User = useSelector((state) => state.User.user);
  const UserAgency = useSelector((state) => state.User.agency);

  const profile = useSelector((state) => state.User.profile);
  const myProperties = useSelector((state) => state.MyProfile.MyProperties);

  const [links, setlinks] = useState([])
  const [loading, setloading] = useState(false)

  const [selectedId, setselectedId] = useState('')
  const dispatch = useDispatch();

  useEffect(() => {
    getPropertyList(`${baseUrl}/properties`)
  }, []);

 


  const getPropertyList = (url) => {
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        if (res.data) {
          // console.log("Response ", res.data.payload);
          setmyListing(res.data.payload.data);
          setlinks(res.data.payload.links)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }



  // const { user, agency, profile } = User;
  const user = UserProfile;
  const agency = UserAgency;

  console.log("myListing===>", myListing);
  let publicUrl = process.env.PUBLIC_URL + "/";



  const onPaginate = link => {
    getPropertyList(link?.url)
  }


  const deleteFunc = () => {
    setloading(true)
    fetch(`${baseUrl}/properties/${selectedId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`,
      }
    }).then(res => res.json())
      .then((data) => {
        if (data.metadata.success) {
          setloading(false)
          setdeleteModal(false)
          getPropertyList(`${baseUrl}/properties`)
        } else {
          setloading(false)
        }
      }).catch(err => {
        console.log(err)
        setloading(false)
      })
  }


  return (
    <div>
      {/* <Navbar /> */}
      <CustomNavbar />
      <Page_header headertitle="Profile" />

      <div className="container" style={{ marginTop: "2%", marginBottom: '2%' }}>
        {UserProfile && (
          <>
            <div className="container border rounded">
              <div className="row" style={{ padding: 10 }}>
                <h4 style={{ color: `${Colors.primary}` }}>
                  Profile Information
                </h4>
              </div>
              <Divider />
              <div
                className="row center-my-profile"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">Your Photo</div>
                <div className="col-md-6">
                  <img
                    src={UserProfile?.avatar?.original_url}
                    alt="..."
                    style={{
                      height: "150px",
                      width: "150px",
                      border: '2px solid white',
                      borderRadius: "3%",
                      // borderWidth:2,
                      // borderColor:'black',
                      boxShadow:
                        "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                    }}
                  />
                </div>
              </div>

              <Divider />
              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">Name</div>
                <div className="col-md-6 form-control">{User?.name}</div>
              </div>
              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">Phone</div>
                <div
                  className="col-md-6 form-control"
                >
                  {UserProfile?.phone}
                </div>
              </div>

              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">Email</div>
                <div className="col-md-6 form-control">{User?.email}</div>
              </div>
              <Divider />
              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">State</div>
                <div className="col-md-6 form-control">{UserProfile?.state?.name}</div>
              </div>
              <Divider />
              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">City</div>
                <div className="col-md-6 form-control">{UserProfile?.city?.name}</div>
              </div>
              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">Address</div>
                <div className="col-md-6 form-control">{UserProfile?.address}</div>
              </div>
              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">Zip Code</div>
                <div className="col-md-6 form-control">{UserProfile?.zip_code}</div>
              </div>
              <Divider />
              {/* <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">Country</div>
                <div className="col-md-6 form-control">{user?.country}</div>
              </div> */}

              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div style={{ padding: 0}} className="col-md-4 col-sm-12 lableAlign-Left listing-heading">About</div>
                <div style={{ padding: 0 }} className="col-md-6 col-sm-12">
                  <textarea
                    disabled
                    className="form-control textarea-profile"
                    defaultValue={user?.about}
                  ></textarea>
                </div>
              </div>

              <Divider />
              <div
                className="row justify-content-end"
                style={{
                  margin: 10,
                }}
              >
                <Link
                  style={{ backgroundColor: appColors.bluecolor }}
                  to="/edit-profile"
                  className="btn btn-base w-100"
                >
                  Edit
                </Link>
              </div>
            </div>
          </>
        )}

        {UserAgency && (
          <>
            <div className="container border rounded" style={{ marginTop: 10 }}>
              <div className="row" style={{ padding: 10 }}>
                <h4 style={{ color: `${Colors.primary}` }}>
                  Agency Information
                </h4>
              </div>
              <Divider />
              <div
                className="row center-my-profile"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">Agency Photo</div>
                <div className="col-md-6">



                  <img
                    src={agency?.logo?.original_url}
                    alt="..."
                    style={{
                      height: "150px",
                      width: "150px",
                      border: '2px solid white',
                      borderRadius: "3%",
                      boxShadow:
                        "rgba(0, 0, 0, 0.4) 0px 20px 25px -5px, rgba(0, 0, 0, 0.3) 0px 10px 10px -5px",
                    }}
                  />
                </div>
              </div>

              <Divider />
              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">Name</div>
                <div className="col-md-6 form-control">{agency?.name}</div>
              </div>
              {/* <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">Agency Country</div>

                <div className={" col-md-6 form-control"}>{agency?.country}</div>
              </div> */}

              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">Email</div>
                <div className="col-md-6 form-control">{agency?.email}</div>
              </div>

              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">Phone</div>
                <div className="col-md-6 form-control">{agency?.phone}</div>
              </div>

              <Divider />
           
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">State</div>


                <div className="col-md-6 form-control">
                  {agency?.state?.name}
                </div>


              </div>
              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">City</div>


                <div className="col-md-6 form-control">
                  {agency?.city?.name}
                </div>

                <Divider />
              </div>
              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">Address</div>
                <div className="col-md-6 form-control">{agency?.address}</div>
              </div>
              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">Website</div>


                <div className="col-md-6 form-control">
                  {agency?.website ? agency?.website : 'N/A'}
                </div>


              </div>

              {/* <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">About</div>
                <div className="col-md-6 form-control">{agency?.about}</div>
              </div> */}

              <Divider />
              <div
                className="row"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6 lableAlign-Left">Description</div>
                <div style={{ textAlign: 'justify' }} className="col-md-6 form-control">{agency?.description}</div>
              </div>

              <Divider />
              <div
                className="row justify-content-end"
                style={{
                  margin: 10,
                }}
              >
                <Link
                  style={{ backgroundColor: appColors.bluecolor }}
                  to="/update-agency-profile"
                  className="btn btn-base w-100"
                >
                  Edit
                </Link>
              </div>
            </div>
          </>
        )}

        <div className="container border rounded" style={{ marginTop: 10, }}>
          <div className="row" style={{ padding: 10 }}>
            <h4 className="listing-heading" style={{ color: `${Colors.primary}`}}>My Listing</h4>
          </div>
          <Divider />
          <div
            className="row justify-content-center"
            style={{
              margin: 10,
            }}
          >
            {myListing &&
              myListing.map((property, i) => (
                <div className="col-lg-4 col-md-6 con-image card-centring">

                  <div className="single-product-wrap">
                    <Link to={`/property-details/${property.id}`}>
                      <div className="thumb">
                        <img
                          style={{
                            widthd: 350,
                            height: 340,
                          }}
                          src={
                            property.image?.original_url
                              ? property.image?.original_url
                              : demoimg
                          }
                          // src={demoimg}
                          alt="img"
                        />
                        <Link
                          className={property.classification?.id==3?"cat2":'cat'}
                          to={`/property-details/${property.id}`}
                        >
                          {property.classification?.name}
                        </Link>
                      </div>
                    </Link>
                    <div class="overlay"></div>
                    <div className="d-flex">
                      <div class="buttona"><Link className="buttonHover" to={`/editproperty/${property.id}`}>
                        Edit
                      </Link></div>
                      <div class="buttona"><a className="buttonHover" style={{  cursor: 'pointer',color:"red" }} onClick={() => {
                        setselectedId(property.id)
                        setdeleteModal(true)
                      }}>
                        Delete
                      </a></div>
                    </div>

                    <div className="product-wrap-details">
                      <div className="media">
                        <div className="author">
                          <img
                            style={{ height: 50, width: 50, objectFit: 'contain' }}
                            src={publicUrl + "assets/img/logo.png"}
                            alt="img"
                          />
                        </div>
                        <div className="media-body">
                          <h6 >
                           {property.title}
                          </h6>
                          <p>
                            <img
                              src={
                                publicUrl + "assets/img/icon/location-alt.png"
                              }
                              alt="img"
                            />
                            {property.address.substr(0, 30)}{'...'}
                          </p>
                        </div>
                        {/* <a className="fav-btn float-right" href="#">
                          <i className="far fa-heart" />
                        </a> */}
                      </div>
                      <div className="product-meta">
                        <span className="price">$ {property.price}</span>
                        <div className="float-right d-inline-block">
                          <ul>
                            {/* <li>
                              <img
                                src={publicUrl + "assets/img/icon/triangle.png"}
                                alt="img"
                              />
                              2
                            </li>
                            <li>
                              <img
                                src={publicUrl + "assets/img/icon/bed.png"}
                                alt="img"
                              />
                              3
                            </li> */}
                            <li>
                              <img
                                src={publicUrl + "assets/img/icon/wall.png"}
                                alt="img"
                              />
                              {property.size}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>
              ))}
          </div>

          <UsePagination
            links={links}
            onPaginate={onPaginate}
          />
          <Modal
            centered
            size="sm"
            show={deleteModal}
            animation={true}
          >
            <Modal.Header >
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete?
            </Modal.Body>
            <Modal.Footer>
              {
                !loading ? <>
                  <button
                    onClick={deleteFunc}
                    className='btnn btn-success'>
                    Delete
                  </button>
                  <button
                    onClick={() => setdeleteModal(false)}
                    className='btnn btn-danger'>
                    Cancel
                  </button>
                </>
                  :
                  <Spinner animation='border' />
              }

            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};
export default Profile;
