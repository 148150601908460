import { FETCH_USER, LOGIN_USER, LOGIN_ERROR, FETCH_PROFILE, FETCH_AGENCY,UPDATE_LATLNG } from '../actions/types'

const initialState = {
    loading: false,
    isAuthenticated: false,
    agency: null,
    profile: null,
    authToken: null,
    user: {},
    errorMessage: '',
    errorMessageArray: {},
    currentLatlng: []

}




export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_USER:

            return {
                ...state,
                user: action.payload.user,
                loading: false,
                authToken: action.payload.authToken,
                isAuthenticated: action.payload.isAuthenticated,

            }
        case FETCH_PROFILE:

            return {
                ...state,
                profile: action.payload.profile,


            }
        case FETCH_AGENCY:

            return {
                ...state,
                agency: action.payload.agency,


            }
        case LOGIN_USER:
            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                loading: action.payload.loading,
            }
        case LOGIN_ERROR:
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                errorMessageArray: action.payload.errorMessageArray,
                errorMessage: action.payload.errorMessage
            }
        case UPDATE_LATLNG:
            return {
                ...state,
                currentLatlng: action.payload.currentLatlng,

            }


        default:
            return state;
    }

}