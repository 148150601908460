import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import PropertyDetailsPage from './section-components/property-details';
import Footer from './global-components/footer-v2';
import CustomNavbar from './global-components/custom-navbar';

const PropertyDetails = () => {
    return <div>
        {/* <Navbar /> */}
        <CustomNavbar />
        <PageHeader headertitle="Listing Details"  />
        <PropertyDetailsPage />
        <Footer />
    </div>
}

export default PropertyDetails

