import React, { useState, useEffect } from "react";
import Navbar from "../../../components/global-components/navbar";
import Banner from "../../../components/section-components/banner";
import { BeatLoader } from "react-spinners";
import { appColors } from "../../../utils/colors";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { baseUrl } from "../../../services/config";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_PROFILE } from "../../../redux/actions/types";
import Select from "react-select";
import "./profile2.css";
import CustomNavbar from "../../../components/global-components/custom-navbar";
import { Divider } from "@material-ui/core";

const EditProfileForm = (props) => {




  const user = useSelector((state) => state.User.profile);
  // const user = JSON.parse(localStorage.getItem("UserProfile"));


  // console.log("User Data===>", User);

  const { addToast } = useToasts();

  const dispatch = useDispatch();

  const history = useHistory();

  const [loading, setloading] = useState(false);
  const [states, setstates] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [cityListsId, setcityListsId] = useState(user.city.id);
  const [selectedState, setselectedState] = useState("");
  const [errorState, setErrorState] = useState(false);
  const [errorCity, setErrorCity] = useState(false);

  const [logo, setlogo] = useState("");

  const authToken = localStorage.getItem("userToken");

  useEffect(() => {
    getStates();
    onchangeState(user.state?.id)
    // setlogo(user?.avatar.original_url)
  }, []);

  const getStates = () => {
    axios
      .get(`${baseUrl}/list/states`)
      .then((resp) => {
        if (resp.data) {
          let arr = [];

          resp.data.payload.forEach((state) => {
            arr.push({
              id: state.id,
              value: state.id,
              label: state.name,
            });
          });
          setstates(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const initialValues = {
    phone: user.phone,
    address: user.address,
    state_id: "",
    city_id: "",
    zip_code: user.zip_code,
    about: user.about,
    avatar: "",
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const LoginSchema = Yup.object().shape({
    phone: Yup.string()
      .required("Phone No. is required")
      .min(10, "Enter the Valid Mobile number")
      .max(20, "Enter the Valid Mobile number"),
    address: Yup.string().required("Address is required"),
    zip_code: Yup.string().required("Zip Code is required")
      .min(5, 'Zip code should be 5 digits')
      .max(5, 'Zip code should be 5 digits'),
    about: Yup.string().nullable('about').max(150, 'Max Length 150 Words'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,

    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log(values);
      if (selectedState == "") {
        addToast("Please select state", {
          appearance: "error",
          autoDismiss: true,
        });
      } else if (cityListsId == "") {
        addToast("Please select city", {
          appearance: "error",
          autoDismiss: true,
        });
      } else {
        setloading(true);

        let formData = new FormData();
        formData.append("phone", values.phone.toString());
        formData.append("address", values.address);
        formData.append("zip_code", values.zip_code);
        formData.append("state_id", selectedState);
        formData.append("city_id", cityListsId);
        formData.append("about", values.about==null?"":values.about);
        formData.append("avatar", logo);

        axios
          .post(`${baseUrl}/profile/update`, formData, {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          })
          .then((res) => {
            console.log("Response====>", res.data);
            if (res.status === 200) {
              addToast(res.data.metadata.message, {
                appearance: "success",
                autoDismiss: true,
              });
              dispatch({
                type: FETCH_PROFILE,
                payload: {
                  profile: res.data.payload,
                },
              });
              
              // localStorage.setItem(
              //   "Userprofile",
              //   JSON.stringify(res.data.payload)
              // );
              setloading(false);
              history.push('/profile')
            } else {
              console.log(res.data);
              setloading(false);
            }
          })
          .catch((err) => {
            setloading(false);

            console.log("<==Profile_create_Error==>", err);
          });
      }
    },
  });

  const onchangeState = (id) => {
    // const id = e.id;
    setselectedState(id);
    axios
      .get(`${baseUrl}/list/states/${id}/cities`)
      .then((resp) => {
        if (resp.data) {
          let arr = [];

          resp.data.payload.forEach((city) => {
            arr.push({
              id: city.id,
              value: city.id,
              label: city.name,
            });
          });
          setcityList(arr);
          
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let publicUrl = process.env.PUBLIC_URL + "/";




  

  return (
    <div>
      {/* <Navbar /> */}
      <CustomNavbar />
      <Banner isProfile={true} />

      <div className="signin-page-area">
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework signin-inner profile-edit-form"
        >
          <div
            className="container"
            style={{ marginTop: "2%", marginBottom: "2%" }}
          >
            {formik.status ? (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            ) : null}
            <div className="container border rounded">
              <div className="row" style={{ padding: 10 }}>
                <h2 className="col-md-12 text-center">
                  Update your personal information
                </h2>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center center-my-profile"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">Your profile</div>
                <div className="col-md-6">
                  <input
                    onChange={(e) => setlogo(e.target.files[0])}
                    style={{ display: "none" }}
                    className="btn btn-base"
                    type="file"
                    id="imageUpload"
                    accept=".png, .jpg, .jpeg"
                  />
                  <label htmlFor="imageUpload">
                    <img
                      src={
                        logo
                          ? URL.createObjectURL(logo)
                          : user?.avatar?.original_url
                      }
                      alt="..."
                      style={{
                        height: "150px",
                        width: "150px",
                        border:'2px solid white',
                        borderRadius: "3%",

                        boxShadow:
                          "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
                      }}
                    />
                  </label>
                </div>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">
                <span className="">Phone No</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                </div>
                <div className="col-md-6">
                  <input
                    placeholder="Enter Phone"
                    type="number"
                    className={`form-control ${getInputClasses("phone")}`}
                    name="phone"
                    {...formik.getFieldProps("phone")}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.phone}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">
                <span className="">Zip Code</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                </div>
                <div className="col-md-6">
                  <input
                    placeholder="Enter Zip-code"
                    type="text"
                    className={`form-control ${getInputClasses("zip_code")}`}
                    name="zip_code"
                    {...formik.getFieldProps("zip_code")}
                  />
                  {formik.touched.zip_code && formik.errors.zip_code ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.zip_code}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">
                <span className="">Address</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                </div>
                <div className="col-md-6">
                  <input
                    placeholder="Enter valid Address"
                    type="text"
                    className={`form-control ${getInputClasses("address")}`}
                    name="address"
                    {...formik.getFieldProps("address")}
                  />
                  {formik.touched.address && formik.errors.address ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.address}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >

                <div className="col-md-6">
                <span className="">State</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                </div>
                <div className="col-md-6">
                  <Select
                    defaultValue={{ label: user.state?.name, value: user.state?.id }}
                    // defaultValue={defaultValue(states,2)}
                    classNamePrefix="mySelect"
                    placeholder="Select State"
                    aria-label="Default select example"
                    onChange={(e) => onchangeState(e.id)}
                    options={states}
                  />

                  {errorState ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        Please select the State
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">
                <span className="">City</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                </div>
                <div className="col-md-6">
                  <Select

                    defaultValue={{ label: user.city.name, value: user.city.id }}
                    classNamePrefix="mySelect"
                    placeholder="Select City"
                    onChange={(e) => setcityListsId(e.id)}
                    options={cityList}
                  />
                  {errorCity ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        Please select the city
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <Divider
                style={{
                  margin: 10,
                }}
              />
              <div
                className="row align-items-center"
                style={{
                  margin: 10,
                }}
              >
                <div className="col-md-6">About
                </div>
                <div className="col-md-6">
                  <textarea
                    rows="4"
                    cols="50"
                    maxLength={150}
                    placeholder="About yourself"
                    type="text"
                    className={`form-control ${getInputClasses("about")}`}
                    name="about"
                    {...formik.getFieldProps("about")}
                  />
                  {formik.touched.about && formik.errors.about ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block text-danger">
                        {formik.errors.about}
                      </div>
                    </div>
                  ) : null}
                <span className="text-info">Max Length 150 Words</span>

                </div>
              </div>

              <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
                {loading ? (
                  <BeatLoader color={appColors.bluecolor} />
                ) : (
                  <button
                    style={{
                      backgroundColor: appColors.bluecolor,
                      width: "97.77%",
                    }}
                    type="submit"
                    className="btn btn-base"
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>


    </div>
  );
};

export default EditProfileForm;
