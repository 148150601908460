import React, { Component, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { appColors } from "../../utils/colors";
import { BeatLoader } from "react-spinners";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./signin.css";
import axios from "axios";
import { baseUrl } from "../../services/config";
import { useToasts } from "react-toast-notifications";

const Forget_Password = (props) => {


  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();

  const initialValues = {
    email: "",
    password: "",
  };

  const history = useHistory();

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
  });

  useEffect(() => {
    const $ = window.$;
    $("body").addClass("bg-gray");
    if (props.isAuthenticated) {
      history.push("/");
    }
  }, []);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setIsLoading(true);
      const user = {
        email: values.email,
      };

      fetch(`${baseUrl}/password/forget`, {
        method:'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      }).then(response => response.json())
        .then((data) => {
          setIsLoading(false);
          if (data.metadata.success) {
            addToast(data.metadata.message, {
              appearance: "success",
              autoDismiss: true,
            });
          } else if (!data.metadata.success) {
            addToast(data.metadata.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }).catch(err => {
          console.log(err)
        })


      // axios
      //   .post(`${baseUrl}/password/forget`, user)
      //   .then((response) => {
      //     console.log(response);
      //     setIsLoading(false);
      //     addToast(response.data.metadata.message, {
      //       appearance: "success",
      //       autoDismiss: true,
      //     });
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     setIsLoading(false);
      //   });
    },
  });

  return (
    <div className="signin-page-area pd-top-100 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            {props.errorMessage && (
              <div className="alert alert-danger" role="alert">
                {props.errorMessage}
              </div>
            )}

            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework signin-inner"
            >
              {formik.status ? (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : null}
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Email"
                  type="email"
                  className={`form-control h-auto py-3 px-3 ${getInputClasses(
                    "email"
                  )}`}
                  name="email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      {formik.errors.email}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                {isLoading ? (
                  <BeatLoader color={appColors.bluecolor} />
                ) : (
                  <button
                    style={{ backgroundColor: appColors.bluecolor }}
                    type="submit"
                    className="btn btn-base w-100"
                  >
                    Forget Password
                  </button>
                )}
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  Don't have an account?{" "}
                  <Link to={"/sign-up"}>
                    <strong>SignUp</strong>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forget_Password;
