import React, { Component, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { appColors } from "../../utils/colors";
import { BeatLoader } from "react-spinners";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./signin.css";
import axios from "axios";
import { baseUrl } from "../../services/config";
import { useToasts } from "react-toast-notifications";
import { useParams } from 'react-router-dom';

const Reset_Password = (props) => {


  const { token, email } = useParams();




  const [isLoading, setIsLoading] = useState(false);
  const { addToast } = useToasts();

  const initialValues = {
    password_confirmation: "",
    password: "",
  };

  const history = useHistory();

  const LoginSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, "Minimum 8 symbols")
      .max(50, "Maximum 50 symbols")
      .required('Password is required'),

    password_confirmation: Yup.string().when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      )
    }).required('Confirm Password is required')
  });

  useEffect(() => {
    const $ = window.$;
    $("body").addClass("bg-gray");
    if (props.isAuthenticated) {
      history.push("/");
    }
  }, []);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };



  console.log("props arhy hain?", token)
  console.log("props arhy hain?", email)

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setIsLoading(true);
      const user = {
        password: values.password,
        password_confirmation: values.password_confirmation,
        token,
        email
      };
      console.log(user)

      fetch(`${baseUrl}/password/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(user)
      }).then(response => response.json())
        .then((data) => {
          setIsLoading(false);
          if (data.metadata.success) {
            addToast(data.metadata.message, {
              appearance: "success",
              autoDismiss: true,
            });
            history.push('/sign-in')
          } else if (!data.metadata.success) {
            addToast(data.metadata.message, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }).catch(err => {
          console.log(err)
        })


      // axios
      //   .post(`${baseUrl}/password/forget`, user)
      //   .then((response) => {
      //     console.log(response);
      //     setIsLoading(false);
      //     addToast(response.data.metadata.message, {
      //       appearance: "success",
      //       autoDismiss: true,
      //     });
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     setIsLoading(false);
      //   });
    },
  });

  return (
    <div className="signin-page-area pd-top-100 ">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-7">
            {props.errorMessage && (
              <div className="alert alert-danger" role="alert">
                {props.errorMessage}
              </div>
            )}

            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework signin-inner"
            >
              {formik.status ? (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : null}
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Password"
                  type="password"
                  className={`form-control h-auto py-3 px-3 ${getInputClasses(
                    "password"
                  )}`}
                  name="password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      {formik.errors.password}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group fv-plugins-icon-container">
                <input
                  placeholder="Confirm Password"
                  type="password"
                  className={`form-control h-auto py-3 px-3 ${getInputClasses(
                    "password_confirmation"
                  )}`}
                  name="password_confirmation"
                  {...formik.getFieldProps("password_confirmation")}
                />
                {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block text-danger">
                      {formik.errors.password_confirmation}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                {isLoading ? (
                  <BeatLoader color={appColors.bluecolor} />
                ) : (
                  <button
                    style={{ backgroundColor: appColors.bluecolor }}
                    type="submit"
                    className="btn btn-base w-100"
                  >
                    Reset Password
                  </button>
                )}
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  Remember Password?{" "}
                  <Link to={"/sign-in"}>
                    <strong>Login</strong>
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset_Password;
