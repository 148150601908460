import axios from 'axios'
import { baseUrl } from '../../services/config'
import { FETCH_MY_PROPERTY } from './types'


const authToken = localStorage.getItem('userToken')

export const FetchMyProperties = () => async (dispatch) => {


    await fetch(`${baseUrl}/properties`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${authToken}`
        }
    }).then(res => res.json())
        .then(data => {
            dispatch({
                type:FETCH_MY_PROPERTY,
                payload:{
                    myProperties:data.payload
                }
            })
        }).catch(err => {
            console.log(err);
        })


    // await axios.get(, {
    //     headers: {
    //         'Authorization': `Bearer ${authToken}`
    //     }
    // }).then(res => {
    //     if (res.data) {
    //         console.log(res.data)
    //     }
    // }).catch(err => {
    //     console.log(err)
    // })


}