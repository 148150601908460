import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../services/config";
import UsePagination from "../usePagination/UsePagination";
import CircularProgress from "@material-ui/core/CircularProgress";
let demoimg = `https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg`;

// let img1 = ''
// let img2 = 'https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80'
// let img3 = 'https://images.unsplash.com/photo-1600607688960-e095ff83135c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80'
// let img4 = ''


const PropertyGrid = () => {
	const compRef = useRef()
	const authToken = localStorage.getItem("userToken");
	const [loading, setloading] = useState(false);
	const [AllProperties, setAllProperties] = useState([]);
	const [links, setlinks] = useState([])



	const searchProperties = useSelector(
		(state) => state.MyProfile.searchProperties
	);

	// console.log("Properties===> " + searchProperties);

	useEffect(() => {

		let storage = sessionStorage.getItem('PAGE_LINK')
		if (storage) {

			getAllProperties(storage)
		} else {
			getAllProperties(`${baseUrl}/list/for-you-properties`)
		}


		const $ = window.$;

		$("body").addClass("body-bg");

		// if (searchProperties) {
		// setAllProperties(searchProperties);
		// } else {
		//   getAllProperties();
		// }
	}, []);


	useEffect(() => {

		window.scroll({
			behavior: 'smooth',
			top: 0
		})

	}, [])


	const getAllProperties = async (url) => {
		setloading(true);
		await axios
			.get(url, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				console.log("this is property", res.data.payload);
				setAllProperties(res.data.payload.data);
				setlinks(res.data.payload.links)
				setloading(false);
			})
			.catch((err) => {
				console.log(err);
				setloading(false);
			});
	};

	//   useEffect(() => {
	//     const $ = window.$;

	//     $("body").addClass("body-bg");
	//   }, []);

	let publicUrl = process.env.PUBLIC_URL + "/";


	const onPaginate = link => {
		getAllProperties(link?.url)
		window.scroll({
			behavior: 'smooth',
			top: 0
		})
		sessionStorage.setItem('PAGE_LINK', link.url)
	}


	return (
		<div
			ref={compRef}
			className="blog-page-area pt-5">
			{
				loading ?
					<div
						className="mt-5"
						style={{
							flex: 1,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					><CircularProgress size={25} /></div>
					:
					<div className="container">
						<div>
							<div className="row justify-content-center">
								{AllProperties.map((property, index) => (
									<Link
										key={property.id}
										to={`/property-details/${property.id}`}
										className="col-lg-4 col-md-6 card-centring"
									>
										<div className="single-product-wrap">
											<div className="thumb">
												<img
													style={{
														widthd: 350,
														height: 340,
													}}
													src={
														property.image ? property.image?.original_url : demoimg
													}
													alt="img"
												/>
												<Link
													className={property.classification?.id==3?"cat2":'cat'}
													to={`/property-details/${property.id}`}
												>
													{property?.classification?.name}

												</Link>
											</div>
											<div className="product-wrap-details">
												<div className="media">
													<div className="author">
														<img
															style={{ height: 50, width: 50, objectFit: 'contain' }}
															src={publicUrl + "assets/img/logo.png"}
															alt="img"
														/>
													</div>
													<div className="media-body">
														<h6>
															{property.title.slice(0, 20) + '...'}
														</h6>
														<p>
															<img
																src={publicUrl + "assets/img/icon/location-alt.png"}
																alt="img"
															/>
															{property.address}
														</p>
													</div>
													{/* <a className="fav-btn float-right" href="#">
												<i className="far fa-heart" />
											</a> */}
												</div>
												<div className="product-meta">
													<span className="price">$ {property.price}</span>
													<div className="float-right d-inline-block">
														<ul>
															{/* <li>
														<img
															src={publicUrl + "assets/img/icon/triangle.png"}
															alt="img"
														/>
														2
													</li>
													<li>
														<img
															src={publicUrl + "assets/img/icon/bed.png"}
															alt="img"
														/>
														3
													</li> */}
															<li>
																<img
																	src={publicUrl + "assets/img/icon/wall.png"}
																	alt="img"
																/>
																{property.size} sq ft
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</Link>
								))}
							</div>

						</div>
						<UsePagination
							links={links}
							onPaginate={onPaginate}

						/>
					</div>
			}
		</div>
	);
};

export default PropertyGrid;
