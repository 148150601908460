import React from 'react';
import PageHeader from '../../components/global-components/page-header';
import Footer from '../../components/global-components/footer-v2';
import CustomNavbar from '../../components/global-components/custom-navbar';
import Reset_Password from '../../components/section-components/reset-password';


const ResetPassword = () => {

    return (
        <div>
            <CustomNavbar />
            <PageHeader headertitle="Reset password" />
            <Reset_Password />

            <Footer />
        </div>
    )
}

export default ResetPassword