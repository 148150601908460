import React from 'react';
import Navbar from './global-components/navbar';
import PageHeader from './global-components/page-header';
import Blog from './blog-components/blog';
import Footer from './global-components/footer-v2';
import CustomNavbar from './global-components/custom-navbar';

const BlogPage = () => {
    return <div>
        {/* <Navbar /> */}
        <CustomNavbar />
        <PageHeader headertitle="Blogs"  />
        <Blog />
        <Footer />
    </div>
}

export default BlogPage

