import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

class ServiceV3 extends Component {

    render() {

        let publicUrl = process.env.PUBLIC_URL+'/'

    return  <div className="service-area mg-top--50">
			  <div className="container">
			    <div className="row justify-content-center">
			      <div className="col-lg-4 col-md-10">
			        <div className="single-service-wrap style-2 small-padding" style={{backgroundImage: 'url('+publicUrl+'assets/img/service/01.png)'}}>
			          <div className="thumb">
			            <img src={publicUrl+"assets/img/service/1.png"} alt="icon" />
			          </div>
			          <div className="single-service-details">
			            <h4>Sell Property</h4>
			            <p>Buy commercial real estate online with no commission. Get immediate access to all available listings, request documents and schedule visits to view the property.</p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-md-10">
			        <div className="single-service-wrap style-2 small-padding" style={{backgroundImage: 'url('+publicUrl+'assets/img/service/02.png)'}}>
			          <div className="thumb">
			            <img src={publicUrl+"assets/img/service/2.png"} alt="icon" />
			          </div>
			          <div className="single-service-details">
			            <h4>Daily Apartment</h4>
			            <p>Buy commercial real estate online with no commission. Get immediate access to all available listings, request documents and schedule visits to view the property.</p>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-4 col-md-10">
			        <div className="single-service-wrap style-2 small-padding" style={{backgroundImage: 'url('+publicUrl+'assets/img/service/03.png)'}}>
			          <div className="thumb">
			            <img src={publicUrl+"assets/img/service/3.png"} alt="icon" />
			          </div>
			          <div className="single-service-details">
			            <h4>Family House</h4>
			            <p>Buy commercial real estate online with no commission. Get immediate access to all available listings, request documents and schedule visits to view the property.</p>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>

        }
}

export default ServiceV3