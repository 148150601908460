export const FETCH_USER = 'FETCH_USER'
export const NEW_POSTS = 'NEW_POSTS'
export const LOGIN_USER = 'LOGIN_USER'
export const FETCH_PROFILE = 'FETCH_PROFILE'
export const FETCH_AGENCY = 'FETCH_AGENCY'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const FETCH_MY_PROPERTY = 'FETCH_MY_PROPERTY'
export const SEARCH_PROPERTY = 'SEARCH_PROPERTY'
export const SEARCH_QUERY = 'SEARCH_QUERY'
export const UPDATE_LATLNG = 'UPDATE_LATLNG'

