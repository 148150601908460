import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { appColors } from "../../utils/colors";
import { baseUrl } from "../../services/config";
import axios from "axios";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import { SEARCH_PROPERTY, SEARCH_QUERY, UPDATE_LATLNG } from "../../redux/actions/types";
import { MAP_API_KEY } from "../../constants/mapApiKey";
import { usePlacesWidget } from "react-google-autocomplete";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { BeatLoader } from 'react-spinners'


const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    height: 45,
    // maxWidth: '200',
    borderRadius: 0,
  }),

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled ? "red" : "white",
      color: "black",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const customStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    height: 45,
    width: "110w%",
    borderRadius: 0,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled ? "red" : "white",
      color: "black",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const Banner = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();



  const [selectedpurpose, setselectedpurpose] = useState({
    id: 1,
    title: "For Sale",
  });
  const [searchLoading, setsearchLoading] = useState(false)
  const [states, setstates] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [selectedOption, setSelectedOption] = useState({});
  const [selectedCity, setSelectedCity] = useState("");
  const [saleEnable, setsaleEnable] = useState(true);
  const [leaseEnable, setleaseEnable] = useState(false);
  const [businessEnable, setBusinessEnable] = useState(false);
  const [categoryList, setcategoryList] = useState([]);
  const [categoryType, setcategoryType] = useState([]);
  const [selectedCategoryId, setselectedCategoryId] = useState('');
  // const [selectedminPrice, setselectedMinPrice] = useState({});
  // const [selectedmaxPrice, setselectedMaxPrice] = useState({});
  // const [selectedminSize, setselectedMinSize] = useState({});
  // const [selectedmaxSize, setselectedMaxSize] = useState({});
  const [selectAddress, setSelectedAddress] = useState({});

  const [selectedCategoryType, setselectedCategoryType] = useState({});

  const [min_price, setmin_price] = useState('');
  const [max_price, setmax_price] = useState('');
  const [min_size, setmin_size] = useState('');
  const [max_size, setmax_size] = useState('');


  const authToken = localStorage.getItem("userToken");

  let publicUrl = process.env.PUBLIC_URL + "/";

  const PURPOSE = [
    { id: 1, title: "For Sale" },
    { id: 2, title: "For Lease" },
    { id: 3, title: "Businesses For Sale" },

  ];


  // console.log("quick view fo lat lng",selectAddress)

  useEffect(() => {
    async function getCat() {
      await getStates();
      await getCategories();
    }
    getCat();

    // if (categoryList.length<=0) {
    //   onChangePurpose(selectePurpose.id, selectePurpose);
    // }
  }, []);

  const getStates = async () => {
    await axios
      .get(`${baseUrl}/list/states`)
      .then((resp) => {
        console.log(resp.data.payload);
        if (resp.data) {
          let arr = [];

          resp.data.payload.forEach((state) => {
            arr.push({
              id: state.id,
              value: state.id,
              label: state.name,
            });
          });
          setstates(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCategories = async () => {
    await axios
      .get(`${baseUrl}/list/classifications`)
      .then((resp) => {
        if (resp.data) {
          let arr = [];

          resp.data.payload.forEach((cat) => {
            arr.push({
              id: cat.id,
              value: cat.id,
              label: cat.name,
              categories: cat.categories,
            });
          });
          console.log("Array", arr);
          setcategoryList(arr);
          let filterdata = arr.find((item) => item.id == 1);
          let arr2 = [];
          filterdata.categories.forEach((catType) => {
            arr2.push({
              id: catType.id,
              value: catType.id,
              label: catType.name,
              categories: catType.categories,
            });
          });

          setcategoryType(arr2)

          //   onChangePurpose(selectedpurpose.id, selectedpurpose);
        }
        console.log("categoryyyyy", resp.data.payload);
        console.log("Category");
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("Data====>" + categoryList);
  };

  //   useEffect(()=>{
  // 	onChangePurpose(selectedpurpose.id, selectedpurpose);
  //   },[categoryList])

  // const onchangeCategories = (id) => {
  //   setcategoryList(id.id);
  //   console.log("IDdd", id);
  //   setselectedCategoryType(id);
  //   let filterdata = categoryList.filter((item) => item.id == id.id);
  //   console.log("filter dataaaa", filterdata);
  //   let arr2 = [];
  //   filterdata[0].categories.forEach((catType) => {
  //     arr2.push({
  //       id: catType.id,
  //       value: catType.id,
  //       label: catType.name,
  //       categories: catType.categories,
  //     });
  //   });
  //   setcategoryType(arr2);
  // };

  // console.log(selectAddress)

  const onchangeState = (value) => {
    console.log(value);
    setSelectedOption(value);
    axios
      .get(`${baseUrl}/list/states/${value.id}/cities`)
      .then((resp) => {
        if (resp.data) {
          let arr = [];

          resp.data.payload.forEach((city) => {
            arr.push({
              id: city.id,
              value: city.id,
              label: city.name,
            });
          });
          setcityList(arr);
        }
      })
      .catch((err) => console.log(err));
  };

  const selectePurpose = (purpose) => {
    setselectedpurpose(purpose);
    if (purpose.id == 1) {
      setsaleEnable(true);
      setleaseEnable(false);
      setBusinessEnable(false);
    }
    if (purpose.id == 2) {
      setleaseEnable(true);
      setsaleEnable(false);
      setBusinessEnable(false);
    }
    if (purpose.id == 3) {
      setBusinessEnable(true);
      setleaseEnable(false);
      setsaleEnable(false);
    }
  };

  const handleSearch = (e) => {
    // e.preventDefault()
    // if (Object.values(selectedpurpose).length <= 0) {
    // 	addToast('Please select purpose', {
    // 		appearance: 'error',
    // 		autoDismiss: true,

    // 	})
    // }
    //  if (Object.values(selectedCity).length <= 0) {
    // 	addToast('Please select city', {
    // 		appearance: 'error',
    // 		autoDismiss: true,

    // 	})

    // } else if (Object.values(selectedCategory).length <= 0) {
    // 	addToast('Please select category', {
    // 		appearance: 'error',
    // 		autoDismiss: true,

    // 	})}
    // if (Object.values(selectedCategoryType).length <= 0) {
    //   addToast('Please select category type id', {
    //     appearance: 'error',
    //     autoDismiss: true,

    //   })
    // }
    // else if(Object.values(selectAddress).length <= 0){
    //   addToast('Please select Address', {
    // 		appearance: 'error',
    // 		autoDismiss: true,

    // 	})
    // }
    // else {

    // const data = {
    //   classification_id: selectedpurpose.id,
    //   //   city_id: selectedCity.id,
    //   category_id: selectedCategoryType.id,
    //   address:selectAddress.formatted_address,
    //   //   category_id: selectedCategory.id,
    //   //   location: "",
    //   //   min_price: "",
    //   //   max_price: "",
    //   //   min_size: "",
    //   //   max_size: "",
    // }; 

    setsearchLoading(true)

    const fdata = new FormData();
    fdata.append("classification_id", selectedpurpose.id);
    fdata.append("category_id", selectedCategoryId);
    fdata.append("latitude", selectAddress?.geometry?.location.lat());
    fdata.append("longitude", selectAddress?.geometry?.location.lng());


    // if (Object.keys(selectAddress).length==0) {}
    // else{
    // if (Object.values(selectAddress).length > 0) {
    //   if (selectAddress.formatted_address) {
    //     fdata.append("address", selectAddress.formatted_address);
    //   } else {
    //     fdata.append("address", selectAddress.formatted_address);
    //   }
    // }

    // }

    const data = {
      classification_id: selectedpurpose.id,
      category_id: selectedCategoryId ? selectedCategoryId : null,
      city_id:selectedCity,
      latitude: selectAddress?.geometry?.location.lat(),
      longitude: selectAddress?.geometry?.location.lng(),
      min_price: min_price,
      max_price: max_price,
      min_size: min_size,
      max_size: max_size
    }


    // for (var pair of fdata.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    console.log("prperty data ........",data)

    fetch(`${baseUrl}/search/properties`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify(data),
    }).then(res => res.json())
      .then((res) => {
        console.log(res)
        if (res.metadata.success) {
          // if(res.payload.length > 0){
          let checkboxes = res.payload;
          res.payload.map((item, index) => {
            item["StyleId"] = `ref ${index + 1}`;
            checkboxes[index] = item;
          });


          dispatch({
            type: SEARCH_PROPERTY,
            payload: {
              searchProperties: [...checkboxes],

            },
          });
          dispatch({
            type: SEARCH_QUERY,
            payload: {
              selectedPurpose: selectedpurpose,
              selectedcategoryType: selectedCategoryType,
              searchedLocation: selectAddress,
              selectedCity: selectedCity,
              lat: selectAddress?.geometry?.location.lat(),
              lng: selectAddress?.geometry?.location.lng()

            },
          });
          setsearchLoading(false)

          history.push(`/search-properties`);
          // }
          // else{
          //   addToast('Listing Not Found', {
          //     appearance: "error",
          //     autoDismiss: true,
          //   })
          //   setsearchLoading(false)
          // }
        } else if (!res.metadata.success) {
          if (Array.isArray(Object.values(res.metadata.message))) {
            Object.values(res.metadata.message).map(msg => {
              addToast(msg, {
                appearance: "error",
                autoDismiss: true,
              })
              setsearchLoading(false)
            })

          } else {
            addToast(res.metadata.message, {
              appearance: "error",
              autoDismiss: true,
            })
            setsearchLoading(false)

          }

        }

      })
      .catch((err) => {
        setsearchLoading(false)
        console.log("catch block of search ===>", err);
      });
    // }
  };

  function onChangePurpose(id, prp) {


    let tempArr = [];
    if (id === 1) {
      tempArr = categoryList[0];
      category(id, tempArr, prp)
    }
    else if (id === 2) {
      tempArr = categoryList[1];
      category(id, tempArr, prp)
    }
    else if (id === 3) {
      tempArr = categoryList[1];
      category(id, tempArr, prp)
    }
    else {
      console.log("Click")
      history.push('/search-properties')
      // tempArr = categoryList[2];
    }
    // setcategoryList(list.id)
    // let listCat = {...categoryList}
    // console.log('IDdd', list)

  }


  function category(id, tempArr, prp) {

    setselectedpurpose(prp);
    setselectedCategoryType(tempArr);
    let filterdata = categoryList.find((item) => item.id == id);
    // console.log("filter dataaaa", filterdata);
    let arr2 = [];
    filterdata.categories.forEach((catType) => {
      arr2.push({
        id: catType.id,
        value: catType.id,
        label: catType.name,
        categories: catType.categories,
      });
    });
    setcategoryType(arr2);
  }

  function getDataFirst() {
    if (categoryList.length > 0) {
      onChangePurpose(selectedpurpose.id, selectedpurpose);
    }
  }

  const { ref } = usePlacesWidget({
    apiKey: MAP_API_KEY,

    onPlaceSelected: (place) => {
      console.log("Location====>", place);
      setSelectedAddress(place);

    },
    options: {

      types: ["geocode", "establishment"],
      componentRestrictions: { country: "us" },
    },

  });







  useEffect(() => {
    getCurrentLocation()
  }, []);

  const getCurrentLocation = async () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const obj = [position.coords.latitude, position.coords.longitude]

      dispatch({
        type: UPDATE_LATLNG,
        payload: {
          currentLatlng: obj
        }
      })
      // setLatlng(obj)
      // searchPropertiesByCurrentLoc(position.coords.latitude, position.coords.longitude)
    });
  };

  return (
    <div
      className="banner-area banner-area-1 banner-area-bg"
      style={{ background: "url(https://ik.imagekit.io/82ugmoipa/overlay-5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1667116725296)" }}
    >
      <div className="container-fluid" >
        {!props.isProfile ? (
          <div className="banner-area-inner">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="banner-inner text-center">
                  {/* <div className="line" /> */}
                  <h3 className="text-white"><b className="text-dark">Real Investment</b> Thru Encrypted Commercial Real Estate</h3>
                </div>
              </div>
              <div className="container">
             
                <div className="col-12 mt-2 p-0" >
                  {PURPOSE.map((prp) => (
                    <button
                    type="button"
                      onMouseEnter={() => getDataFirst()}
                      style={{
                        color: "white",
                        backgroundColor:
                          prp.id == selectedpurpose.id
                            ? "rgba(0,0,0,0.8)"
                            : "rgba(0,0,0,0.5)",
                      }}
                      onClick={() => onChangePurpose(prp.id, prp)}
                      className={`btn mr-2 col-md-3 text-center bannerTOpButton ${prp.id == 3 ? 'pl-4' : ''}  ${prp.id == selectedpurpose.id ? "btn-success" : ""
                        }`}
                      key={prp.id}
                    >
                      {prp.title}
                    </button>
                  ))}
                </div>
                <div
                  style={{
                    backgroundColor: "rgba(0,0,0,0.8)",
                    padding: 15,
                    // marginBottom: "15rem",
                  }}
                  className="col-lg-12"
                >
                  <div
                    // onSubmit={(e) => {
                    //   e.preventDefault();
                    //   handleSearch()
                    // }}
                    className="main-search-inner">
                    <div className="row custom-gutters-10">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-3">
                        <div className="single-select-inner">
                          <Select
                            styles={colourStyles}
                            placeholder="Category Type"
                            defaultValue={selectedCategoryType.name}
                            options={categoryType}

                            onChange={(item) => {
                              setselectedCategoryId(item.id)
                              setselectedCategoryType(item)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                        <div className="single-select-inner">
                          {/* <GooglePlacesAutocomplete
                            apiKey={MAP_API_KEY}
                            apiOptions={{ language: 'en', region: 'us' }}
                          
                            selectProps={{
                                  
                              // isMulti:true,
                              isClearable:true,
                              placeholder: 'Enter location',
                              selectAddress,
                              onChange: setSelectedAddress,
                            }}
                          /> */}

                          <input
                            ref={ref}
                            style={{
                              height: 44,
                              borderRadius: 0,
                            }}
                            placeholder="Enter Location"
                            className="form-control h-10"
                          />
                        </div>
                      </div>

                      <div className="col-12 col-sm-12 col-md-12 col-lg-3">

                        {
                          searchLoading ?
                            <div className="mt-2">
                              <BeatLoader

                                margin={5}
                                color={appColors.appPrimaryColor} />
                            </div>

                            :

                            <button
                              // type="submit"
                              onClick={handleSearch}
                              style={{
                                color: "white",
                                borderRadius: 0,
                                backgroundColor: '#E83C3C',
                                height: 44,
                                position: "relative",
                                lineHeight: "45px",
                              }}
                              className="btn w-100">
                              <i className="fa fa-search mr-1 mb-5" /> Search
                            </button>



                        }
                      </div>


                      <div class="collapse col-md-12 mt-2" id="collapseExample">
                        <div className="row">
                          <div className="col-md-2">
                            <div className="single-select-inner">
                              <Select
                                styles={customStyles}
                                placeholder="Select state"
                                defaultValue={selectedOption.name}
                                onChange={onchangeState}
                                options={states}
                              />
                              {/* <Select
															styles={customStyles}
															placeholder="Category"
															defaultValue={selectedCategory.name}
															onChange={onchangeCategories}
															options={categoryList}
														/> */}
                            </div>
                          </div>
                          <div
                            className="col-md-2"
                          // style={{ marginLeft: "-3px" }}
                          >
                            <div className="single-select-inner">
                              <Select
                                styles={customStyles}
                                className="react-select-lib"
                                placeholder="Select city"
                                defaultValue={selectedCity.name}
                                onChange={(item) => {
                                  setSelectedCity(item.id)
                                  
                                }}
                                options={cityList}
                              />
                              {/* <Select
															styles={customStyles}
															placeholder="Category Type"
															defaultValue={selectedCategoryType.name}
															options={categoryType}
														/> */}
                            </div>
                          </div>
                          <div
                            className="col-md-2"

                          >

                            <input
                              style={{ height: 45, borderRadius: 0 }}
                              className="form-control"
                              type='number'
                              placeholder="Min Price"
                              min="0"
                              value={min_price}
                              onChange={(e) => {
                                if(e.target.value>=0){
                                  setmin_price(e.target.value)}else{
                                    setmin_price("")
                                  }
                                }
                                }
                            // defaultValue={selectedminPrice.name}
                            // onChange={onchangeState}

                            // options={categoryList}
                            />

                          </div>
                          <div
                            className="col-md-2"

                          >

                            <input
                              type={'number'}
                              style={{ height: 45, borderRadius: 0 }}
                              placeholder="Max Price"
                              min={0}
                              value={max_price}
                              className="form-control"
                              onChange={(e) => {
                                if(e.target.value>=0){
                                  setmax_price(e.target.value)}else{
                                    setmax_price("")
                                  }
                                }
                                }
                            // defaultValue={selectedmaxPrice.name}
                            // onChange={onchangeState}

                            // options={categoryList}
                            />
                          </div>

                          <div className="col-md-2">

                            <input
                              type={'number'}
                              style={{ height: 45, borderRadius: 0 }}
                              className="form-control"
                              // styles={customStyles}
                              placeholder="Min Size"
                              min={0}
                              value={min_size}
                              onChange={(e) => {
                                if(e.target.value>=0){
                                  setmin_size(e.target.value)}else{
                                    setmin_size("")
                                  }
                                }
                                }
                            // defaultValue={selectedminSize.name}
                            // onChange={onchangeState}

                            // options={categoryList}
                            />

                          </div>
                          <div className="col-md-2">

                            <input
                              type={'number'}
                              style={{ height: 45, borderRadius: 0 }}
                              placeholder="Max Size"
                              className="form-control"
                              pattern="[0-9]+"
                              value={max_size}
                              onChange={(e) => {
                                if(e.target.value>=0){
                                  setmax_size(e.target.value)}else{
                                    setmax_size("")
                                  }
                                }
                                }
                            // defaultValue={selectedmaxSize.name}
                            // onChange={onchangeState}

                            // options={categoryList}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ marginBottom: "-25px" }}>
                        <p>
                          <a
                            class="ml-2"
                            data-toggle="collapse"
                            href="#collapseExample"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            style={{ color: "darkgrey" }}
                          >
                            More Options
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
             
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Banner;
