import React from 'react';
import Navbar from '../../components/global-components/navbar';
import PageHeader from '../../components/global-components/page-header';
import Footer from '../../components/global-components/footer-v2';
import SignUp from '../../components/section-components/sign-up';
import CustomNavbar from '../../components/global-components/custom-navbar';

const SignUpPage = () => {
    return <div>
        {/* <Navbar /> */}
        <CustomNavbar />
        <PageHeader headertitle="Sign Up"  />
        <SignUp />
        <Footer />
    </div>
}

export default SignUpPage

