import React, { Component } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

class CallToAction extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";

    return (
      <div className="cta-area pd-top-118 go-top pd-bottom-120 text-center bg-white">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="section-title style-white mb-0">
                <h6>BoulevardCRE</h6>
                <h2 className="text-danger">DOWNLOAD THE APP</h2>
                <p style={{ textAlign: 'justify' }} className="text-dark">
                  For the first time ever for the commercial real estate sector,
                  BoulevardCRE is now introducing its very own branded encrypted
                  online platform for easy and reliable transactions.
                  BoulevardCRE revolutionized the way you buy your next
                  commercial property or business by introducing the free
                  marketplace. BoulevardCRE makes buying commercial property or
                  business as simple as buying the latest iPhone online.
                </p>
              </div>
              <div className="btn-wrap mt-5 pt-3">
                <img
                  src={publicUrl + "assets/img/google.png"}
                  className="w-25"
                />
                <img
                  
                  src={publicUrl + "assets/img/apple.png"}
                  className="w-25"
                />
                {/* <Link className="btn btn-base mr-2" to="/add-property">SUBMIT</Link> */}
                {/* <Link className="btn btn-base ml-2" to="/property-details">BUY NOW</Link> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 mt-3">
              <img src={publicUrl + "assets/img/mobile.png"} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CallToAction;
