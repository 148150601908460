import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Page_header extends Component {

	render() {

		let HeaderTitle = this.props.headertitle;
		let publicUrl = process.env.PUBLIC_URL + '/'
		let Subheader = this.props.subheader ? this.props.subheader : HeaderTitle

		return (

			<div className="breadcrumb-area bg-overlay-2"
			style={{ background: "url(https://ik.imagekit.io/82ugmoipa/overlay-5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1667116725296)" }}


			>
				<div className="container">
					<div className="breadcrumb-inner">
						<div className="section-title text-center">
							<h2 className="page-title">{HeaderTitle}</h2>
							{/* <ul className="page-list">
		          <li className='pr-1'><Link to="/">Home</Link> </li>
		          <li>{ Subheader }</li>
		        </ul> */}
						</div>
					</div>
				</div>
			</div>



		)
	}
}


export default Page_header