import React, { Component, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import { connect } from 'react-redux'
import { SignupUser } from '../../redux/actions/Auth.action'
import { appColors } from '../../utils/colors';
import { BeatLoader } from 'react-spinners';
import { useFormik } from "formik";
import * as Yup from "yup";
import './signUp.css';

const SignUp = (props) => {
	const history = useHistory()
	const __init = {
		name: '',
		email: '',
		password: '',
		password_confirmation: ''
	}



	const LoginSchema = Yup.object().shape({
		name: Yup.string()
			.required('Name is required'),
		email: Yup.string()
			.email("Wrong email format")
			.min(3, "Minimum 3 symbols")
			.max(50, "Maximum 50 symbols")
			.required('Email is required'),
		password: Yup.string()
			.min(8, "Minimum 8 symbols")
			.max(50, "Maximum 50 symbols")
			.required('Password is required'),

		password_confirmation: Yup.string().when("password", {
			is: val => (val && val.length > 0 ? true : false),
			then: Yup.string().oneOf(
				[Yup.ref("password")],
				"Both password need to be the same"
			)
		}).required('Confirm Password is required')

	});



	//states

	const [credentials, setCredentials] = useState(__init);
	const [error, seterror] = useState('')


	const changeCredentials = (e) => {
		seterror('')
		setCredentials({
			...credentials,
			[e.target.name]: e.target.value,
		});
	};

	useEffect(() => {
		const $ = window.$;

		$('body').addClass('bg-gray');
	}, [])


	const handleSignup = (e) => {
		e.preventDefault();
		const emailrgx = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/

		if (credentials.name == "" || credentials.email == "" || credentials.password == "" || credentials.password_confirmation == "") {

			seterror('Please fill all fields')
		} else if (credentials.password != credentials.password_confirmation) {

			seterror('Password must be same')

		} else if (emailrgx.test(credentials.email) == false) {
			seterror('Email is not valid')
		}

		else {
			// console.log(credentials)
			props.SignupUser(credentials, history)
		}
	}

	// console.log(props.errorMessageArray)


	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return "is-invalid";
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return "is-valid";
		}

		return "";
	};



	const formik = useFormik({
		initialValues: __init,
		validationSchema: LoginSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			const user = {
				email: values.email,
				password: values.password,
				name: values.name,
				password_confirmation: values.password_confirmation,
			}
			props.SignupUser(user, history)
		}
	})

	return (<div className="signup-page-area pd-top-100">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-xl-6 col-lg-7">
					<form onSubmit={formik.handleSubmit} className="signin-inner">
						{/* {
							error && <div className="alert alert-danger" role="alert">
								{error}
							</div>

						} */}
						{
							props.errorMessageArray && Object.values(props.errorMessageArray).map((message) => (
								<div className="alert alert-danger" role="alert">
									{message}
								</div>
							))

						}
						{formik.status ? (
							<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
								<div className="alert-text font-weight-bold">{formik.status}</div>
							</div>
						) : (
							null
							// <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
							//   <div className="alert-text ">
							//     Use account <strong>admin@demo.com</strong> and password{" "}
							//     <strong>demo</strong> to continue.
							//   </div>
							// </div>
						)}
						<div className="row">

							<div className="col-12 form-group fv-plugins-icon-container">


								<input
									className={`form-control h-auto py-3 px-3 ${getInputClasses(
										"name"
									)}`}

									onChange={changeCredentials}
									type="text"
									name='name'
									placeholder="Name"
									{...formik.getFieldProps("name")}
								/>

								{formik.touched.name && formik.errors.name ? (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block text-danger">{formik.errors.name}</div>
									</div>
								) : null}
							</div>
							<div className="col-12 form-group fv-plugins-icon-container">

								<input
									className={`form-control h-auto py-3 px-3 ${getInputClasses(
										"email"
									)}`}

									onChange={changeCredentials}
									type="email"
									name='email'
									placeholder="Email"
									{...formik.getFieldProps("email")}
								/>
								{formik.touched.email && formik.errors.email ? (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block text-danger">{formik.errors.email}</div>
									</div>
								) : null}
							</div>
							<div className="col-12 form-group fv-plugins-icon-container">

								<input
									className={`form-control h-auto py-3 px-3 ${getInputClasses(
										"password"
									)}`}
									onChange={changeCredentials}
									type="password"
									name='password'
									placeholder="Password"
									{...formik.getFieldProps("password")}
								/>
								{formik.touched.password && formik.errors.password ? (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block text-danger">{formik.errors.password}</div>
									</div>
								) : null}
							</div>
							<div className="col-12 form-group fv-plugins-icon-container">
								<input
									className={`form-control h-auto py-3 px-3 ${getInputClasses(
										"password_confirmation"
									)}`}
									onChange={changeCredentials}
									type="password"
									name='password_confirmation'
									placeholder="Confirm Password"
									{...formik.getFieldProps("password_confirmation")}
								/>
								{formik.touched.password_confirmation && formik.errors.password_confirmation ? (
									<div className="fv-plugins-message-container">
										<div className="fv-help-block text-danger">{formik.errors.password_confirmation}</div>
									</div>
								) : null}
							</div>
							<div className="col-12 mb-4">
								{
									props.isLoading ?
										<BeatLoader margin={10} color={appColors.bluecolor} />
										:
										<button
											style={{ backgroundColor: appColors.bluecolor }}
											type='submit'
											className="btn btn-base w-100">Create Account</button>
								}
							</div>
							<div className="col-12">
								<span>Already have an account </span>
								<Link to={'/sign-in'}><strong>Signin</strong></Link>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>

	)
}

const mapStateToProps = state => ({
	isLoading: state.User.loading,
	errorMessageArray: state.User.errorMessageArray,

})

export default connect(mapStateToProps, { SignupUser, })(SignUp);