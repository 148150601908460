import React, { Component, useEffect } from 'react';
import { Link, useHistory, } from 'react-router-dom';
import { useSelector, connect, useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { FetchMyProperties } from '../../redux/actions/Property.action'
import { appColors } from '../../utils/colors';
import './nav.css'
import { FETCH_USER } from '../../redux/actions/types';

const Navbar = (props) => {
	//toast
	const { addToast } = useToasts()

	//history
	const history = useHistory()

	const dispatch = useDispatch()

	const isAuthenticated = useSelector(state => state.User.isAuthenticated)
	const userProfile = useSelector(state => state.User.profile)
	const userAgency = useSelector(state => state.User.agency)

	const authtoken = localStorage.getItem('userToken');

	useEffect(() => {
		props.FetchMyProperties()
	}, [])




	// console.log(user)

	let publicUrl = process.env.PUBLIC_URL + '/'
	let imgattr = 'logo'

	const gotoAddProperty = () => {
		if (!isAuthenticated) {
			history.push('/sign-in')
		} else if (userProfile == null) {
			addToast('Please fill your Profile 1st', {
				appearance: 'error',
				autoDismiss: true,

			})
			history.push('/create-profile')
		}
		else if (userAgency == null) {
			addToast('Please create your agency profile', {
				appearance: 'error',
				autoDismiss: true,

			})

			history.push('/create-agency-profile')
		} else {
			console.log('this part')
			history.push('/add-property')
		}
	}


	const logOut = () => {
		dispatch({
			type: FETCH_USER,
			payload: {
				isAuthenticated: false,
				user: {},
				loading: false,
				authToken: null
			}
		})
		localStorage.clear()
		history.replace('/sign-in')
	}

	// to={isAuthenticated ? "/add-property" : '/sign-in'}

	return (
		<div className="navbar-area navbar-area-1">
			{/* navbar top start */}
			<div className="navbar-top">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 text-lg-left text-center">
							<ul>
								{/* <li><p><img src={publicUrl + "assets/img/icon/location.png"} alt="img" /> 420 Love Sreet 133/2 flx City</p></li>  */}
								{/* <li><p className='text-dark font-weight-bold'><img src={publicUrl + "assets/img/icon/phone.png"} alt="img" /> +1 (916) 793-4048</p></li> */}
								{/* <li><p className='text-dark font-weight-bold'><img src={publicUrl + "assets/img/icon/envelope.png"} alt="img" />info@theboulevard.us</p></li> */}
							</ul>
						</div>
						<div className="col-lg-4">

							<ul className="topbar-right text-lg-right text-center">
								{
									authtoken ?
										<li>
											<Link onClick={logOut} className={'text-dark font-weight-bold'}>Logout</Link>
											<Link to={'/profile'} className={'text-dark font-weight-bold'}>My Account</Link>
										</li>
										:
										<>

											<li>
												<Link className="ml-0 text-dark font-weight-bold" to="/sign-up">Register</Link>
												<Link to="/sign-in" className={'text-dark font-weight-bold'}>Login</Link>
											</li>

										</>

								}
								<li className="social-area">
									{/* <a href="https://www.facebook.com/solverwp/"><i className="fab fa-facebook-f" aria-hidden="true" /></a>
									<a href="https://www.twitter.com/solverwp/"><i className="fab fa-twitter" aria-hidden="true" /></a>
									<a href="https://www.instagram-plus.com/solverwp/"><i className="fab fa-instagram" aria-hidden="true" /></a>
									<a href="https://www.skype.com/solverwp/"><i className="fab fa-skype" aria-hidden="true" /></a>
									<a href="https://www.pinterest.com/solverwp/"><i className="fab fa-pinterest-p" aria-hidden="true" /></a> */}
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<nav className="navbar navbar-expand-lg">
				<div className="container nav-container">
					<div className="responsive-mobile-menu">
						<button className="menu toggle-btn d-block d-lg-none" data-target="#dkt_main_menu" aria-expanded="false" aria-label="Toggle navigation">
							<span className="icon-left" />
							<span className="icon-right" />
						</button>
					</div>
					<div className="logo">
						<Link to="/"><img src={publicUrl + "assets/img/logo-white.png"} alt="img" width={180} /></Link>
					</div>
					<div className="nav-right-part nav-right-part-mobile">
						<ul>
							<li><a className="search" href="#"><i className="fa fa-search" /></a></li>
							<li><Link className="btn btn-base" to="/add-property"><span className="btn-icon"><i className="fa fa-plus" /></span> <span className="btn-text">SUBMIT PROPERTY</span></Link></li>
						</ul>
					</div>
					<div className="collapse navbar-collapse" id="dkt_main_menu">
						<ul className="navbar-nav menu-open text-center">
							<li className="menu-item-has-children current-menu-item">
								<Link to="/">Home</Link>
								{/* <ul className="sub-menu">
										<li><Link to="/">Home 01</Link></li>
										<li><Link to="/home-v2">Home 02</Link></li>
										<li><Link to="/home-v3">Home 03</Link></li>
									</ul> */}
							</li>
							{/* <li className="menu-item-has-children current-menu-item">
								<Link to="/">Property</Link> */}
							{/* <ul className="sub-menu">
									<li><Link to="/property">Property</Link></li>
									<li><Link to="/property-grid">Property Grid</Link></li>
									<li><Link to="/property-details">Property Details</Link></li>
								</ul> */}
							{/* </li> */}
							<li className="menu-item-has-children current-menu-item">
								<Link to="/about">About</Link>
								{/* <ul className="sub-menu">
									<li><Link to="/about">About</Link></li>
									<li><Link to="/team">Team</Link></li> */}
								{/* <li><Link to="/sign-in">Sign In</Link></li>
									<li><Link to="/sign-up">Sign Up</Link></li>
									<li><Link to="/add-property">Add Property</Link></li> */}
								{/* </ul> */}
							</li>
							<li className="menu-item-has-children current-menu-item">
								<li><Link to="/blog">Blogs</Link></li>
								{/* <ul className="sub-menu">
									<li><Link to="/blog">Blog</Link></li>
									<li><Link to="/blog-details">Blog Details</Link></li>
								</ul> */}
							</li>
							<li><Link to="/contact">Contact</Link></li>
							{/* <li><Link to="/privacy-policy">Privacy Policy</Link></li> */}
						</ul>
					</div>
					<div
						onClick={gotoAddProperty}
						className="nav-right-part nav-right-part-desktop">
						<ul>
							<li><a className="search" href="#"><i className="fa fa-search" /></a></li>
							<li>
								<p style={{ backgroundColor: appColors.appsecondaryColor }} className="btn nav-btn"><span className="btn-icon"><i style={{ backgroundColor: appColors.appPrimaryColor }} className="fa fa-plus" /></span> <span className="btn-text">SUBMIT PROPERTY</span>
								</p>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</div>

	)

}


export default connect(null, { FetchMyProperties })(Navbar)