import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { FETCH_AGENCY, FETCH_PROFILE, FETCH_USER } from "../../redux/actions/types";
import { FetchMyProperties } from "../../redux/actions/Property.action";
// import component 👇
import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import './style.css'
import { useState } from "react";

const CustomNavbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  //toast
  const { addToast } = useToasts();

  //history
  const history = useHistory();

  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.User.isAuthenticated);
  const userProfile = useSelector((state) => state.User.profile);
  const userAgency = useSelector((state) => state.User.agency);

  const authtoken = localStorage.getItem("userToken");


  // const unparsedProfile =  JSON.parse(localStorage.getItem("UserProfile"))
  // const unparsedAgency =  JSON.parse(localStorage.getItem("Agencyprofile"))


  // const userProfile = unparsedProfile
  // const userAgency = unparsedAgency;

  useEffect(() => {
    FetchMyProperties();
  }, []);

  // console.log(userProfile)
  // console.log(userAgency)

  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "logo";

  const gotoAddProperty = () => {
    if (!isAuthenticated) {
      history.push("/sign-in");
    } else if (!userProfile) {
      addToast("Please fill your Profile 1st", {
        appearance: "error",
        autoDismiss: true,
      });
      history.push("/create-profile");
    } else if (!userAgency) {
      addToast("Please create your agency profile", {
        appearance: "error",
        autoDismiss: true,
      });

      history.push("/create-agency-profile");
    } else {
      console.log("this part");
      history.push("/add-property");
    }
  };

  const logOut = () => {
    dispatch({
      type: FETCH_USER,
      payload: {
        isAuthenticated: false,
        user: {},
        loading: false,
        authToken: null,
      },
    });
    dispatch({
      type: FETCH_AGENCY,
      payload: {
        agency: null
      },
    });
    dispatch({
      type: FETCH_PROFILE,
      payload: {
        profile: null
      },
    });
    localStorage.clear();
    history.replace("/sign-in");
  };

  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className="navbar-area navbar-area-1">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div>
            <Link to="/">
              <img
                className="img-fluid "
                src={publicUrl + "assets/img/logo.png"}
                style={{ height: 'auto', width: 200, marginTop: 10 }}
              />
            </Link>
          </div>
          <div className="col d-flex justify-content-end align-items-center">
            <div style={{ marginRight: 10, cursor: 'pointer' }}>
              <h5 className="link__listing fornone" onClick={gotoAddProperty}>
                <i class="bi bi-plus"></i>Add Listing
              </h5>
            </div>
            {authtoken ? (
              <>
                <div style={{ marginRight: 10 }}>
                  <h5
                    className="text-danger fornone font-weight-bold"
                    style={{ padding: 5 }}
                  >
                    <Link to={"/profile"}>My Account</Link>
                  </h5>

                </div>
                <div style={{ marginRight: 10 }}>
                  <h5
                    className="text-danger fornone font-weight-bold"
                    style={{ padding: 5 }}
                  >
                    <Link onClick={logOut}>Logout</Link>
                  </h5>
                </div>
              </>
            ) : (
              <>
                <div className="fornone" style={{ marginRight: 10 }}>
                  <div
                    className="font-size-h2  font-weight-bold d-flex align-items-center"
                    style={{ padding: 5 }}
                  >
                    <h5 className="text-danger">
                      <Link to="/sign-in">Login /</Link>
                    </h5>
                    <h5 className="text-danger">
                      <Link to="/sign-up">Signup</Link>
                    </h5>
                  </div>
                </div>
              </>
            )}
            <i
              className="bi bi-list"
              style={{ fontSize: 30, fontWeight: "bold" }}
              onClick={toggleDrawer}
            ></i>
          </div>
        </div>
        <Drawer
          style={{
            height: '100%'
          }}
          open={isOpen}
          onClose={toggleDrawer}
          direction="right"
          className="drawer-nav"
        >
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            {
              isAuthenticated ?
                <>
                  <li className="formobile-show">
                    <Link onClick={gotoAddProperty}>Add Listing</Link>
                  </li>
                  <li className="formobile-show">
                    <Link to="/profile">My Account</Link>
                  </li>

                </>
                :
                <li className="formobile-show">
                  <Link to={'/sign-in'}>Login</Link>
                </li>
            }
            {
              isAuthenticated ?
                <li>
                  <Link to="/favorite">Favourite</Link>
                </li>
                : null
            }
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/blog">Blogs</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            {
              isAuthenticated ?
                <li className="formobile-show">
                  <Link onClick={logOut}>Logout</Link>
                </li>
                : null

            }
          </ul>
        </Drawer>
      </div>
    </div>
  );
};

export default CustomNavbar;
