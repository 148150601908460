import React, { useEffect, useState } from "react";

import CustomNavbar from '../../components/global-components/custom-navbar'
import Page_header from '../../components/global-components/page-header'
import { Divider, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Colors } from "../../constants/colors";
import { baseUrl } from "../../services/config";
import axios from "axios";
import { IoMdHeartEmpty } from 'react-icons/io'
import CircularProgress from "@material-ui/core/CircularProgress";
import UsePagination from "../../components/usePagination/UsePagination";


let demoimg = `https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg`;

const FavProperties = () => {
    const authToken = localStorage.getItem("userToken");

    const [loading, setloading] = useState(false)
    const [links, setlinks] = useState([])



    useEffect(() => {
        getFavListing(`${baseUrl}/favorites-properties`)
    }, []);


    const getFavListing = (url) => {
        setloading(true)
        axios
            .get(url, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            })
            .then((res) => {
                if (res.data) {
                    setmyListing(res.data.payload.data);
                    setlinks(res.data.payload.links)
                    setloading(false)
                } else {
                    setloading(false)
                }
            })
            .catch((err) => {
                console.log(err);
                setloading(false)
            });
    }

    const [myListing, setmyListing] = useState([]);
    let publicUrl = process.env.PUBLIC_URL + "/";


    const onPaginate = link => {
        getFavListing(link?.url)
      }
    


    return (
        <div>
            <CustomNavbar />
            <Page_header headertitle="Favourite Properties" />

            {loading ? <div
                className="mt-5"
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            ><CircularProgress size={25} /></div> :

                <div className="container border rounded" style={{ marginTop: 10 }}>
                    <div className="row" style={{ padding: 10 }}>
                        <h4 style={{ color: `${Colors.primary}` }}>Favourite Listing</h4>
                    </div>
                    <Divider />
                    <div
                        className="row justify-content-center"
                        style={{
                            margin: 10,
                        }}
                    >
                        {myListing.length > 0 ?

                            myListing.map((property, i) => (
                                <div key={property.id} className="col-lg-4 col-md-6 card-centring">
                                    <div className="single-product-wrap">
                                        <Link to={`/property-details/${property.id}`}>
                                            <div className="thumb">
                                                <img
                                                    style={{
                                                        widthd: 350,
                                                        height: 340,
                                                    }}
                                                    src={
                                                        property.image?.original_url
                                                            ? property.image?.original_url
                                                            : demoimg
                                                    }
                                                    // src={demoimg}
                                                    alt="img"
                                                />
                                                <Link
                                                    className="cat"
                                                    to={`/property-details/${property.id}`}
                                                >
                                                    {property.classification?.name}
                                                </Link>
                                            </div>
                                        </Link>
                                        <div className="product-wrap-details">
                                            <div className="media">
                                                <div className="author">
                                                    <img
                                                     style={{ height: 50, width: 50, objectFit: 'contain' }}
                                                        src={publicUrl + "assets/img/logo.png"}
                                                        alt="img"
                                                    />
                                                </div>
                                                <div className="media-body">
                                                    <h6>
                                                        <Link to="/property">{property.title}</Link>
                                                    </h6>
                                                    <p>
                                                        <img
                                                            src={
                                                                publicUrl + "assets/img/icon/location-alt.png"
                                                            }
                                                            alt="img"
                                                        />
                                                        {property.address.substr(0, 30)}{'...'}
                                                    </p>
                                                </div>

                                            </div>
                                            <div className="product-meta">
                                                <span className="price">$ {property.price}</span>
                                                <div className="float-right d-inline-block">
                                                    <ul>


                                                        <li>
                                                            <img
                                                                src={publicUrl + "assets/img/icon/wall.png"}
                                                                alt="img"
                                                            />
                                                            {property.size}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                            ))
                            :
                            <div
                                style={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                            >
                                <p>Not Added Yet</p>
                                <p>Go to Listing and Add your Favourite Property</p>

                            </div>
                        }
                    </div>
                </div>

            }
                                    {myListing.length > 0 ?   <UsePagination
                                        links={links}
                                        onPaginate={onPaginate}
                                    />:null}
        </div>
    )
}

export default FavProperties