import React from 'react'
import Navbar from '../../components/global-components/navbar'
import PageHeader from '../../components/global-components/page-header';
import AddProperty from '../../components/section-components/add-property';
import Footer from '../../components/global-components/footer-v2';
import AddPropertyForm from './AddPropertyForm/AddPropertyForm';
import CustomNavbar from '../../components/global-components/custom-navbar';

const AddNewProperty = () => {
    return (
        <div>
            {/* <Navbar /> */}
            <CustomNavbar />
            <PageHeader headertitle="Add Listings" />
            <AddProperty />
            <Footer />
        </div>
    )
}

export default AddNewProperty