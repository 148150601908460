import {
  FETCH_AGENCY,
  FETCH_PROFILE,
  FETCH_USER,
  LOGIN_ERROR,
  LOGIN_USER,
} from "./types";
import { api, headers } from "../../utils/apis";
import { baseUrl } from "../../services/config";

export const LoginUser = (data, history) => async (dispatch) => {
  dispatch({
    type: LOGIN_USER,
    payload: {
      loading: true,
    },
  });
  dispatch({
    type: LOGIN_ERROR,
    payload: {
      errorMessage: '',
    },
  });
  await fetch(`${baseUrl}/login`, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  }).then(async (res) =>
    res
      .json()
      .then(async (response) => {
        // console.log(response)
        if (response.metadata.success) {
          // dispatch({
          //     type: LOGIN_USER,
          //     payload: {
          //         isAuthenticated: true,

          //     }
          // })
          dispatch({
            type: FETCH_USER,
            payload: {
              isAuthenticated: true,
              user: response.payload.user,
              loading: false,
              authToken: response.payload.token,
            },
          });
          dispatch({
            type: FETCH_PROFILE,
            payload: {
              profile: response.payload.user.profile,
            },
          });
          dispatch({
            type: FETCH_AGENCY,
            payload: {
              agency: response.payload.user.agency,
            },
          });
          localStorage.setItem("userToken", response.payload.token);
          // localStorage.setItem(
          //   "user",
          //   JSON.stringify(response.payload.user)
          // );
          // localStorage.setItem(
          //   "UserProfile",
          //   JSON.stringify(response.payload.user.profile)
          // );
          // localStorage.setItem(
          //   "Agencyprofile",
          //   JSON.stringify(response.payload.user.agency)
          // );
          // localStorage.setItem("Users", JSON.stringify(response.payload));
          history.push("/");
        } else if (!response.metadata.success) {
          dispatch({
            type: LOGIN_USER,
            payload: {
              isAuthenticated: false,
              user: {},
              loading: false,
            },
          });
          dispatch({
            type: LOGIN_ERROR,
            payload: {
              errorMessage: response.metadata.message,
            },
          });
        } else {
          dispatch({
            type: LOGIN_ERROR,
            payload: {
              errorMessage: `Somthing went wrong`,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
  );
};

export const SignupUser = (data, history) => (dispatch) => {
  dispatch({
    type: LOGIN_USER,
    payload: {
      loading: true,
    },
  });
  dispatch({
    type: LOGIN_ERROR,
    payload: {
      errorMessageArray: [],
    },
  });
  fetch(api.signup, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then(async (response) => {
      console.log(response);
      if (response.metadata.success) {
        dispatch({
          type: FETCH_USER,
          payload: {
            isAuthenticated: true,
            user: response.payload.user,
            loading: false,
            authToken: response.payload.token,
          },
        });
        localStorage.setItem("userToken", response.payload.token);
        // localStorage.setItem(
        //   "user",
        //   JSON.stringify(response.payload.user)
        // );
        // localStorage.setItem(
        //   "UserProfile",
        //   JSON.stringify(response.payload.user.profile)
        // );
        // localStorage.setItem(
        //   "Agencyprofile",
        //   JSON.stringify(response.payload.user.agency)
        // );
        // localStorage.setItem("Users", JSON.stringify(response.payload));
        history.push("/");
      } else if (!response.metadata.success) {
        dispatch({
          type: LOGIN_ERROR,
          payload: {
            errorMessageArray: response.metadata.message,
          },
        });
        dispatch({
          type: LOGIN_USER,
          payload: {
            isAuthenticated: false,
            user: {},
            loading: false,
          },
        });
      } else {
        dispatch({
          type: LOGIN_USER,
          payload: {
            isAuthenticated: false,
            user: {},
            loading: false,
          },
        });
      }
    });
};
