import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../services/config";
import { BeatLoader } from "react-spinners";
import { appColors } from "../../utils/colors";
import { BsBuilding } from 'react-icons/bs'
let demoimg = `https://thumbs.dreamstime.com/b/no-image-available-icon-flat-vector-no-image-available-icon-flat-vector-illustration-132482953.jpg`;

const Product = () => {
  const authToken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);
  const [AllProperties, setAllProperties] = useState([]);

  useEffect(() => {
    getAllProperties();
  }, []);

  const getAllProperties = () => {
    setloading(true);
    axios
      .get(`${baseUrl}/list/for-you-properties`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        console.log("this is property", res.data.payload);
        setAllProperties(res.data.payload.data);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="product-area pd-top-118 pd-bottom-90">
      <div className="container">
        <div className="section-title text-center">
          <h6>We are offering the best real estate</h6>
          <h2>Featured Listings</h2>
        </div>

        {loading && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <BeatLoader color={appColors.bluecolor} />
          </div>
        )}
        <div className="row justify-content-center">
          {AllProperties && AllProperties.slice(0, 3).map((property, index) => (
            <Link
              key={index}
              to={`/property-details/${property.id}`}
              className="col-lg-4 col-md-6 card-centring"
            >
              <div className="single-product-wrap">
                <div className="thumb">
                  <img
                    loading={'lazy'}
                    style={{
                      widthd: 350,
                      height: 340,
                    }}
                    src={
                      property.image ?
                        property.image?.original_url

                        : demoimg
                    }
                    alt="img"
                  />
                  <Link className={property.classification?.id==3?"cat2":'cat'}to={`/property-details/${property.id}`}>
                    {property?.classification?.name}
                    {/* Featured */}
                  </Link>
                </div>
                <div className="product-wrap-details">
                  <div className="media">
                    <div className="author">
                      <img
                        style={{ height: 50, width: 50 ,objectFit:'contain'}}
                        src={
                          publicUrl + "assets/img/logo.png"
                        }
                        alt="img"
                      />
                    </div>
                    <div className="media-body">
                      <h6>
                        {property.title}
                      </h6>
                      <p>
                        <img
                          src={publicUrl + "assets/img/icon/location-alt.png"}
                          alt="img"
                        />
                        {property.address}
                      </p>
                    </div>
                    <a className="fav-btn float-right" href="#">
                      {/* <i className="far fa-heart" /> */}
                    </a>
                  </div>
                  <div className="product-meta">
                    <span className="price">$ {property.price}</span>
                    <div className="float-right d-inline-block">
                      <ul>
                        {/* <li>
                          <img
                            src={publicUrl + "assets/img/icon/triangle.png"}
                            alt="img"
                          />
                          2
                        </li>
                        <li>
                          <img
                            src={publicUrl + "assets/img/icon/bed.png"}
                            alt="img"
                          />
                          3
                        </li> */}
                        <li>
                          <BsBuilding color="white" />{" "}
                          {property.size} sq ft
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
          {/* <div className="col-lg-4 col-md-6">
						<div className="single-product-wrap">
							<div className="thumb">
								<img src={publicUrl + "assets/img/product/2.png"} alt="img" />
								<Link className={property.classification?.id==3?"cat2":'cat'}to="/property-details">For Sell</Link>
							</div>
							<div className="product-wrap-details">
								<div className="media">
									<div className="author">
										<img src={publicUrl + "assets/img/author/2.png"} alt="img" />
									</div>
									<div className="media-body">
										<h6><Link to="/property">PreeSoft</Link></h6>
										<p><img src={publicUrl + "assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
									</div>
									<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
								</div>
								<div className="product-meta">
									<span className="price">$ 80,650.00</span>
									<div className="float-right d-inline-block">
										<ul>
											<li><img src={publicUrl + "assets/img/icon/triangle.png"} alt="img" />2</li>
											<li><img src={publicUrl + "assets/img/icon/bed.png"} alt="img" />3</li>
											<li><img src={publicUrl + "assets/img/icon/wall.png"} alt="img" />1026 sq ft</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div> */}
          {/* <div className="col-lg-4 col-md-6">
						<div className="single-product-wrap">
							<div className="thumb">
								<img src={publicUrl + "assets/img/product/3.png"} alt="img" />
								<Link className={property.classification?.id==3?"cat2":'cat'}to="/property-details">For Sell</Link>
							</div>
							<div className="product-wrap-details">
								<div className="media">
									<div className="author">
										<img src={publicUrl + "assets/img/author/3.png"} alt="img" />
									</div>
									<div className="media-body">
										<h6><Link to="/property">PreeSoft</Link></h6>
										<p><img src={publicUrl + "assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
									</div>
									<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
								</div>
								<div className="product-meta">
									<span className="price">$ 80,650.00</span>
									<div className="float-right d-inline-block">
										<ul>
											<li><img src={publicUrl + "assets/img/icon/triangle.png"} alt="img" />2</li>
											<li><img src={publicUrl + "assets/img/icon/bed.png"} alt="img" />3</li>
											<li><img src={publicUrl + "assets/img/icon/wall.png"} alt="img" />1026 sq ft</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div> */}
        </div>
      </div>
    </div>
  );
};

export default Product;
