import { FETCH_MY_PROPERTY, SEARCH_PROPERTY, SEARCH_QUERY } from '../actions/types'

const initialState = {
    loading: false,
    myProperties: [],
    searchProperties: [],
    selectedPurpose: {},
    selectedcategoryType: {},
    searchedLocation: {},
    selectedState: {},
    selectedCity: {},
    lat: 0,
    lng: 0

}

export default function (state = initialState, action) {
    switch (action.type) {

        case FETCH_MY_PROPERTY:

            return {
                ...state,
                loading: false,
                myProperties: action.payload.myProperties,
            }

        case SEARCH_PROPERTY:

            return {
                ...state,
                loading: false,
                searchProperties: action.payload.searchProperties,
            }
        case SEARCH_QUERY:

            return {
                ...state,
                selectedPurpose: action.payload.selectedPurpose,
                selectedcategoryType: action.payload.selectedcategoryType,
                searchedLocation: action.payload.searchedLocation,
                selectedCity: action.payload.selectedCity,
                selectedState: action.payload.selectedState,
                lat: action.payload.lat,
                lng: action.payload.lng
            }


        default:
            return state;
    }

}