import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

const About = () => {

  const compRef = useRef()



  let publicUrl = process.env.PUBLIC_URL + '/'


  useEffect(() => {


    //   $('#test__aboutuse').on('load.owl.lazy', function () {
    //     // alert('Working...')
    //     $("html,body").animate({
    //         scrollTop: 0
    //     }, 1000);
    //     //    $(window).scrollTop(0);
    //  });

    compRef.current.scrollTop = 0

  }, [])













  return <div
    ref={compRef}
    id='test__aboutuse' className="about-area bg-gray pd-top-120 pd-bottom-120">
    <div className="container">
      <div className="single-about-inner about-line-right bg-white">
        <div className="row no-gutter">
          <div className="col-lg-4 order-lg-12">
            <div className="thumb" style={{ background: 'url(' + publicUrl + 'assets/img/bg1.jpg)' }} />
          </div>
          <div className="col-lg-8 order-lg-1">
            <div className="details">
              <div className="section-title mb-4">
                <h6>We are offering the best real estate</h6>
                <h2>The world's most realistic commercial real estate searching experience.</h2>
                <p style={{textAlign:"justify"}}>BoulevardCRE is a fully encrypted, 100% decentralized real estate investment platform that utilizes blockchain technology, smart contracts and tokens to disrupt the real estate industry. We are creating a new financial ecosystem that enables investors and developers to become stakeholders in their cities, rather than being locked into traditional real estate markets. BoulevardCRE is built on proprietary software platforms, which have been developed by Boulevard Group and its affiliates over the years.</p>
              </div>
              {/* <div className="row">
                          <div className="col-md-6">
                            <ul className="single-list-inner">
                              <li>Tempor incididunt Amet</li>
                              <li>Exercitation ullamco</li>
                              <li>Dolore magna quis nisi</li>
                              <li>Lorem ipsum dolor manga</li>
                            </ul>
                          </div>
                          <div className="col-md-6">
                            <ul className="single-list-inner">
                              <li>Tempor incididunt Amet</li>
                              <li>Dolore magna quis nisi</li>
                              <li>Lorem ipsum dolor manga</li>
                            </ul>
                          </div>
                        </div>  
                        <a className="btn btn-base" href="#">SEE MORE</a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


}

export default About