import React from 'react'

function UsePagination({ links, onPaginate }) {
    return (
        <div style={{
            display: 'flex',
            alignItems:'center',
            justifyContent:'center'
        }}>
            <ul class="pagination" >
                {
                    links.map((link) => (
                        <li 
                            class={`page-item ${link.url == null || link.active ? 'disabled' : 'active'} m-2`} >
                            <a onClick={() => onPaginate(link)} class="page-link">
                                {link.label == 'Next &raquo;' ? <span>&raquo;</span> : link.label == "&laquo; Previous" ? <span>&laquo;</span> : link.label}
                            </a>
                        </li>
                    ))
                }

            </ul>
        </div>
    )
}

export default UsePagination