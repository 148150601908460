import React from 'react'
import CustomNavbar from '../../components/global-components/custom-navbar'
import Navbar from '../../components/global-components/navbar'
import Page_header from '../../components/global-components/page-header'
import PrivacyPolicyInfo from '../../components/section-components/privacy-policy'

const PrivacyPolicy = () => {
  const $ = window.$;
  $(document).ready(function () {

    $(this).scrollTop(0);
  });

  return (
    <div>
         {/* <Navbar /> */}
         <CustomNavbar />
        <Page_header headertitle="Privacy Policy"  />
        <PrivacyPolicyInfo/>
    </div>
  )
}

export default PrivacyPolicy