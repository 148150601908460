export const appColors = {
    primary: '#222222',
    secondary: '',
    appPrimaryColor:'#E83D3D',
    appsecondaryColor:'#FFB769',
    darkred:'#C4373C',
    black: '#000',
    white: '#fff',
    btnColor:'#A67328',
    bluecolor:'#023455',
    authbgColor:'#3B3E45',
    inputbgColor:'#fff',
    boxColor:'#2E2B60',
    tabicon:'#EE859D',
    profilebgcolor:'#2798E4'
}