import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import PropertyGrid from "./section-components/property-grid";
import Footer from "./global-components/footer-v2";
import CustomNavbar from "./global-components/custom-navbar";

const PropertGridPage = () => {
  return (
    <div>
      {/* <Navbar /> */}
      <CustomNavbar />
      <PageHeader headertitle="Commercial Listings" />
      <PropertyGrid />
      <Footer />
    </div>
  );
};

export default PropertGridPage;
