import React from 'react';
import Navbar from '../../components/global-components/navbar';
import PageHeader from '../../components/global-components/page-header';
import SignIn from '../../components/section-components/sign-in';
import Footer from '../../components/global-components/footer-v2';
import { Link, Switch, Redirect } from "react-router-dom";
import SignUpPage from '../Signup/sign-up';
import CustomNavbar from '../../components/global-components/custom-navbar';

const SignInPage = () => {
    return <div>
        {/* <Navbar /> */}
        <CustomNavbar />
        <PageHeader headertitle="Sign In" />
        <SignIn />
        <Footer />
        {/* <Switch>
            <ContentRoute path="/auth/login" component={SignInPage} />
            <ContentRoute
                path="/auth/registration"
                component={SignUpPage}
            />
            <ContentRoute
                path="/auth/forgot-password"
                component={ForgotPassword}
            />
            <Redirect from="/auth" exact={true} to="/auth/login" />
            <Redirect to="/auth/login" />
        </Switch> */}
    </div>
}

export default SignInPage

