import React, { Component, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { baseUrl } from '../../services/config';
import { useToasts } from "react-toast-notifications";
const ContactForm=()=> {

  const compRef=useRef()

  // constructor(props) {
  //   super(props);

  //   this.compRef = React.createRef(null);
  // }
  useEffect(() => {
    const $ = window.$;

    $('.footer-area.style-two').removeClass('mg-top-100');


    compRef.current.scrollTop = 0
  }, [])
  let token = localStorage.getItem("userToken");
  const { addToast } = useToasts();
const [subject,setSubject]=useState("")
const [message,setMessage]=useState("")
 const [loading,setloading] =useState(false)
   const UserProfile = useSelector((state) => state.User.profile);
    const User = useSelector((state) => state.User.user);
  let publicUrl = process.env.PUBLIC_URL + '/'


  const handleSubmit=(e)=>{
    e.preventDefault()
    const data={
      name:User.name,
      email:User.email,
      subject:subject,
      message:message,
    }
    setloading(true)
    fetch(`${baseUrl}/inquiries`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type':'application/json'
      },
      body: JSON.stringify(data) 
    }).then(res => res.json())
      .then(res => {

        if (res.metadata.success) {
          addToast(res.metadata.message, {
            appearance: "success",
            autoDismiss: true,
          });
          setloading(false);
          setMessage("")
          setSubject("")
        } else if (!res.metadata.success) {
          Object.values(res.metadata.message).map(msg => {
            addToast(msg, {
              appearance: "error",
              autoDismiss: true,

            });
            setloading(false);
          })
        }
        else {
          addToast(res.metadata.message, {
            appearance: "error",
            autoDismiss: true,
          });
          console.log(res.metadata);
          setloading(false);
        }
      }).catch(err => {
        console.log("catch block", err);
        setloading(false);
      })

  }
  return(
    <>
    <div
      ref={compRef}
      className="contact-page-area pd-top-120 ">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-7 mb-5 mb-lg-0">
            <div className="contact-details-inner mng-box-shadow">
              <h4>The world's most realistic commercial real estate searching experience.</h4>
              <p className='text-justify'>BoulevardCRE is a premier commercial real estate platform for any business, offering the best commercial listings from nearly 30,000 square feet up to 7.5 million square feet.</p>
              <div className="row">
                <div className="col-md-6">
                  <div className="contact-single-list">
                    <h5>US Office</h5>
                    <ul>
                      <li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" /> 4080 Truxel Road, Suite 100, Sacramento. CA. US 95834</li>
                      <li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" /> +1 (916) 793-4048</li>
                      <li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" /> info@theboulevard.us</li>
                      {/* <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" /> +(066) 19 5017 800 628</li> */}
                    </ul>
                  </div>
                </div>
                {/* <div className="col-md-6">
                          <div className="contact-single-list">
                            <h5>Pakistan Office</h5>
                            <ul>
                              <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" /> +92 (316) 312-0000</li>
                               <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" /> +(066) 19 5017 800 628</li> 
                               <li><img src={publicUrl+"assets/img/icon/location2.png"} alt="img" /> +(066) 19 5017 800 628</li> 
                            </ul>
                          </div>
                        </div> */}
              </div>
              <div className="row mt-4">
                <div className="col-md-3">
                  {/* <div className="contact-single-date">
                            <p><strong>Monday-Friday:</strong> 9am - 8pm</p>
                            <p><strong>Saturday:</strong> 10 am to 3 pm</p>
                            <p><strong>Sunday: </strong> Clossed</p>
                          </div> */}
                </div>
                <div className="col-md-6 align-self-center text-md-right ">
                  <ul className="social-area style-3 justify-content-center d-flex">
                    <li><a target={'_blank'} href="https://www.facebook.com/Blvdcre"><i className="fab fa-facebook-f" aria-hidden="true" /></a></li>
                    <li><a target={'_blank'} href="https://www.twitter.com/solverwp/"><i className="fab fa-twitter" aria-hidden="true" /></a></li>
                    <li><a href="#"><i className="fab fa-instagram" aria-hidden="true" /></a></li>
                    <li><a href="#"><i className="fab fa-skype" aria-hidden="true" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-5">
            <form onSubmit={handleSubmit}>
              <div className="row">
         
                <div className="col-lg-12 col-md-12">
                  <label className="single-input-inner style-bg-border">
                    <input type="text" placeholder="Subject" value={subject} onChange={(e)=>setSubject(e.target.value)}/>
                  </label>
                </div>
                <div className="col-xl-12 col-lg-6">
                  <label className="single-input-inner style-bg-border">
                    <input type="text" placeholder="Name" disabled value={User.name} />
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="single-input-inner style-bg-border">
                    <input type="text" placeholder="Email" disabled value={User.email} />
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="single-input-inner style-bg-border">
                    <input type="text" placeholder="Phone" disabled value={UserProfile.phone} />
                  </label>
                </div>
                <div className="col-12">
                  <label className="single-input-inner style-bg-border">
                    <textarea placeholder="Message" defaultValue={""} value={message} onChange={(e)=>setMessage(e.target.value)}/>
                  </label>
                </div>
                <div className="col-12 mb-4">
                  <button disabled={loading} type="submit" className="btn btn-base">Submit Now</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="contact-page-map mg-top-100">
        <iframe src={`https://maps.google.com/maps?q=38.644107833798714,-121.50779148708243&t=&z=15&ie=UTF8&iwloc=&output=embed`} />
      </div>
    </div>
</>
  )
}

export default ContactForm