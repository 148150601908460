import React, { useEffect, useState, useCallback } from "react";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  Marker,
  Popup,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { MAP_API_KEY } from "../../constants/mapApiKey";
import teslaData from "../../utils/tesla-charger.json";
import "leaflet-draw/dist/leaflet.draw.css";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import L from "leaflet";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

// let publicUrl = process.env.PUBLIC_URL+'/'

const markerIcon = new L.Icon({
  iconUrl: require("./locationlogo.png"),
  iconSize: [30, 45],
});

const SearchGoogleMap = ({
  latlngVal,
  allProperties,
  listProp,
  onMarkerClick

}) => {


  const [map, setMap] = useState(null);
  const [mapLayer, setMapLayer] = useState([]);

  const [latlng, setLatlng] = useState(latlngVal);


console.log("this is marker", listProp)


  const { lat, lng } = useSelector(state => state.MyProfile)
  const { currentLatlng, searchProperties } = useSelector(state => state.User)

  const [position, setPosition] = useState([lat, lng])


  console.log("position lat lng", position)

  let cc = [38.5252303, -121.4583056]

  useEffect(() => {
    // setLatlng(latlngVal);
    console.log("LatLng===>", latlng);
  }, []);

  const filterData = teslaData.filter((tsla) => tsla.address.country === "USA");

  const _created = (e) => {
    console.log(e);
    const { layerType, layer } = e;
    if (layerType === "polygon") {
      const { _leaflet_id } = layer;
      setMapLayer((layers) => [
        ...layers,
        { id: _leaflet_id, latlngs: layer.getLatLngs()[0] },
      ]);
    }
  };
  const _edited = (e) => {
    console.log(e);

    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayer((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id
            ? { ...l, latlngs: { ...editing.latlngs[0] } }
            : l
        )
      );
    });
  };
  const _deleted = (e) => {
    console.log(e);

    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayer((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });
  };

  function DisplayPosition({ map }) {
    const [position, setPosition] = useState(() => map.getCenter());

    const onMove = useCallback(() => {
      setPosition(map.getCenter());
    }, [map]);

    useEffect(() => {
      map.setView(latlngVal, 11);

      map.on("move", onMove);
      return () => {
        map.off("move", onMove);
      };
    }, [map, onMove, latlngVal]);

    return <></>;
  }



  // console.log("allProperties", allProperties)

  return (
    <div className="map">
      {map ? <DisplayPosition map={map} /> : null}

      <MapContainer
        center={latlngVal ? latlngVal : [lat, lng]}
        zoom={11}
        scrollWheelZoom={true}
        ref={setMap}
        style={{ width: "100%", }}
      >


        {/* {console.log("latlooooong",latlngVal)}
{console.log("hellooooo long",[lat, lng])} */}


        <ReactLeafletGoogleLayer

          apiKey={MAP_API_KEY} type={"roadmap"}>
          <FeatureGroup>
            <EditControl
              onCreated={_created}
              onEdited={_edited}
              onDeleted={_deleted}
              draw={{

                // polygon: true,
                polyline: true,
                circle: true,
                rectangle: true,
                circlemarker: false,
                marker: false,
              }}
            />
          </FeatureGroup>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {listProp?.map((item) => (
            <Marker
              key={item.id}
              position={[item.latitude, item.longitude]}
              icon={markerIcon}
            >
              <Popup
                className="popup-map"
                position={[item.latitude, item.longitude]}>
                <Link
                  onClick={() => onMarkerClick(item)}
                >
                  <div>
                    <div className="col-8">
                      <b className="mt-2">{`${item.title}`}</b>
                      <p>{item.address}</p>
                    </div>
                  </div>
                </Link>
              </Popup>
            </Marker>
          )
          )
          }

          <div class="float">
            <i class="fa fa-map my-float"></i>
          </div>
        </ReactLeafletGoogleLayer>
      </MapContainer>




    </div >
  );
};

export default SearchGoogleMap;
