import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SearchGoogleMap from "../../components/google-map/SearchGoogleMap";
import { MAP_API_KEY } from "../../constants/mapApiKey";
import { baseUrl } from "../../services/config";
// import { PlacesAutocomplete } from "./PlacesAutocomplete";
import Select from "react-select";
import { SEARCH_PROPERTY } from "../../redux/actions/types";
import { BsBuilding, BsCurrencyDollar } from "react-icons/bs";
import "./map.css";

const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    height: 40,
    // maxWidth: '200',
    borderRadius: 0,
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled ? "red" : "white",
      color: "black",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

export const SearchProperties = () => {
  // useEffect(() => {

  // }, [])

  const [mapbutton, setMapbutton] = useState(true);
  const [mapShow, setMapShow] = useState(true);
  const [listShow, setListShow] = useState(true);
  const [listbutton, setListbutton] = useState(false);


  const mapClick = () => {
    setListbutton(true);
    setMapbutton(false);
    setMapShow(true);
    setListShow(false);
    let map2 = window.document.getElementById('kanjarmap')
    map2.classList.remove('maptop')
    // const map = document.getElementsByClassName("maptop");
    // map.style.display = "block";
  };
  const listClick = () => {
    setListbutton(false);
    setMapbutton(true);
    setMapShow(false);
    setListShow(true);
    let map2 = window.document.getElementById('kanjarmap')
    map2.classList.add('maptop')
  };

  const chechDevice = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };

  window.onresize = reportWindowSize;
  function reportWindowSize() {
    if (window.innerWidth > 760) {
      setListbutton(false);
      setListShow(true);
      setMapShow(true);
    }
    if (window.innerWidth < 760) {
      setMapbutton(true);
    }
  }

  const messagesEnd = useRef();
  const viewEnd = useRef();
  const [currentSelectedObject, setcurrentSelectedObject] = useState({});
  const [isMapshown, setisMapshown] = useState(true);

  const dispatch = useDispatch();
  const PURPOSE = [
    { id: 0, title: "All" },
    { id: 1, title: "For Sale" },
    { id: 2, title: "For Lease" },
    { id: 3, title: "Businesses For Sale" },
  ];

  const [categoryType, setcategoryType] = useState([]);

  const {
    searchProperties,
    selectedPurpose,
    selectedcategoryType,
    searchedLocation,
    selectedState,
    selectedCity,
    lat,
    lng
  } = useSelector((state) => state.MyProfile);

  const searchLatlng = [searchProperties.length > 0 ? searchProperties[0]?.latitude : lat, searchProperties.length > 0 ? searchProperties[0]?.longitude : lng]



  // console.log("kuch likh dy yr", searchProperties);

  let publicUrl = process.env.PUBLIC_URL + "/";

  const authToken = localStorage.getItem("userToken");
  const [loading, setloading] = useState(false);
  const [allProperties, setAllProperties] = useState([]);


  const [selectedPurposeId, setSelectedPurposeid] = useState('');
  const [selectedCategory, setselectedCategory] = useState('');
  const [latlng, setLatlng] = useState([lat, lng]);

  const [selectAddress, setSelectedAddress] = useState({});

  const [userLat, setuserLat] = useState(0);
  const [userLng, setuserLng] = useState(0);

  const { ref } = usePlacesWidget({
    apiKey: MAP_API_KEY,
    onPlaceSelected: (place) => {
      console.log("Location====>", place.geometry.location.lat());
      setLatlng({
        lat: +place.geometry.location.lat(),
        lng: +place.geometry.location.lng(),
      });

      setSelectedAddress(place);
      searchbySelectedaddress(
        place.geometry.location.lat(),
        place.geometry.location.lng()
      );
    },
    options: {
      types: ["geocode", "establishment"],
      componentRestrictions: { country: "us" },
      fields: [
        "address_components",
        "geometry.location",
        "place_id",
        "formatted_address",
      ],
      // bounds,
    },
  });

  useEffect(() => {
    getAllProperties();
    getCategories();
  }, []);



  const getCurrentLocation = async () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      const obj = [position.coords.latitude, position.coords.longitude]
      // setLatlng(obj)
      // searchPropertiesByCurrentLoc(position.coords.latitude, position.coords.longitude)
    });
  };

  const searchbySelectedaddress = (lat, lng) => {
    const data = {
      latitude: lat,
      longitude: lng,
    };
    console.log("latitude and longitude",data)

    fetch(`${baseUrl}/search/properties`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((res) => {
        if (res.metadata.success) {
          // if (res.payload.length > 0) {
          let checkboxes = res.payload;
          res.payload.map((item, index) => {
            item["StyleId"] = `ref ${index + 1}`;
            checkboxes[index] = item;
          });
          dispatch({
            type: SEARCH_PROPERTY,
            payload: {
              searchProperties: [...checkboxes],
            },
          });
          // dispatch({
          //   type: SEARCH_QUERY,
          //   payload: {
          //     searchedLocation: selectAddress,
          //     selectedCity: selectedCity,

          //   },
          // });

          // } else {
          //   addToast('Listing Not Found', {
          //     appearance: "error",
          //     autoDismiss: true,
          //   })

          // }
        }
        //  else {
        //   addToast('Listing Not Found', {
        //     appearance: "error",
        //     autoDismiss: true,
        //   })
        // }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  // console.log("selected purpose quick", selectedcategoryType);

  const getCategories = async () => {
    await axios
      .get(`${baseUrl}/list/classifications`)
      .then((resp) => {
        if (resp.data) {
          let tempArr = [];

          const filteredData = resp.data.payload.find(
            (item) => item.id == selectedPurpose.id
          );
          let obt = {
            id: 122,
            value: 122,
            label: 'All',
            categories: []
          }
          tempArr.push(obt)
          filteredData.categories.forEach((cat) => {
            tempArr.push({
              id: cat.id,
              value: cat.id,
              label: cat.name,
              categories: cat.types,
            });
          });

          // let checkboxes = tempArr;
          // updatedArr.map((item, index) => {
          //   item["selected"] = false;
          //   checkboxes[index] = item;
          // });


          setcategoryType(tempArr);




          console.log("filterd Array", filteredData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllProperties = async () => {
    setloading(true);
    await axios
      .get(`${baseUrl}/list/for-you-properties`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        // console.log("this is property", res.data.payload);
        setAllProperties(res.data.payload);
        setloading(false);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };
  const selectProperty = (lat, lng) => {
    setLatlng([lat, lng]);
  };

  const onChangeSearch = (id) => {



    const fdata = new FormData();

   
    fdata.append("classification_id", id == 0 ? '' : id);
    // fdata.append("category_id", id);
    fdata.append("latitude", lat);
    fdata.append("longitude", lng);

    // if (Object.keys(selectAddress).length==0) {}
    // else{
    // if (Object.values(selectAddress).length > 0) {
    //   if (selectAddress.name) {
    //     fdata.append("address", selectAddress.name);
    //   } else {
    //     fdata.append("address", selectAddress.formatted_address);
    //   }
    // }

    // }


    axios
      .post(`${baseUrl}/search/properties`, fdata, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        console.log("this is search data",res.data);
        if (res.data) {
          dispatch({
            type: SEARCH_PROPERTY,
            payload: {
              searchProperties: res.data.payload,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeSearch2 = (id) => {

    const fdata = new FormData();
   
      fdata.append("category_id", id == 122 ? '' : id);
      fdata.append("latitude", lat);
      fdata.append("longitude", lng);
    

    // if (Object.keys(selectAddress).length==0) {}
    // else{
    // if (Object.values(selectAddress).length > 0) {
    //   if (selectAddress.name) {
    //     fdata.append("address", selectAddress.name);
    //   } else {
    //     fdata.append("address", selectAddress.formatted_address);
    //   }
    // }

    // }
    axios
      .post(`${baseUrl}/search/properties`, fdata, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          dispatch({
            type: SEARCH_PROPERTY,
            payload: {
              searchProperties: res.data.payload,
            },
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const markerClick = (item) => {
    setcurrentSelectedObject(item);
    let test = window.document.getElementById(item.StyleId);
    if (test) {
      test.scrollIntoView({ behavior: "smooth" });
    }
    // window.scrollTo({
    //   behavior: "smooth",
    //   top: messagesEnd.current.offsetTop,
    // });
    // messagesEnd.current.scrollIntoView({ behavior: "smooth" });

    // let arr = []

    // arr.push(item)

    // dispatch({
    //   type: SEARCH_PROPERTY,
    //   payload: {
    //     searchProperties: arr,
    //   },
    // });
  };

  const onMap = () => {
    var map = document.querySelector(".map-con");
    var list = document.querySelector(".search__map__list");

    list.style.display = "none";
    map.style.display = "none";
  };
  const handleDiscription=(item)=>{
if(item.length>30){
  return item.slice(0,30)+"...."
}else{
  return item
}
  }

  const handleTitle=(item)=>{
    if(item.length>20){
      return item.slice(0,20)+"...."
    }else{
      return item
    }
      }

  return (
    <div className="mapContainer">
      <div className="row">
        <div
          style={{
            background: "url(" + publicUrl + "assets/img/overlay-10.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
          id="searchContainer"
          className="col-md-5 col-sm-12 searchContainer show"
        >
          <div className="navBar">
            <div className="col-12 col-sm-12 d-flex justify-content-center">
              <Link to="/">
                <img
                  src={publicUrl + "assets/img/logo.png"}
                  alt="img"
                  className={"navBarLogo"}
                />
              </Link>
              {/* <PlacesAutocomplete /> */}
              <input
                placeholder="Enter Location"
                defaultValue={searchedLocation.formatted_address}
                ref={ref}
                type={"text"}
                className={"form-control"}
              />
            </div>
            <div className="d-flex mt-3 mr-1">
              <div className="col-sm-6">
                <select
                  // style={{ width: '150px' }}
                  onChange={(e) => {
                    setSelectedPurposeid(e.target.value)
                    onChangeSearch(e.target.value)
                  }}
                  // defaultValue={selectedPurpose.id}
                  className="form-control form-select"
                >
                  {PURPOSE.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-sm-6">
                <select
                  // style={{ width: '150px' }}
                  onChange={(e) => {
                    setselectedCategory(e.target.value);
                    onChangeSearch2(e.target.value)
                  }}
                  defaultValue={122}
                  // value={122}
                  className="form-control form-select"
                >
                  {categoryType.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="col-md-3">
                <input
                  style={{ width: '150px' }}

                  type={'number'} placeholder="Min" className="form-control" />
              </div>
              <div className="col-md-3">
                <input
                  style={{ width: '150px' }}

                  type={'number'} placeholder="Max" className="form-control" />
              </div> */}
            </div>
          </div>
          <div className="">
            <div className="informationProperty">
              <p style={{ color: "#003366" }}>
                Showing <b>{searchProperties.length}</b> results
              </p>
            </div>

            {searchProperties.length <= 0 && (
              <div className="text-center">
                <h5>Listing Not Found</h5>
              </div>
            )}

            <div
              className={
                listShow ? "search__map__list show" : "hide"
              }
            >
              <div className="row">
                {searchProperties &&
                  searchProperties?.map((item, i) => {
                    return (
                      <div

                        className="col-md-6">
                        <Link
                          target={"_blank"}
                          className="cardProperty"

                          to={`property-details/${item.id}`}
                        >
                          <div
                            style={{
                              borderRadius: 10,
                              border:
                                item.id == currentSelectedObject.id
                                  ? "2px solid #003366"
                                  : "2px solid  #eee",
                              // transform: item.id == currentSelectedObject.id ? 'transform: scale(1.2)' : ''
                            }}
                            id={item.StyleId}
                            ref={messagesEnd}
                            onClick={() =>
                              selectProperty(item.latitude, item.longitude)
                            }
                          >
                            <img
                              src={
                                item.image
                                  ? item.image.original_url
                                  : `${publicUrl}assets/img/noimage.jpg`
                              }
                              className={"imageProperty"}
                            />
                            <div className="ml-2 mr-2">
                              <h6
                                style={{ color: "black", marginTop: 5 }}
                                className="text font-weight-bold property-title"
                              >
                                {handleTitle(item.title)}
                              </h6>
                              <p
                                style={{ color: "black" }}
                                className="text font-weight-bold"
                              >
                                ${item.price}
                              </p>
                              <p
                                style={{ color: "black" }}
                                className="text font-weight-light property-description"
                              >
                                {item.description ? handleDiscription(item.description) : "N/A"}
                              </p>
                              <p
                                style={{ color: "black" }}
                                className="text font-weight-light property-description"
                              >
                                {item.state?.name + "," + item.city?.name}
                              </p>

                              <div className="row">
                                <div className="col-12">
                                  <BsBuilding color="black" /> {item.size}sqft
                                </div>
                                <div className="col-12 align-content-center">
                                  <BsCurrencyDollar color="black" />
                                  <span>{item.price}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
              </div>
            </div>
            {chechDevice() ? (
              <SearchGoogleMap
                onMarkerClick={(currentLatlng) => markerClick(currentLatlng)}
                listProp={searchProperties}
                latlngVal={searchLatlng ? searchLatlng : [lat, lng]}

              />
            ) : null}
          </div>
        </div>
        <button
          className={mapbutton ? "btn btn-primary mapbutton show" : "hide"}
          onClick={mapClick}
        >
          Map
        </button>
        <button
          className={listbutton ? "btn btn-primary ListButton show" : "hide"}
          onClick={listClick}
        >
          List
        </button>
        <div
          id="kanjarmap"
          className={
            mapShow
              ? "col-md-7 col-sm-12 bg-success no-gutter map-con show2 maptop"
              : "hide"
          }
        >
          <SearchGoogleMap
            onMarkerClick={(currentLatlng) => markerClick(currentLatlng)}
            listProp={searchProperties}
            latlngVal={searchLatlng ? searchLatlng : [lat, lng]}
          />

        </div>

        {/* {chechDevice() ? (
          <div onClick={onMap} class="float">
            <i class="fa fa-map my-float"></i>
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
