import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { baseUrl } from "../../services/config";
import { useToasts } from "react-toast-notifications";
import { BeatLoader } from "react-spinners";
import { appColors } from "../../utils/colors";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import TextField from "@material-ui/core/TextField";
import { InputLabel } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { MAP_API_KEY } from "../../constants/mapApiKey";
import { usePlacesWidget } from "react-google-autocomplete";
import Autocomplete from "react-google-autocomplete";
import { SimpleMap } from "../google-map/SimpleMap";
import CreatableSelect from 'react-select/creatable';
import { ErrorMessage } from "@hookform/error-message";
import MiniMap from "../MiniMap/MiniMap";
import InputMask from "react-input-mask";
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';


const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    height: 45,
    maxWidth: "100%",
    borderRadius: 5,
    borderColor: "#CBCBCB",
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    //   const color = chroma(data.color);
    return {
      ...styles,
      opacity: 10,
      backgroundColor: isDisabled ? "red" : "white",
      color: "black",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
};

const AddProperty = () => {



  useEffect(() => {

    window.scroll({
      behavior: 'smooth',
      top: 0
    })

  }, [])





  const compRef = useRef()
  const isAuth = useSelector((state) => state.User.isAuthenticated);






  const { addToast } = useToasts();

  const history = useHistory();

  const [isMapshown, setisMapshown] = useState(false)
  const [latlng, setLatlng] = useState({ lat: 0, lng: 0 });
  const [selectAddress, setSelectedAddress] = useState({});


  const [selectedLat, setSelectedLat] = useState();
  const [selectedLng, setSelectedLng] = useState();
  const [termsAgree, setAgree] = useState(false);

  const [isMixed, setIsMixed] = useState(false)





  useEffect(() => {

    if (!isAuth) {
      addToast('Please login first', {
        appearance: 'warning',
        autoDismiss: true
      })
      history.push('/sign-in')
    }


  }, [])


  const { ref } = usePlacesWidget({
    apiKey: MAP_API_KEY,
    onPlaceSelected: (place) => {
      console.log("Location====>", place);
      setLatlng({
        lat: +place.geometry.location.lat(),
        lng: +place.geometry.location.lng(),
      });

      setSelectedLat(+place.geometry.location.lat());
      setSelectedLng(+place.geometry.location.lng());
      setSelectedAddress(place);
    },
    options: {
      types: ["(regions)"],
      componentRestrictions: { country: "us" },
      fields: [
        "address_components",
        "geometry.location",
        "place_id",
        "formatted_address",
      ],
      // bounds,
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ mode: 'all' });
  // const onSubmit = (data,e)=>{
  //   e.preventDefault();

  //   console.log(e)
  // };

  const [loading, setloading] = useState(false);

  const [featuresList, setfeaturesList] = useState([]);
  const [tempArray, settempArray] = useState([]);
  const [selectedFeatures, setselectedFeatures] = useState([]);
  const [forleaseEnabled, setforleaseEnabled] = useState(false);

  const [selectedpurpose, setselectedpurpose] = useState({});
  const [saleEnable, setsaleEnable] = useState(false);
  const [installmentEnabled, setinstallmentEnabled] = useState(false);
  const [states, setstates] = useState([]);
  const [cityLists, setcityLists] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [categoryTypes, setcategoryTypes] = useState([]);
  const [categoryTypesId, setcategoryTypesId] = useState("");

  const [categoryListId, setcategoryListId] = useState("");
  const [cityListsId, setcityListsId] = useState("");
  const [statesId, setstatesId] = useState("");
  const [categoryError, setcategoryError] = useState("");
  const [categoryTypeError, setcategoryTypeError] = useState("");
  const [cityIdError, setcityIdError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [stateError, setstateError] = useState("");
  const [purposeError, setpurposeError] = useState("");
  const [selectedInstallmentOption, setselectedInstallmentOption] = useState(
    {}
  );
  const [selectedInstallmentOptionEnable, setselectedInstallmentOptionEnable] =
    useState(false);

  const [investmentHighlights, setInvestmentHighlights] = useState([""]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...investmentHighlights];

    list[index] = e.target.value;
    console.log("List====>", list);

    setInvestmentHighlights(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...investmentHighlights];
    list.splice(index, 1);
    setInvestmentHighlights(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInvestmentHighlights([...investmentHighlights, ""]);
    console.log("input Values====> " + JSON.stringify(investmentHighlights));
  };

  const [amenitiesId, setAmenitiesId] = useState();

  let token = localStorage.getItem("userToken");

  //images states
  const [showlist, setshowlist] = useState([]);
  const [showdoclist, setshowDoclist] = useState([]);
  const [images, setimages] = useState([]);
  const [videos, setVideos] = useState();
  const [documents, setdocuments] = useState([]);
  const [subTypes, setSubTypes] = useState([]);
  const [subTypesId, setsubTypesId] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    // setselectedFeatures([]);
    setShow(false);
    // console.log("selected features",selectedFeatures)
  };
  const handleShow = () => setShow(true);

  let publicUrl = process.env.PUBLIC_URL + "/";

  const PURPOSE = [
    // { id: 2, title: "Rent" },
    { id: 1, title: "Sale" },
    { id: 1, title: "Sale" },
  ];

  const INSTALLMENT_AVAILABLE = [
    { id: 0, title: "No" },
    { id: 1, title: "Yes" },
  ];

  const selectePurpose = (purpose) => {
    setcategoryTypes([]);
    setselectedpurpose(purpose);

    if (purpose.id == 1) {
      setsaleEnable(true);
      // setrentEnable(false);
      setforleaseEnabled(false);
      setinstallmentEnabled(false);
      onchangeCategoryType(purpose);
    }
    if (purpose.id == 2) {
      setsaleEnable(true);
      setforleaseEnabled(true);
      onchangeCategoryType(purpose);
    }
    if (purpose.id == 3) {
      setsaleEnable(true);
      setforleaseEnabled(false);
      setinstallmentEnabled(false);
      onchangeCategoryType(purpose);
    }
  };

  useEffect(() => {
    getStates();
    getCategoryList();
  }, []);

  const getStates = () => {
    axios
      .get(`${baseUrl}/list/states`)
      .then((resp) => {
        if (resp.data) {
          let arr = [];

          resp.data.payload.forEach((state) => {
            arr.push({
              id: state.id,
              value: state.id,
              label: state.name,
            });
          });
          setstates(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategoryList = () => {
    axios
      .get(`${baseUrl}/list/classifications`)
      .then((resp) => {
        if (resp.data) {
          let arr = [];
          resp.data.payload.forEach((category) => {
            arr.push({
              id: category.id,
              value: category.id,
              label: category.name,
              categories: category.categories,
            });
          });
          setcategoryList(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangeStateHandle = (e) => {
    const id = e.id;
    setstatesId(id);
    console.log(id);
    axios
      .get(`${baseUrl}/list/states/${id}/cities`)
      .then((resp) => {
        if (resp.data) {
          let arr = [];

          resp.data.payload.forEach((city) => {
            arr.push({
              id: city.id,
              value: city.id,
              label: city.name,
            });
          });
          setcityLists(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onchangeCategoryType = (e) => {
    console.log(e.categories);
    setcategoryListId(e.id);
    // getFeaturesList(e.id)
    if (e.categories) {
      let arr = [];
      e.categories.forEach((categories) => {
        arr.push({
          id: categories.id,
          value: categories.id,
          label: categories.name,
        });
      });

      setcategoryTypes(arr);
    }
  };

  const getFeaturesList = (id) => {
    setAmenitiesId(id);
    axios
      .get(`${baseUrl}/list/categories/${id}/features`)
      .then((resp) => {
        console.log(resp.data);
        if (resp.data.metadata.success) {
          setfeaturesList(resp.data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getTypeList = (id) => {
    setAmenitiesId(id);
    axios
      .get(`${baseUrl}/list/categories/${id}/types`)
      .then((resp) => {
        console.log(resp.data);
        if (resp.data.metadata.success) {
          let arr = [];
          resp.data.payload.forEach((types) => {
            arr.push({
              id: types.name,
              value: types.name,
              label: types.name,
            });
          });

          setSubTypes(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeImages = (e) => {
    // let img = e.target.files[0];
    const imagesArray = [...images];
    let isValid = "";

    console.log(e.target.files[0].size)

    for (let i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size >= 5e6) {
        setimages([]);
        window.alert("Please upload a file smaller than 5 MB");
        return false;
      } else {
        if (images.length > 10) {
          addToast('You can select just 10 Images', {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          imagesArray.push(e.target.files[i]);

        }
      }
    }
    // imagesArray.push(img);
    setimages(imagesArray);

    console.log(imagesArray);
  };
  const handleChangeVideos = (e) => {

    if (e.target.files[0].size >= 5e6) {
      setVideos(null);
      window.alert("Please upload a file smaller than 5 MB");
      return false;
    } else {
      setVideos(e.target.files[0]);
    }

  };

  const showImages = (event) => {
    let fileArray = showlist;
    let fileObj = [];
    for (let i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i].size >= 5e6) {
        setimages([]);
        // window.alert("Please upload a file smaller than 5 MB");
        return false;
      } else {
        fileObj.push(event.target.files[i]);
      }
      // fileObj.push(event.target.files[i]);
    }

    for (let i = 0; i < fileObj.length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[i]));
    }

    // console.log("file array",fileArray)
    // console.log("file obj",fileObj)
  };

  const removeImage = (i) => {
    let arr = [...showlist];
    let arr2 = [...images];
    let newArr = arr.filter((item, index) => {
      return index !== i;
    });
    let newArr2 = arr2.filter((item, index) => {
      return index !== i;
    });

    setimages(newArr2);
    setshowlist(newArr);
  };

  const handleChangeDocuments = (e) => {
    // let img = e.target.files[0];
    const documentsArray = [...documents];
    let isValid = "";

    for (let i = 0; i < e.target.files.length; i++) {
      documentsArray.push(e.target.files[i]);
    }
    // imagesArray.push(img);
    setdocuments(documentsArray);

    console.log("aja file name",documentsArray);
  };

  const showDocuments = (event) => {
    let fileArray = showdoclist;
    let fileObj = [];
    for (let i = 0; i < event.target.files.length; i++) {
      fileObj.push(event.target.files[i]);
    }

    for (let i = 0; i < fileObj.length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[i]));
    }

    // console.log("file array",fileArray)
    // console.log("file obj",fileObj)
  };

  const removeDocuments = (i) => {
    let arr = [...showdoclist];
    let arr2 = [...documents];
    let newArr = arr.filter((item, index) => {
      return index !== i;
    });
    let newArr2 = arr2.filter((item, index) => {
      return index !== i;
    });

    setdocuments(newArr2);
    setshowDoclist(newArr);
  };

  const onSubmit = (fdata, e) => {

    console.log("fdataaaaaa ", fdata);

    e.preventDefault();
    if (categoryListId == "") {

      setcategoryError("Category must be selected");
    } else if (categoryTypesId == "") {


      setcategoryTypeError("Category type must be selected");
    } else if (statesId == "") {
      setstateError("State must be selected");


    } else if (cityListsId == "") {
      setcityIdError("City must be selected");


    } else if (Object.values(selectedpurpose).length == 0) {
      setpurposeError("Purpose must be selected");


    }else if (investmentHighlights.length == 0) {
      addToast("Please add atleast 1 Investment Highlights", {
        appearance: "error",
        autoDismiss: true,
      });


    }
    else if (images.length <= 0) {
      addToast("Please add atleast 1 Image", {
        appearance: "error",
        autoDismiss: true,
      });

    }
    else if (Object.values(selectAddress).length == 0) {
      addToast('Please enter Address', {
        appearance: "error",
        autoDismiss: true,
      });
    }
    else if (images.length == 0) {
      addToast('Please select atleast 1 Image', {
        appearance: "error",
        autoDismiss: true,
      });
    }else if(fdata.sale_price<0){
      addToast('Asking price should be greater then 0', {
        appearance: "error",
        autoDismiss: true,
      });
    }
    else if(fdata.size<0){
      addToast('Size should be greater then 0', {
        appearance: "error",
        autoDismiss: true,
      });
    }
    else {
      setloading(true);

      setcategoryError("")
      setcategoryTypeError("");
      setstateError("");
      setcityIdError("");
      setpurposeError("");

      console.log("Type===>", subTypesId);


      console.log(fdata);
      console.log(selectedFeatures);

      const data = new FormData();

      // "sale[installment_available]": '1',
      // "installment[advance_payment]": 1213,
      // "installment[number_of_installment]": 121,
      // "installment[monthly_installment]": 123

      console.log("purpose", selectedpurpose.id);
      console.log("category", categoryTypesId);
      data.append("classification_id", selectedpurpose.id);
      data.append("category_id", categoryTypesId);
      data.append("type", subTypesId ? subTypesId : fdata.type);
      data.append("size", fdata.size);
      data.append("state_id", statesId);
      data.append("city_id", cityListsId);
      data.append("title", fdata.title);
      data.append("description", fdata.description);
      data.append("address", selectAddress.formatted_address);
      data.append("latitude", selectedLat);
      data.append("longitude", selectedLng);
      data.append("price", fdata.sale_price);

      for (let i = 0; i < investmentHighlights.length; i++) {
        data.append(`investment_highlights[${i}]`, investmentHighlights[i]);
      }

      if (selectedpurpose.id == 2) {
        data.append("installment[advance_payment]", fdata.advance_payment);
        data.append(
          "installment[number_of_installment]",
          fdata.number_of_installment
        );
        data.append(
          "installment[monthly_installment]",
          fdata.monthly_installment
        );
      }

      for (let i = 0; i < selectedFeatures.length; i++) {
        for (let key of Object.keys(selectedFeatures[i])) {
          console.log("hello...", selectedFeatures[i][key]);
          data.append(`features[${i}][${key}]`, selectedFeatures[i][key]);
          // console.log(data);
        }
      }

      // data.append('features', ar)
      // arr.forEach((item) => {
      //   data.append('features', JSON.stringify(item))

      // })

      // console.log("all features", data.getAll('features'));

      for (let i = 0; i < images.length; i++) {
        data.append("images[]", images[i]);
      }

      for (let i = 0; i < documents.length; i++) {
        data.append("attachments[]", documents[i]);
      }
      if (videos) {
        data.append("video", videos);

      }

      for (var pair of data.entries()) {
        console.log(pair[0] + ", " + pair[1]);
      }

      // console.log("Sending Data=> "+JSON.parse(data));

      fetch(`${baseUrl}/properties`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: data
      }).then(res => res.json())
        .then(res => {

          if (res.metadata.success) {
            addToast(res.metadata.message, {
              appearance: "success",
              autoDismiss: true,
            });
            setloading(false);
            history.push("/profile");
          } else if (!res.metadata.success) {
            Object.values(res.metadata.message).map(msg => {
              addToast(msg, {
                appearance: "error",
                autoDismiss: true,

              });
              setloading(false);
            })
          }
          else {
            addToast(res.metadata.message, {
              appearance: "error",
              autoDismiss: true,
            });
            addToast('Server error', {
              appearance: "error",
              autoDismiss: true,
            });
            console.log(res.metadata);
            setloading(false);
          }
        }).catch(err => {
          console.log("catch block", err);
          setloading(false);
        })

    }
  };

  const onChangeTextFunc = (val, index, id, type, featureId) => {
    // setselectedFeatures([...selectedFeatures], {
    //   id: id,
    //   value: e.target.value
    // })

    let arr = [...selectedFeatures];

    if (type == 2) {
      let checker = arr.findIndex((ele) => ele.id == id);

      if (checker != -1) {
        arr[checker].id = id;
        arr[checker].value = val;
        arr[checker].amenity_id = arr[checker].amenity_id;
      } else {
        arr.push({
          id: id,
          value: val,
          amenity_id: featureId
        });
      }
      setselectedFeatures(arr);

    }

    if (type == 1) {
      let checker = arr.findIndex((ele) => ele.id == id);

      if (checker != -1) {
        arr[checker].id = id;
        arr[checker].value = val ? 1 : 0;
        arr[checker].amenity_id = arr[checker].amenity_id;
      } else {
        arr.push({
          id: id,
          value: val,
          amenity_id: featureId
        });
      }
      setselectedFeatures(arr);

    }
    if (type == 3) {
      let checker = arr.findIndex((ele) => ele.id == id);

      if (checker != -1) {
        arr[checker].id = id;
        arr[checker].value = val;
        arr[checker].amenity_id = arr[checker].amenity_id;
      } else {
        arr.push({
          id: id,
          value: val,
          amenity_id: featureId
        });
      }
      setselectedFeatures(arr);

    }



    // if (type == 2) {
    //   if (!clonedQuestions.includes(id)) {
    //     clonedQuestions.splice(index, 1, {
    //       ...selectedFeatures[index],
    //       ["id"]: id,
    //       ["value"]: e,
    //       ["amenity_id"]: featureId,
    //     });
    //     setselectedFeatures(clonedQuestions);
    //   }
    // }
    // if (type == 1) {
    //   if (!clonedQuestions.includes(id)) {
    //     clonedQuestions.splice(index, 1, {
    //       ...selectedFeatures[index],
    //       ["id"]: id,
    //       ["value"]: e ? 1 : 0,
    //       ["amenity_id"]: featureId,
    //     });
    //     setselectedFeatures(clonedQuestions);
    //   }
    // }
    // if (type == 3) {
    //   if (!clonedQuestions.includes(id)) {
    //     clonedQuestions.splice(index, 1, {
    //       ...selectedFeatures[index],
    //       ["id"]: id,
    //       ["value"]: e,
    //       ["amenity_id"]: featureId,
    //     });
    //     setselectedFeatures(clonedQuestions);
    //   }
    // }
  };

  // if (type === 2) {

  //   setselectedFeatures(prevState => {
  //     prevState.textStates[index] = text
  //     return {
  //       textStates: prevState.textStates
  //     }
  //   })
  // }

  // if (type == 2) {
  //   // console.log(text, index)
  //   obj[index] = { value: text }
  //   setselectedFeatures(obj)

  //   // console.log(obj)

  // }
  // if (type == 1) {

  //   obj[index] = { value: text == true ? 0 : 1 }
  //   setselectedFeatures(obj)

  // }
  // if (type == 3) {
  //   console.log(text, index)
  //   obj[index] = { value: text.label }
  //   setselectedFeatures(obj)

  // }

  console.log("ye selectedFeatures hain", selectedFeatures);

  const filter = (arr) => {
    let newarr = [];

    arr.forEach((item) => {
      newarr.push({
        label: item.name,
        // value: item.value,
        // id: item.id,
      });
    });

    return newarr;
  };

const vedioNAmeShotner=(name)=>{
if(name.length>50){
  return name.slice(0,50)+"...."
}else{
  return name
}
}

  const GooglePlacesAutocompleteComponent = ({ error, ...field }) => {
    return (
      <div>
        <Autocomplete

          apiKey={MAP_API_KEY}
          // style={{ width: "90%" }}
          onPlaceSelected={(place) => {
            setSelectedLat(+place.geometry.location.lat());
            setSelectedLng(+place.geometry.location.lng());
            setSelectedAddress(place);
          }}
          options={{
            types: ["(regions)"],
            componentRestrictions: { country: "us" },
            // fields: [
            // "address_components",
            // "geometry.location",
            // "place_id",
            // "formatted_address",
            // ],
          }}
          selectProps={{ ...field, isClearable: true }}
        />
        {error && <div style={{ color: "red" }}>{error.message}</div>}
      </div>
    );
  };


  return (
    <div className="container pd-top-100 ">
      <div className="row">
        <h2 className="col-12 text-center">Add Listings for Free</h2>
        <p className="col-12 text-center">
          Add all your listings for free and start capturing leads today!
        </p>
      </div>

      <div className="row mb-3">
        {categoryList.map((prp) => (
          <div
            className={`btn  col-4 border d-flex justify-content-center align-items-center ${prp.id == selectedpurpose.id ? "btn-danger bg-danger" : "bg-white"
              }`}
            style={{
              height: 100,
              marginTop: 10,
            }}
            onClick={() => selectePurpose(prp)}
            key={prp.id}
          >
            {prp.label}
          </div>
        ))}
      </div>

      {Object.keys(selectedpurpose).length > 0 && (
        <div className="listing-information">
          <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="property-form-grid">
              <div className="row">
                <div
                  className="col-md-12"
                  style={{ margin: 5, marginLeft: -50 }}
                >
                  <div
                    style={{ backgroundColor: appColors.appPrimaryColor }}
                    className="btn btn-base hover-none button-alignment"
                  >
                    Listings Infromation
                  </div>
                </div>
                <div ref={compRef} className="col-md-6 mt-2">
                  <div id="cate" className="single-select-inner style-bg-border">
                    <div style={{ display: "flex" }}>
                      <span className="label">Category</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                    </div>

                    <Controller
                      control={control}
                      name="category"
                      rules={{ required: 'Category is required' }}

                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          ref={ref}
                          name={name}
                          value={categoryTypes.find(c => c.value === value)}
                          styles={colourStyles}
                          // value={selectedOption}
                          onChange={e => {
                            // if (e.label == "Mixed Use") {
                            //   // setIsMixed(true)
                            //   onChange(e.id)
                            //   getFeaturesList(e.id);
                            //   getTypeList(e.id);
                            // } else {

                            onChange(e.id)
                            setcategoryTypesId(e.id);
                            getFeaturesList(e.id);
                            getTypeList(e.id);

                            // }
                          }}
                          // onChange={(e) => {
                          //   setcategoryTypesId(e.id);
                          //   getFeaturesList(e.id);
                          //   getTypeList(e.id);
                          // }}
                          options={categoryTypes}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      render={({ message }) => <div
                        style={{ color: "red" }}
                        className="invalid-feedback d-block"
                      >
                        {message}
                      </div>}

                      name="category" />
                    {/* <Select
                    
                      styles={colourStyles}
  
                      onChange={(e) => {

                        setcategoryTypesId(e.id);
                        getFeaturesList(e.id);
                        getTypeList(e.id);
                      }}
                      options={categoryTypes}
                    /> */}

                  </div>
                </div>

                {
                  isMixed ?
                    <div className="col-md-6  mt-2">
                      <label className="single-input-inner style-bg-border">
                        <div style={{ display: "flex" }}>
                          <span className="label">Type</span>
                          <span style={{ color: "red", marginLeft: "0.3%" }}>
                            *
                          </span>
                        </div>
                        <input
                          placeholder="Please enter Type"
                          {...register("type", { required: true })}
                          type="text"
                        />
                      </label>
                      {errors.title && (
                        <div
                          style={{ color: "red" }}
                          className="invalid-feedback d-block"
                        >
                          Type is required.
                        </div>
                      )}
                    </div>

                    :
                    <div className="col-md-6 mt-2">
                      <div className="single-select-inner style-bg-border">
                        <div style={{ display: "flex" }}>
                          <span className="label">Type</span>
                          <span style={{ color: "red", marginLeft: "0.3%" }}>
                            *
                          </span>
                        </div>
                        <Controller

                          control={control}
                          name="categoryType"
                          rules={{ required: 'Category Type is required' }}

                          render={({ field: { onChange, value, name, ref } }) => (
                            <CreatableSelect
                              isClearable
                              ref={ref}
                              value={subTypes.find(c => c.value === value)}
                              styles={colourStyles}
                              // value={selectedOption}

                              // value={selectedOption}
                              onChange={(e) => {
                                onChange(e)

                                setsubTypesId(e.label);

                              }}
                              options={subTypes}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          render={({ message }) => <div
                            style={{ color: "red" }}
                            className="invalid-feedback d-block"
                          >
                            {message}
                          </div>}

                          name="categoryType" />



                      </div>
                    </div>
                }


                <div className="col-md-6 mt-2">
                  <div className="single-select-inner style-bg-border">
                    <div style={{ display: "flex" }}>
                      <span className="label">State</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                    </div>

                    <Controller
                      control={control}
                      name="state"
                      rules={{ required: 'State is required' }}

                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          ref={ref}
                          value={states.find(c => c.value === value)}
                          styles={colourStyles}
                          name={name}
                          onChange={(e) => {
                            onChange(e.id)
                            onchangeStateHandle(e)

                          }}
                          options={states}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      render={({ message }) => <div
                        style={{ color: "red" }}
                        className="invalid-feedback d-block"
                      >
                        {message}
                      </div>}

                      name="state" />
                    {/* <Select
                      styles={colourStyles}
                      // value={selectedOption}
                      onChange={(e) => onchangeStateHandle(e)}
                      options={states}
                    />
                    {stateError && (
                      <div
                        style={{ color: "red" }}
                        className="invalid-feedback d-block"
                      >
                        {stateError}
                      </div>
                    )} */}
                  </div>
                </div>

                <div className="col-md-6  mt-2">
                  <div className="single-select-inner style-bg-border">
                    <div style={{ display: "flex" }}>
                      <span className="label">City</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                    </div>
                    <Controller
                      control={control}
                      name="city"
                      rules={{ required: 'City is required' }}

                      render={({ field: { onChange, value, name, ref } }) => (
                        <Select
                          name={name}
                          ref={ref}
                          value={cityLists.find(c => c.value === value)}
                          styles={colourStyles}
                          onChange={(e) => {
                            onChange(e.id)
                            setcityListsId(e.id)
                          }}
                          options={cityLists}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      render={({ message }) => <div
                        style={{ color: "red" }}
                        className="invalid-feedback d-block"
                      >
                        {message}
                      </div>}

                      name="city" />


                    {/* <Select
                      styles={colourStyles}
                      // value={selectedOption}
                      onChange={(e) => setcityListsId(e.id)}
                      options={cityLists}
                    />
                    {cityIdError && (
                      <div
                        style={{ color: "red" }}
                        className="invalid-feedback d-block"
                      >
                        {cityIdError}
                      </div>
                    )} */}
                  </div>
                </div>

                {/* Title */}

                <div className="col-md-6  mt-2">
                  <label className="single-input-inner style-bg-border">
                    <div style={{ display: "flex" }}>
                      <span className="label">Title</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                    </div>
                    <input
                      placeholder="Title"
                      {...register("title", { required: true })}
                      type="text"
                    />
                       {errors.title && (
                    <div
                      style={{ color: "red" }}
                      className="invalid-feedback d-block"
                    >
                      Title  is required.
                    </div>
                  )}
                  </label>
               
                </div>

                {/* Title Col End */}
                <div className="col-md-6 mt-2">
                  <label className="single-input-inner style-bg-border">
                    <div style={{ display: "flex" }}>
                      <span className="label">Address</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                    </div>
                    {/* <Controller
                      name="location"
                      rules={{
                        required: "Please Enter Location field"
                      }}
                      control={control}
                      render={({ field, fieldState, }) => (
                        <GooglePlacesAutocompleteComponent
                          {...field}
                          error={fieldState.error}
                        />
                      )}
                    /> */}
                    {/* <ErrorMessage
                      errors={errors}
                      render={({ message }) => <div
                        style={{ color: "red" }}
                        className="invalid-feedback d-block"
                      >
                        {message}
                      </div>}

                      name="location" /> */}

                    <Autocomplete

                      // currentLocation={true}
                      apiKey={MAP_API_KEY}
                      // style={{ width: "90%" }}
                      onPlaceSelected={(place) => {
                        setSelectedLat(+place.geometry.location.lat());
                        setSelectedLng(+place.geometry.location.lng());
                        setSelectedAddress(place);
                        setisMapshown(true)
                      }}

                      options={{
                        types: ["geocode", "establishment"],
                        componentRestrictions: { country: "us" },
                        fields: [
                          "address_components",
                          "geometry.location",
                          "place_id",
                          "formatted_address",
                        ],
                      }}
                    // defaultValue="Amsterdam"
                    />
                    {/* <input ref={ref} type="text" className={"form-control"} /> */}
                    {errors.address && (
                    <div
                      style={{ color: "red" }}
                      className="invalid-feedback d-block"
                    >
                      Address  is required.
                    </div>
                  )}
                  </label>
                </div>

                {/* Title Col End */}
                <div className="col-md-6">
                  <label className="single-input-inner style-bg-border">
                    <div style={{ display: "flex" }}>
                      <span className="label">Latitude</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                    </div>
                    <input
                      disabled
                      // ref={ref}
                      value={selectedLat}
                      placeholder="Latitude"
                      onChange={(e) => setSelectedLat(+e.target.value)}
                      type="text"
                    />
                    {/* {errors.address && (
                    <div
                      style={{ color: "red" }}
                      className="invalid-feedback d-block"
                    >
                      this field is required.
                    </div>
                  )} */}
                  </label>
                </div>
                {/* Title Col End */}
                <div className="col-md-6">
                  <label className="single-input-inner style-bg-border">
                    <div style={{ display: "flex" }}>
                      <span className="label">Longitude</span>
                      <span style={{ color: "red", marginLeft: "0.3%" }}>
                        *
                      </span>
                    </div>
                    <input
                      disabled
                      value={selectedLng}
                      placeholder="Longitude"
                      onChange={(e) => setSelectedLng(+e.target.value)}
                      type="text"
                    />
                    {/* {errors.address && (
                    <div
                      style={{ color: "red" }}
                      className="invalid-feedback d-block"
                    >
                      this field is required.
                    </div>
                  )} */}
                  </label>
                </div>


                {/* <MiniMap
                  centerPoint={{
                    lat: selectedLat,
                    lng: selectedLng
                  }}
                /> */}

                <div className="col-md-6 text-center">

                  {
                    isMapshown &&
                    (
                      <SimpleMap
                        dragable={true}
                        onLocationChange={(latlng) => {
                          // console.log('m je udhr sy arha hun',latlng)
                          setSelectedLat(latlng.lat)
                          setSelectedLng(latlng.lng)
                        }}
                        latlngVal={{
                          lat: selectedLat,
                          lng: selectedLng
                        }}
                        style={{ height: 50 }} />
                    )

                  }



                </div>

                <div className="col-md-12  mt-2">
                  <label className="single-input-inner style-bg-border">
                    <span className="label">Description</span>
                    <textarea
                      placeholder="Description"
                      {...register("description", { required: false })}
                      type="text"
                    />
                  </label>
                </div>

                <div
                  className="col-md-12"
                  style={{ margin: 5, marginLeft: -50 }}
                >
                  {/* <div style={{ backgroundColor: appColors.appPrimaryColor }} className="btn btn-base hover-none button-alignment">
                  Sale Information
                </div> */}
                  {saleEnable ? (
                    <div
                      style={{ backgroundColor: appColors.appPrimaryColor }}
                      className="btn btn-base hover-none button-alignment"
                    >
                      Sale Information
                    </div>
                  ) : installmentEnabled ? (
                    <div
                      style={{ backgroundColor: appColors.appPrimaryColor }}
                      className="btn btn-base hover-none button-alignment"
                    >
                      Installment Information
                    </div>
                  ) : null}
                </div>

                {saleEnable ? (
                  <>
                    <div className="col-md-6">
                      <label className="single-input-inner style-bg-border">
                        <div style={{ display: "flex" }}>
                          <span className="label">Asking Price</span>
                          <span style={{ color: "red", marginLeft: "0.3%" }}>
                            *
                          </span>
                        </div>

                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            placeholder="Sales Price"
                            name="sale_price"
                          
                            {...register("sale_price", { required: true })}
                            type={"number"}
                          />
                          <h5
                            style={{
                              position: "absolute",
                              right: '26px',
                              top: '46px',
                              // bottom: 30,
                            }}
                          >
                            $
                          </h5>
                        </div>
                        {errors.sale_price && (
                          <div
                            style={{ color: "red" }}
                            className="invalid-feedback d-block"
                          >
                            this field is required.
                          </div>
                        )}
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label className="single-input-inner style-bg-border">
                        <div style={{ display: "flex" }}>
                          <span className="label">Size</span>
                          <span style={{ color: "red", marginLeft: "0.3%" }}>
                            *
                          </span>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <input
                            placeholder="Size"
                            {...register("size", { required: true })}
                            type={"number"}
                          />
                          <h5
                            style={{
                              position: "absolute",
                              right: '26px',
                              top: '46px',
                              // bottom: 30,
                            }}
                          >
                            Sqft
                          </h5>
                        </div>
                        {errors.size && (
                          <div
                            style={{ color: "red" }}
                            className="invalid-feedback d-block"
                          >
                            this field is required.
                          </div>
                        )}
                      </label>
                    </div>

                    {
                      // forleaseEnabled ? (
                      //   <div className="col-md-6">
                      //     <label className="single-input-inner style-bg-border">
                      //       <div style={{ display: "flex" }}>
                      //         <span className="label">Installment Available</span>
                      //         <span style={{ color: "red", marginLeft: "0.3%" }}>
                      //           *
                      //         </span>
                      //       </div>
                      //       {INSTALLMENT_AVAILABLE.map((prp) => (
                      //         <div
                      //           style={{
                      //             border: "2px solid #eeee",
                      //             margin: 5,
                      //             backgroundColor:
                      //               prp.id == selectedInstallmentOption.id
                      //                 ? appColors.appsecondaryColor
                      //                 : "",
                      //           }}
                      //           onClick={() => {
                      //             if (prp.id == 1) {
                      //               setselectedInstallmentOption(prp);
                      //               setinstallmentEnabled(true);
                      //             } else if (prp.id == 0) {
                      //               setselectedInstallmentOption(prp);
                      //               setinstallmentEnabled(false);
                      //             }
                      //           }}
                      //           className={`btn ${prp.id == selectedInstallmentOption.id
                      //             ? "btn-success"
                      //             : ""
                      //             }`}
                      //           key={prp.id}
                      //         >
                      //           {prp.title}
                      //         </div>
                      //       ))}
                      //     </label>
                      //   </div>
                      // ) : null

                    }
                  </>
                ) : null}

                {selectedpurpose.id == 2 ? (
                  <>
                    <div className="col-md-6">
                      <label className="single-input-inner style-bg-border">
                        <div style={{ display: "flex" }}>
                          <span className="label">Number of Installment</span>
                          <span style={{ color: "red", marginLeft: "0.3%" }}>
                            *
                          </span>
                        </div>
                        <input

                          placeholder="Number of Installment"
                          {...register("number_of_installment", {
                            required: true,
                            
                          })}
                          
                          type="number"
                        />
                        {errors.number_of_installment && (
                          <div
                            style={{ color: "red" }}
                            className="invalid-feedback d-block"
                          >
                            This field is required.
                          </div>
                        )}
                      </label>
                    </div>

                    <div className="col-md-6">
                      <label className="single-input-inner style-bg-border">
                        <div style={{ display: "flex" }}>
                          <span className="label">Monthly Installment</span>
                          <span style={{ color: "red", marginLeft: "0.3%" }}>
                            *
                          </span>
                        </div>
                        <input
                          placeholder="Monthly Installment"
                          {...register("monthly_installment", {
                            required: true,
                          })}
                          type="number"
                        />
                        {errors.monthly_installment && (
                          <div
                            style={{ color: "red" }}
                            className="invalid-feedback d-block"
                          >
                            this field is required.
                          </div>
                        )}
                      </label>
                    </div>

                    <div className="col-md-6">
                      <label className="single-input-inner style-bg-border">
                        <div style={{ display: "flex" }}>
                          <span className="label">Advance Payment</span>
                          <span style={{ color: "red", marginLeft: "0.3%" }}>
                            *
                          </span>
                        </div>
                        <input
                          placeholder="Advance Payment"
                          {...register("advance_payment", { required: true })}
                          type="number"
                        />
                        {errors.advance_payment && (
                          <div
                            style={{ color: "red" }}
                            className="invalid-feedback d-block"
                          >
                            this field is required.
                          </div>
                        )}
                      </label>
                    </div>
                  </>
                ) : null}

                {/* Pickers */}

                {/* <div className="col-md-6">
                <div className="single-select-inner style-bg-border">
                  <div style={{ display: "flex" }}>
                    <span className="label">Category</span>
                    <span style={{ color: "red", marginLeft: "0.3%" }}>*</span>
                  </div>
                  <Select
                    // value={selectedOption}
                    onChange={(e) => onchangeCategoryType(e)}
                    options={categoryList}
                  />
                  {categoryError && (
                    <div
                      style={{ color: "red" }}
                      className="invalid-feedback d-block"
                    >
                      {categoryError}
                    </div>
                  )} 
                  
                </div>
              </div> */}

                {categoryTypesId && (
                  <div
                    className="col-md-12"
                    style={{ margin: 5, marginLeft: -50 }}
                  >
                    <div
                      style={{ backgroundColor: appColors.appPrimaryColor }}
                      className="btn btn-base hover-none button-alignment"
                    >
                      Listings Features
                    </div>
                  </div>
                )}

                <div className="col-md-12">
                  <div className={"col-md-12"}>
                    <div className={"col-md-12 d-flex justify-content-center"}>
                      {categoryTypesId ? (
                        <Button
                          style={{
                            color: "white",
                            backgroundColor: appColors.appsecondaryColor,
                            // marginTop: "1rem",
                            marginbottom: "1rem",
                            width: "100%",
                          }}
                          onClick={handleShow}
                        >
                          Add Features
                        </Button>
                      ) : null}
                    </div>
                    <Modal
                      size="lg"
                      aria-labelledby="example-modal-sizes-title-lg"
                      show={show}
                      onHide={handleClose}
                    >
                      <Modal.Header title="Add Features">
                        <Modal.Title id="example-modal-sizes-title-lg">
                          Add Features
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div id="test" className="container-fluid">
                          {/* <div className="row"> */}
                          {featuresList.map((feature, i) => (
                            <div className="row single-select-inner style-bg-border">
                              <span
                                style={{ fontSize: "1.3rem" }}
                                className="label col-md-12"
                              >
                                {feature.name}
                              </span>
                              {feature.features.map((onefea, index) => (
                                <div className="col-md-4" key={index + 1}>
                                  {onefea.input_type == "number" || onefea.input_type == "text" ? (
                                    <div>
                                      <InputLabel
                                        style={{
                                          marginTop: "1rem",
                                          fontWeight: "600",
                                        }}
                                        placeholder={onefea.placeholder}

                                      >
                                        {onefea.name}
                                      </InputLabel>
                                      {
                                        onefea.name == "Parcel Number" ?
                                          <InputMask
                                            placeholder={onefea.placeholder}
                                            style={{ height: 55 }}
                                            alwaysShowMask={true}
                                            mask="999-9999-999"
                                            maskChar={null}
                                            onChange={(e) =>
                                              onChangeTextFunc(
                                                e.target.value,
                                                index,
                                                onefea.id,
                                                2,
                                                feature.id
                                              )
                                            }
                                            // beforeMaskedValueChange={beforeMaskedValueChange}
                                            className='form-control'
                                          // value={value}
                                          />
                                          : onefea.name == "Improvements Assessment" || onefea.name == "Land Assessment" || onefea.name == "Total Assessment" ?
                                            <TextField

                                              placeholder={onefea.placeholder}
                                              type={onefea.input_type}
                                              style={{ width: "100%" }}
                                              // name={onefea.name}

                                              // value={selectedFeatures[index]}
                                              onChange={(e) =>
                                                onChangeTextFunc(
                                                  e.target.value,
                                                  index,
                                                  onefea.id,
                                                  2,
                                                  feature.id
                                                )
                                              }
                                              InputProps={{
                                                endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                              }}


                                              id="outlined-basic"
                                              // label={onefea.name}
                                              variant="outlined"
                                            />
                                            :
                                            <TextField

                                              placeholder={onefea.placeholder}
                                              type={onefea.input_type}
                                              style={{ width: "100%" }}
                                              // name={onefea.name}
                                              // value={selectedFeatures[index]}
                                              onChange={(e) =>
                                                onChangeTextFunc(
                                                  e.target.value,
                                                  index,
                                                  onefea.id,
                                                  2,
                                                  feature.id
                                                )
                                              }

                                              id="outlined-basic"
                                              // label={onefea.name}
                                              variant="outlined"
                                            />
                                      }
                                    </div>
                                  ) : onefea.input_type == "checkbox" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Checkbox
                                        onChange={(e) =>
                                          onChangeTextFunc(
                                            e.target.checked,
                                            index,
                                            onefea.id,
                                            1,
                                            feature.id
                                          )
                                        }
                                        // name={onefea.name}
                                        // defaultChecked
                                        color="primary"
                                        inputProps={{
                                          "aria-label": "secondary checkbox",
                                        }}
                                      />
                                      <InputLabel
                                        style={{
                                          fontWeight: "600",
                                          marginTop: "10px",
                                        }}
                                      >
                                        {onefea.name}
                                      </InputLabel>
                                    </div>
                                  ) : onefea.input_type == "select" ? (
                                    <div>
                                      <InputLabel
                                        style={{
                                          marginTop: "1rem",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {onefea.name}
                                      </InputLabel>
                                      <Select
                                        menuPortalTarget={document.body}
                                        styles={colourStyles}
                                        onChange={(e) =>
                                          onChangeTextFunc(
                                            e.label,
                                            index,
                                            onefea.id,
                                            3,
                                            feature.id
                                          )
                                        }
                                        options={filter(onefea.options)}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                            </div>
                          ))}
                          {/* </div> */}
                        </div>
                      </Modal.Body>
                      <Modal.Footer
                        className="d-flex"
                        style={{ justifyContent: "center" }}
                      >
                        <Button
                          style={{
                            backgroundColor: "#dd3939",
                            borderRadius: "0.3rem",
                          }}
                          onClick={handleClose}
                        >
                          Close
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "#32b332",
                            borderRadius: "0.3rem",
                          }}
                          onClick={handleClose}
                        >
                          Save Changes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
                {/* Pickers end */}

                <div
                  className="col-md-12"
                  style={{ margin: 5, marginLeft: -50 }}
                >
                  <div
                    style={{ backgroundColor: appColors.appPrimaryColor }}
                    className="btn btn-base hover-none button-alignment"
                  >
                    Investment Highlights
                  </div>
                </div>

                <div className="col-md-12" style={{ marginTop: 10 }}>
                  {investmentHighlights.map((x, i) => {
                    return (
                      <div className="row align-items-center">
                        <div className="form-group col col-md-8 ">
                          <input
                            className="form-control h-auto  px-4 add-more-input"
                            placeholder="Investment Highlights"
                            name="invesmentHighlist"
                            type={"text"}
                            // value={x.requirement_file}
                            onChange={(e) => handleInputChange(e, i)}
                            multiple
                          />
                        </div>

                        {investmentHighlights.length !== 1 && (
                          <div className="form-group col col-md-2">
                            <button
                              className="btn btn-primary bg-primary mr-3 add-and-remove"
                              onClick={() => handleRemoveClick(i)}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                        {investmentHighlights.length - 1 === i && (
                          <div className="form-group col col-md-2 ">
                            <button
                              className=" btn btn-primary bg-primary add-and-remove"
                              onClick={() => handleAddClick()}
                            >
                              Add more
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>

                {/* <span className="mr-3">Minimum 1 required</span> */}


                <div
                  className="col-md-12"
                  style={{ margin: 5, marginLeft: -50 }}
                >
                  <div
                    style={{ backgroundColor: appColors.appPrimaryColor }}
                    className="btn btn-base hover-none button-alignment"
                  >
                    Offering Memorandum & Flyer
                  </div>
                </div>

                <div
                  className="col-12"
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                >
                  <div className="avatar-upload-input text-center">
                    {/* <img
                      src={publicUrl + "assets/img/icon/upload.png"}
                      alt="img"
                    /> */}
                    {/* <h2>Upload Offering Memorandum & Flyer</h2> */}
                    <p>Its must be a pdf, doc or docx</p>
                    <div className="avatar-edit-input">
                      <input
                        onChange={(e) => {
                          handleChangeDocuments(e);
                          showDocuments(e);
                        }}
                        className="btn btn-base col-12"
                        type="file"
                        id="documentUpload"
                        accept=".pdf, .doc, .docx"
                        multiple
                      />
                      <label className="btn btn-base" htmlFor="documentUpload">
                        Click here to Upload
                      </label>
                    </div>
                  </div>
                  <div style={{ width: "fit-content" }}>
                    {documents.map((pic, i) => (
                     <div
                     style={{

                       backgroundColor: "#ececec",
                     }}
                     className="d-flex filesNAmeChose mt-4"
                     key={i + 1}
                   >
                     <div className="d-flex">
                       <p style={{ margin: "0",paddingTop:"7px",paddingLeft:"7px" }}>{pic.name}</p>
                     </div>
                     <div
                       className="danger"
                       onClick={() => removeDocuments(i)}
                       style={{ border: "none", }}
                     >
                       <p  style={{ color: "red", cursor: "pointer",marginLeft:"5px",paddingTop:"6px" }}>X</p>
                     </div>
                   </div>
                    ))}
                  </div>
                </div>

                <div
                  className="col-md-12"
                  style={{ margin: 5, marginLeft: -50 }}
                >
                  <div
                    style={{ backgroundColor: appColors.appPrimaryColor }}
                    className="btn btn-base hover-none button-alignment"
                  >
                    Listings Photos
                  </div>

                </div>
                <span className="mr-3 text-danger">Minimum 1 required</span>
                <div className="col-12">
                  <div className="avatar-upload-input text-center">
                    <img
                      src={publicUrl + "assets/img/icon/upload.png"}
                      alt="img"
                    />
                    {/* <h2>Upload your photo</h2> */}
                    <p>Its must be a clean photo</p>
                    <div className="avatar-edit-input">
                      <input
                        onChange={(e) => {
                          handleChangeImages(e);
                          showImages(e);
                        }}
                        className="btn btn-base"
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        multiple
                      />
                      <label className="btn btn-base" htmlFor="imageUpload">
                        Click here to Upload
                      </label>
                    </div>
                  </div>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {showlist.map((pic, i) => (
                      <div className="pic" key={i + 1}>
                        <img
                          key={i + 1}
                          height={150}
                          width={150}
                          style={{ margin: 5 }}
                          src={pic}
                        />
                        <button
                          className="danger"
                          onClick={() => removeImage(i)}
                          style={{ border: "none" }}
                        >
                          <p style={{ color: "red" }}>X</p>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>


                <div
                  className="col-md-12"
                  style={{ margin: 5, marginLeft: -50 }}
                >
                  <div
                    style={{ backgroundColor: appColors.appPrimaryColor }}
                    className="btn btn-base hover-none button-alignment"
                  >
                    Listings Video
                  </div>
                </div>

                <div className="col-12">
                  <div className="avatar-upload-input text-center">
                    {/* <img
                      src={publicUrl + "assets/img/icon/upload.png"}
                      alt="img"
                    /> */}
                    {/* <h2>Upload your photo</h2> */}
                    <p>Its must be a less than 5mb</p>
                    <div className="avatar-edit-input">
                      <input
                        onChange={(e) => {
                          handleChangeVideos(e);
                          // showImages(e);
                        }}
                        className="btn btn-base"
                        type="file"
                        id="videoUpload"
                        // value={videos[0].name}
                        accept=".flv, .mp4, .m3u8, .ts, .3gp, .mov, .avi, .wmv"
                      // value={videos}
                      />
                      <label className="btn btn-base" htmlFor="videoUpload">
                        {videos
                          ? vedioNAmeShotner(videos.name)
                          : "Click here to Upload"}
                      </label>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-6">
                <label className="single-input-inner style-bg-border">
                  <div style={{ display: "flex" }}>
                    <span className="label">Address</span>
                    <span style={{ color: "red", marginLeft: "0.3%" }}>*</span>
                  </div>
                  <input type="text" {...register("description", { required: true })} />
                  {errors.address && (
                    <div
                      style={{ color: "red" }}
                      className="invalid-feedback d-block"
                    >
                      this field is required.
                    </div>
                  )}
                </label>
              </div> */}
                {/* <div className="col-md-6">
                <label className="single-input-inner style-bg-border">
                  <div style={{ display: "flex" }}>
                    <span className="label">City</span>
                    <span style={{ color: "red", marginLeft: "0.3%" }}>*</span>
                  </div>
                  <input type="text" />
                </label>
              </div> */}
                {/* <div className="col-md-6">
                <label className="single-input-inner style-bg-border">
                  <div style={{ display: "flex" }}>
                    <span className="label">State</span>
                    <span style={{ color: "red", marginLeft: "0.3%" }}>*</span>
                  </div>
                  <input
                    type="text"
                    {...register("description", { required: true })}
                  />
                  {errors.address && (
                    <div
                      style={{ color: "red" }}
                      className="invalid-feedback d-block"
                    >
                      this field is required.
                    </div>
                  )}
                </label>
              </div> */}
                {/* <div className="col-md-6">
                <label className="single-input-inner style-bg-border">
                  <div style={{ display: "flex" }}>
                    <span className="label">Zip Code</span>
                    <span style={{ color: "red", marginLeft: "0.3%" }}>*</span>
                  </div>
                  <input type="text" {...register("description", { required: true })} />
                  {errors.address && (
                    <div
                      style={{ color: "red" }}
                      className="invalid-feedback d-block"
                    >
                      this field is required.
                    </div>
                  )}
                </label>
              </div> */}
              </div>
            </div>
            {/* <div className="btn btn-base hover-none button-alignment">Contact Details</div> */}
            <div
              // style={{
              //   backgroundColor: '#bdd4e7',
              //   backgroundImage: 'linear-gradient(315deg, #ffffff 0%, #8693ab 74%)'
              // }}
              className="property-form-grid merg mb-5"
            >
              <div className="row">
                <div className="col d-flex justify-content-center align-items-center mb-3">
                  <input
                    type={"checkbox"}
                    value={termsAgree}
                    onChange={(e) => {
                      setAgree(e.target.checked);
                    }}
                  />
                  <p
                    style={{
                      marginLeft: 10,
                      marginTop: 10,
                    }}
                  >
                    I agree to the terms and conditions as set out by the user
                    agreement.
                  </p>
                </div>
                {/* <div className="col-md-4">
              <label className="single-input-inner style-bg-border">
                <input type="text" placeholder="Name" />
              </label>
            </div>
            <div className="col-md-4">
              <label className="single-input-inner style-bg-border">
                <input type="text" placeholder="Email" />
              </label>
            </div>
            <div className="col-md-4">
              <label className="single-input-inner style-bg-border">
                <input type="text" placeholder="Phone" />
              </label>
            </div>
            <div className="col-12">
              <label className="single-input-inner style-bg-border">
                <textarea placeholder="Description" defaultValue={""} />
              </label>
            </div> */}
                <div className="col-12 text-center mb-4">
                  {loading ? (
                    <BeatLoader color={appColors.bluecolor} />
                  ) : (
                    <button
                      type="submit"
                      disabled={!termsAgree}
                      className="btn btn-base"
                    >
                      Submit Now
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddProperty;





