import React, { Component, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux'
import { LoginUser } from '../../redux/actions/Auth.action'
import { appColors } from '../../utils/colors';
import { BeatLoader } from 'react-spinners'
import { useFormik } from "formik";
import * as Yup from "yup";
import './signin.css'

const SignIn = (props) => {

	const initialValues = {
		email: "",
		password: "",
	};

	const history = useHistory()

	// const [email, setemail] = useState('test@system.com')
	// const [password, setpassword] = useState('password')
	// const [error, seterror] = useState('')


	const [passwordToggle, setpasswordToggle] = useState(false)
	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email("Wrong email format")
			.min(3, "Minimum 3 symbols")
			.max(50, "Maximum 50 symbols")
			.required('Email is required'),
		password: Yup.string()
			.min(3, "Minimum 3 symbols")
			.max(50, "Maximum 50 symbols")
			.required('Password is required')

	});


	useEffect(() => {
		const $ = window.$;

		$('body').addClass('bg-gray')
		if (props.isAuthenticated) {
			history.push('/')
		}

	}, [])

	// const handleSignin = (e) => {
	// 	e.preventDefault();
	// 	if (email == "") {
	// 		seterror('Please enter email')
	// 	} else if (password == "")
	// 		seterror('Please enter password')
	// 	else {
	// 		const data = {
	// 			email,
	// 			password,
	// 		}
	// 		props.LoginUser(data, history)

	// 	}
	// }



	const getInputClasses = (fieldname) => {
		if (formik.touched[fieldname] && formik.errors[fieldname]) {
			return "is-invalid";
		}

		if (formik.touched[fieldname] && !formik.errors[fieldname]) {
			return "is-valid";
		}

		return "";
	};

	const formik = useFormik({
		initialValues,
		validationSchema: LoginSchema,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			const user = {
				email: values.email,
				password: values.password
			}
			props.LoginUser(user, history)
		}
	})






	return <div className="signin-page-area pd-top-100 ">
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-xl-6 col-lg-7">
					{
						props.errorMessage && <div className="alert alert-danger" role="alert">
							{props.errorMessage}
						</div>
					}

					<form
						onSubmit={formik.handleSubmit}
						className="form fv-plugins-bootstrap fv-plugins-framework signin-inner"
					>
						{formik.status ? (
							<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
								<div className="alert-text font-weight-bold">{formik.status}</div>
							</div>
						) : (
							null
							// <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
							//   <div className="alert-text ">
							//     Use account <strong>admin@demo.com</strong> and password{" "}
							//     <strong>demo</strong> to continue.
							//   </div>
							// </div>
						)}
						<div className="form-group fv-plugins-icon-container">
							<div className='row'>
								<input
									placeholder="Email"
									type="email"
									className={`form-control h-auto py-3 px-3 ${getInputClasses(
										"email"
									)}`}
									name="email"
									{...formik.getFieldProps("email")}
								/>

							</div>
							{formik.touched.email && formik.errors.email ? (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block text-danger">{formik.errors.email}</div>
								</div>
							) : null}
						</div>
						<div className="form-group fv-plugins-icon-container">
							<div className='row'>
								<input
									placeholder="Password"
									type={passwordToggle ? 'text' : 'password'}
									className={`form-control h-auto py-3 px-3 ${getInputClasses(
										"password"
									)}`}
									name="password"
									{...formik.getFieldProps("password")}
								/>
								<i
									onClick={() => setpasswordToggle(!passwordToggle)}
									class={`far ${passwordToggle ? 'fa-eye' : 'fa-eye-slash'}`} id="togglePassword"
									style={{
										cursor: 'pointer', marginLeft: '-30px', zIndex: 100,
										marginTop: '20px'
									}}></i>

							</div>
							{formik.touched.password && formik.errors.password ? (
								<div className="fv-plugins-message-container">
									<div className="fv-help-block text-danger">{formik.errors.password}</div>
								</div>
							) : null}
						</div>
						<div className="row mb-3 signin">
							<div className="col-12 forget-pass">
								<Link to={'/forget-password'} >Forgot Your Password?</Link>

							</div>
							{/* <div class="col-6">
								<Link to={'/sign-up'}><strong>Signup</strong></Link>

							</div> */}
						</div>
						<div className="form-group d-flex flex-wrap justify-content-between align-items-center">
							{
								props.isLoading ?
									<BeatLoader color={appColors.bluecolor} />
									:
									<button
										style={{ backgroundColor: appColors.bluecolor }}
										type='submit'
										className="btn btn-base w-100">Login</button>

							}
						</div>
						<div className="row mb-3">
							<div className=" col-12">
								<p>Don't have an account? <Link to={'/sign-up'}><strong>Signup</strong></Link></p>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>

}

const mapStateToProps = state => ({
	isLoading: state.User.loading,
	errorMessage: state.User.errorMessage,
	isAuthenticated: state.User.isAuthenticated

})

export default connect(mapStateToProps, { LoginUser, })(SignIn);