import React, { Component, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../services/config';
import axios from 'axios';


const Sidebar = () => {

  const [categoryList, setcategoryList] = useState([])

  const authToken = localStorage.getItem('userToken')

  const [blogs, setblogs] = useState([]);

  const getBlogs = () => {
    axios
      .get(`${baseUrl}/blogs`)
      .then((res) => {
        console.log("blogs", res.data.payload);
        if (res.data) {
          setblogs(res.data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {

    getBlogs( )
    const $ = window.$;
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    $('body').addClass('body-bg');
    getCategories()
  }, [])


  const getCategories = () => {
    axios.get(`${baseUrl}/list/classifications`)
      .then(resp => {
        setcategoryList(resp.data.payload)
        console.log('categoryyyyy', resp.data.payload[0].name)


      }).catch(err => {
        console.log(err)
      })
  }



  let anchor = '#'
  let imagealt = 'image'
  let publicUrl = process.env.PUBLIC_URL + '/'
  return (
    <div className="col-lg-4">
      <aside className="sidebar-area">
        <div className="widget widget-search">
          <div className="single-search-inner">
            <input type="text" placeholder="Search your keyword" />
            <button><i className="la la-search" /></button>
          </div>
        </div>
        {/* <div className="widget widget-author text-center">
                <h4 className="widget-title">About Me</h4>
                <div className="thumb">
                  <img src={publicUrl+"assets/img/agent/1.png"} alt="img" />
                </div> 
                <div className="details">
                  <h5>Sandara Mrikon</h5>
                  <p>Lorem ipsum dolor amet, Lore ipsum dolor sit amet, consectetur et  eiLorem ipsum dolor sit amet</p>
                  <ul>
                    <li><a href="#"><i className="fab fa-facebook-f" aria-hidden="true" /></a></li>
                    <li><a href="#"><i className="fab fa-linkedin-in" aria-hidden="true" /></a></li>
                    <li><a href="#"><i className="fab fa-instagram" aria-hidden="true" /></a></li>
                    <li><a href="#"><i className="fab fa-twitter" aria-hidden="true" /></a></li>
                  </ul>
                </div>        
              </div> */}
        <div className="widget widget-news">
          <h5 className="widget-title">Popular Feeds</h5>
          {blogs.slice(0, 3).map((blog, index) => (
            <div className="single-news-wrap media">
              <div className="thumb">
                <img
                  style={{ height: "86px", width: "86px" }}
                  src={
                    blog.images[0]?.original_url
                      ? blog.images[0]?.original_url
                      : publicUrl + "assets/img/project/10.png"
                  }
                  alt="img"
                />
              </div>
              <div className="media-body align-self-center">
                <h6>
                  <Link to={{
                    pathname: `/blog-details`,
                    state: {
                      blogDetails: blog
                    }
                  }}>{blog.title}</Link>
                </h6>
                {/* <p className="date">
                        {renderHTML(blog.description.slice(0, 20))}
                      </p> */}
              </div>
            </div>
          ))}
        </div>
        <div className="widget widget-category">
          <h5 className="widget-title">Category</h5>
          <ul>
            {
              categoryList.map((category) => (

                <li><Link to="/property">{category.name}<span>2</span></Link></li>

              ))
            }
            {/* <li><Link to="/property">Office <span>26</span></Link></li>
                  <li><Link to="/property">Industrial <span>20</span></Link></li>
                  <li><Link to="/property">Retail <span>21</span></Link></li>
                  <li><Link to="/property">Resturant <span>21</span></Link></li>
                  <li><Link to="/property">Shopping Center <span>1</span></Link></li>
                  <li><Link to="/property">MultiFmaily <span>31</span></Link></li>
                  <li><Link to="/property">Specialty <span>25</span></Link></li>
                  <li><Link to="/property">Health Care <span>11</span></Link></li>
                  <li><Link to="/property">Hospitality <span>10</span></Link></li>
                  <li><Link to="/property">Sports & Entertainment <span>3</span></Link></li>
                  <li><Link to="/property">Land <span>21</span></Link></li>
                  <li><Link to="/property">Residential Income <span>27</span></Link></li> */}
            {/* <li><Link to="/blog">Global World <span>31</span></Link></li>
                  <li><Link to="/blog">Technology <span>12</span></Link></li>
                  <li><Link to="/blog">Ui Design <span>12</span></Link></li> */}
          </ul>
        </div>
        {/* <div className="widget widget-instagram">
                <h5 className="widget-title">Instagram Feed</h5>
                <div className="widget-instagram-inner">
                  <div className="row custom-gutters-10">
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/1.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/2.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/3.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/4.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/5.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/6.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/7.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/8.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="thumb">
                        <img src={publicUrl+"assets/img/instagram/9.png"} alt="img" />
                        <i className="fab fa-instagram" />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
        <div className="widget widget-add">
          <div className="thumb">
            <img src={publicUrl + "assets/img/bulvd.jpg"} alt="img" />
          </div>
        </div>
        {/* <div className="widget widget-tags">
                <h5 className="widget-title">Popular Tags</h5>
                <div className="tagcloud">
                  <Link to="/blog">Popular</Link>
                  <Link to="/blog">Art</Link>
                  <Link to="/blog">Business</Link>
                  <Link to="/blog">Design</Link>
                  <Link to="/blog">Creator</Link>
                  <Link to="/blog">CSS</Link>
                  <Link to="/blog">Planing</Link>
                  <Link to="/blog">Creative</Link>
                </div>   
              </div> */}
      </aside>
    </div>
  )
}

export default Sidebar;
