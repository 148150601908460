import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { baseUrl } from "../../services/config";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import ImageSlider from "../custom-slider/ImageSlider";
import { MAP_API_KEY } from "../../constants/mapApiKey";
import { SimpleMap } from "../google-map/SimpleMap";
// import CustomGoogleMap from "../google-map/CustomGoogleMap";
import renderHTML from 'react-render-html';


const PropertyDetails = (props) => {


  // const settings = {
  //   customPaging: function (i) {
  //     return (
  //       <a>
  //         <img src={`${baseUrl}/abstract0${i + 1}.jpg`} />
  //       </a>
  //     );
  //   },
  //   dots: true,
  //   dotsClass: "slick-dots slick-thumb",
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  // };

  const isAuthenticated = useSelector((state) => state.User.isAuthenticated);

  const [loading, setLoading] = useState(false);
  const [loadingFav, setLoadingFav] = useState(false);
  const [categoryList, setcategoryList] = useState([]);
  const [dataGrap, setDataGrap] = useState(false);
  const [blogs, setblogs] = useState([]);

  const authToken = localStorage.getItem("userToken");
  const [mypropertyDetails, setmypropertyDetails] = useState({});
  const [imgData, setImageData] = useState([
    {
      image: "",
    },
  ]);

  // const [latlng,setLatlng]=useState({});
  const [tempVar, setTempVar] = useState(0);
  const [tempFrist, setTempFirst] = useState(false);
  const [features, setFeatures] = useState([]);
  const [favProperty, setFavProperty] = useState(false);

  const id = useParams();

  useEffect(() => {
    const $ = window.$;
    $(document).ready(function () {
      $(this).scrollTop(0);
    });
    $("body").addClass("body-bg");
    getPropertyDetails();
    getCategories();
    getBlogs();
  }, []);

  const getPropertyDetails = async () => {
    setLoading(true);

    let url = isAuthenticated ? `${baseUrl}/user/properties/${id.id}` : `${baseUrl}/public/properties/${id.id}`

    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        // console.log("Imagessss", res.data.payload);
        setmypropertyDetails(res.data.payload);
        setLoading(false);
        if (mypropertyDetails) {
          setDataGrap(true);
        }
        // console.log("", res.data.payload)
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getCategories = async () => {
    await axios
      .get(`${baseUrl}/list/classifications`)
      .then((resp) => {
        setcategoryList(resp.data.payload);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    imageDataConverted();
  }, [dataGrap]);

  const captionStyle = {
    fontSize: "2em",
    fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  let publicUrl = process.env.PUBLIC_URL + "/";
  // let mapKey = process.env. + "/";
  console.clear();
  function imageDataConverted() {
    let tempArr = [];

    if (dataGrap) {
      mypropertyDetails.images.forEach((element) => {
        tempArr.push({
          image: element.original_url,
        });
      });
      setImageData(tempArr);
      console.log("Data" + JSON.stringify(imgData));
    }
  }

  const getBlogs = () => {
    axios
      .get(`${baseUrl}/blogs`)
      .then((res) => {
        console.log("blogs", res.data.payload);
        if (res.data) {
          setblogs(res.data.payload);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const favProperties = async () => {
    setLoadingFav(true);
    await axios
      .get(`${baseUrl}/properties/${id.id}/toggle-favorites`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
      .then((res) => {
        setLoadingFav(false);
        console.log(res.data);
        if (res.data.metadata.success) {
          getPropertyDetails()
        }
      })
      .catch((err) => {
        console.log(err);
        setLoadingFav(false);
      });
  };




  console.log("my prop details", mypropertyDetails)



  const getMonthName2 = date => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const d = new Date(date)
    return `${monthNames[d.getMonth()]} ${d.getDate()} ,${d.getFullYear()}`
  }



  return (
    <div className="property-page-area pd-top-120 pd-bottom-90 ">
      <div className="container">
        <div className="property-details-top pd-bottom-70">
          {loading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
              }}
            ></div>
          ) : (
            <div className="property-details-top-inner">
              <div className="row">
                <div className="col-lg-7">
                  <h3>{mypropertyDetails.title}</h3>
                  <p>
                    <img
                      src={publicUrl + "assets/img/icon/location2.png"}
                      alt="img"
                    />{" "}
                    {mypropertyDetails.address}
                  </p>
                  <ul>
                    {/* <li>3 Bedroom</li> */}
                    {/* <li>Bathroom</li> */}
                    <li>{mypropertyDetails.size} sqft</li>
                  </ul>
                </div>
                <div className="col-lg-5 text-lg-right">
                  {
                    // mypropertyDetails.purpose == 1 ?
                    <h4>${mypropertyDetails.price}</h4>
                    // :
                    // null
                  }
                  <div className="btn-wrap">
                    {/* {mypropertyDetails.purpose == 1 ? (
                      <a className="btn btn-base btn-sm">SALE</a>
                    ) : (
                      <a className="btn btn-base btn-sm">RENT</a>
                    )} */}

                    <span className=" btn-base btn-sm" style={{width:"fit-content",paddingLeft:"10px",paddingRight:"10px"}}>
                      {mypropertyDetails.category?.name}
                    </span>
                  </div>
                  <ul>
                    <li>
                      <img
                        src={publicUrl + "assets/img/icon/1.png"}
                        alt="img"
                      />
                      {getMonthName2(mypropertyDetails.listed_on)}
                      {/* Marce 9 , 2020 */}
                    </li>
                    {/* <li>
                      <img
                        src={publicUrl + "assets/img/icon/2.png"}
                        alt="img"
                      />

                    </li> */}

                    {isAuthenticated && (
                      <li>

                        {
                          mypropertyDetails.favorite ? (
                            <i
                              className="fa fa-heart courser"
                              onClick={favProperties}
                            />
                          ) : (
                            <i
                              className="far fa-heart courser"
                              onClick={favProperties}
                            />
                          )}

                      </li>
                    )}
                    {loadingFav && <CircularProgress size={15} />}
                  </ul>
                </div>
              </div>
            </div>
          )}

          <div
            style={{ marginTop: '4rem' }}
            className="product-thumbnail-wrapper">
            <div className="slides-carousel">
              <ImageSlider slides={imgData} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 mt-3">
            <div className="single-property-details-inner">
              {/* <h4>{mypropertyDetails.title}</h4> */}
              <h4>Executive Summary</h4>
              <p style={{ textAlign: 'justify' }}>{mypropertyDetails.description}</p>
              {/* <p>Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet, consectetuLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> */}
              {/* <div className="single-property-grid">
                <h4>Poperty Details</h4>
                <div className="row">
                  <div className="col-md-4">
                    <ul>
                      <li>Bedrooms: 3</li>
                      <li>All Rooms: 12</li>
                      <li>Kitchen: 2</li>
                      <li>Type: Privet House</li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li>Bedrooms: 3</li>
                      <li>Livingroom: 2</li>
                      <li>Year Built: 2020</li>
                      <li>Area: 1258</li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li>Bedrooms: 3</li>
                      <li>All Rooms: 12</li>
                      <li>Kitchen: 2</li>
                      <li>Type: Privet House</li>
                    </ul>
                  </div>
                </div>
              </div> */}
              <div className="single-property-grid">
                <h4>Investment Highlights</h4>
                <div className="row">
                  <div className="col">
                    <ul>
                      {mypropertyDetails?.investment_highlights && mypropertyDetails?.investment_highlights.map((item, index) => (
                        <li className="list-item" key={index}>
                          {" "}
                          {item}
                        </li>
                      )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              {mypropertyDetails.amenities?.map((item, index) => (
                <div className="single-property-grid" key={index}>
                  <h5>{item.name}</h5>
                  <div className="row">
                    {item.propertyFeatures?.map((item, index) => (
                      <div className="col-6 mt-2" key={index}>
                        <ul>
                          <li style={{ fontSize: '14px' }}>
                            {/* <i className="fa fa-check" /> */}
                            {`${item.name}: ${item.value}`}
                          </li>
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              ))}

              {/* <div className="single-property-grid">
                <h4>Amenities</h4>
                <div className="row">
                  <div className="col-md-4">
                    <ul>
                      <li>
                        <i className="fa fa-check" />
                        Air Conditionar
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Fencing
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Internet
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li>
                        <i className="fa fa-check" />
                        Wardrobes
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        School
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Park
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li>
                        <i className="fa fa-check" />
                        Dishwasher
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Floor Covering
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Internet
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}
              {/* <div className="single-property-grid">
                <h4>Additional Details</h4>
                <div className="row">
                  <div className="col-md-4">
                    <ul>
                      <li>Remodale Year: 3</li>
                      <li>Amenites: Half of Fame</li>
                      <li>Equepment: Grill-gass</li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li>Diposit: 7065$</li>
                      <li>Pool Size: 1620</li>
                      <li>Additional Room: 2</li>
                    </ul>
                  </div>
                  <div className="col-md-4">
                    <ul>
                      <li>Ground: 2</li>
                      <li>Additional Room: 2</li>
                      <li>Floor: 1203</li>
                    </ul>
                  </div>
                </div>
              </div> */}
              {/* <div className="single-property-grid">
                <h4>Features</h4>
                <div className="row">
                  {mypropertyDetails.features?.map((item, index) => {
                    // console.log(item.name)
                    return (
                      <div className="col-md-4" key={index}>
                        <p>{`${item.name}: ${item.value}`}</p>
                      </div>
                    );
                  })}
                </div>
              </div> */}
              {mypropertyDetails.attachments > 0 ? (
                <div className="single-property-grid">
                  <h4>Proparty Attachment</h4>
                  <div className="row">
                    <div className="col-sm-6">
                      <a href="PDFLINK" download>
                        <img
                          src={publicUrl + "assets/img/icon/9.png"}
                          alt="img"
                        />
                      </a>
                    </div>
                    <div className="col-sm-6 mt-2 mt-sm-0">
                      <a href="PDFLINK" download>
                        <img
                          src={publicUrl + "assets/img/icon/9.png"}
                          alt="img"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="single-property-grid">
                <h4>Estate Location</h4>
                {/* <div className="property-map">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d198059.49240377638!2d-84.68048827338674!3d39.13652252762691!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1615660592820!5m2!1sen!2sbd" />
                </div> */}
                <div className="property-map">
                  {mypropertyDetails.latitude && (
                    <SimpleMap

                      dragable={false}
                      latlngVal={{
                        lat: mypropertyDetails.latitude,
                        lng: mypropertyDetails.longitude,
                      }}
                      style={{ height: 25 }}
                    />
                  )}

                  {/* <SimpleMap latlngVal={latlng}/> */}
                  {/* <CustomGoogleMap
                    lat={mypropertyDetails.latitude}
                    lng={mypropertyDetails.longitude}
                  /> */}
                </div>
              </div>

              {
                mypropertyDetails.video ?
                  <div className="single-property-grid">
                    <h4>Intro Video</h4>
                    <div
                      className="property-video text-center"
                      style={{
                        background: "url(" + publicUrl + "assets/img/bg-blwd.jpg)",
                      }}
                    >
                      <a
                        className="play-btn"
                        href={mypropertyDetails.video?.original_url}
                        data-effect="mfp-zoom-in"
                      >
                        <i className="fa fa-play" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                  : null

              }
          {mypropertyDetails.attachments?.length>0?  <div className="single-property-grid">
              <h4>Attachments</h4>
            {mypropertyDetails.attachments.map((item,i)=>(
              <>
               <div className="d-flex">
                <p>{i+1}.</p>
              <p style={{marginLeft:"15px"}}>{item.file_name}</p>
              <a  style={{marginLeft:"5px",color:"red"}}  href={item.original_url} target="_blank">
              <i class="fa fa-download" aria-hidden="true"></i>
                    </a>
              </div>
              </>
            )) }
              </div>:null}
              {/* <div className="single-property-grid">
							<h4>Floor Plans</h4>
							<img src={publicUrl + "assets/img/project-single/6.png"} alt="img" />
						</div> */}
              {/* <div className="single-property-grid">
                <h4>Intro Video</h4>
                <div
                  className="property-video text-center"
                  style={{
                    background: "url(" + publicUrl + "assets/img/bg-blwd.jpg)",
                  }}
                >
                  <a
                    className="play-btn"
                    href="https://www.youtube.com/embed/Wimkqo8gDZ0"
                    data-effect="mfp-zoom-in"
                  >
                    <i className="fa fa-play" aria-hidden="true" />
                  </a>
                </div>
              </div> */}
              {/* <div className="single-property-grid">
							<h4>Whats Nearby?</h4>
							<div className="media single-review-inner">
								<div className="media-left">
									<div className="thumb">
										<img src={publicUrl + "assets/img/project-single/9.png"} alt="img" />
									</div>
								</div>
								<div className="media-body align-self-center">
									<div className="row">
										<div className="col-md-8">
											<h5>Eureka/Harvey Milk Branch</h5>
											<p>consectetuLorem ipsum dolor sit amet</p>
										</div>
										<div className="col-md-4 text-md-right">
											<p className="ratting-title"><span>32</span> Reviews</p>
											<div className="ratting-inner">
												<i className="fa fa-star" />
												<i className="fa fa-star" />
												<i className="fa fa-star" />
												<i className="fa fa-star" />
												<i className="fa fa-star" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="media single-review-inner">
								<div className="media-left">
									<div className="thumb">
										<img src={publicUrl + "assets/img/project-single/10.png"} alt="img" />
									</div>
								</div>
								<div className="media-body align-self-center">
									<div className="row">
										<div className="col-md-8">
											<h5>Milbaly Extension &amp; Academy</h5>
											<p>consectetuLorem ipsum dolor sit amet</p>
										</div>
										<div className="col-md-4 text-md-right">
											<p className="ratting-title"><span>32</span> Reviews</p>
											<div className="ratting-inner">
												<i className="fa fa-star" />
												<i className="fa fa-star" />
												<i className="fa fa-star" />
												<i className="fa fa-star" />
												<i className="fa fa-star" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="media single-review-inner">
								<div className="media-left">
									<div className="thumb">
										<img src={publicUrl + "assets/img/project-single/11.png"} alt="img" />
									</div>
								</div>
								<div className="media-body align-self-center">
									<div className="row">
										<div className="col-md-8">
											<h5>Nilgao School</h5>
											<p>consectetuLorem ipsum dolor sit amet</p>
										</div>
										<div className="col-md-4 text-md-right">
											<p className="ratting-title"><span>32</span> Reviews</p>
											<div className="ratting-inner">
												<i className="fa fa-star" />
												<i className="fa fa-star" />
												<i className="fa fa-star" />
												<i className="fa fa-star" />
												<i className="fa fa-star" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> */}
              {/* <div className="single-property-grid">
							<h4>Page statistics</h4>
							<img src={publicUrl + "assets/img/project-single/7.png"} alt="img" />
						</div> */}
              {/* <form className="single-property-comment-form">
							<div className="single-property-grid bg-black">
								<div className="single-property-form-title">
									<div className="row">
										<div className="col-md-8">
											<h4>Post Your Comment</h4>
										</div>
										<div className="col-md-4 text-md-right">
											<div className="ratting-inner">
												<i className="fa fa-star" />
												<i className="fa fa-star" />
												<i className="fa fa-star" />
												<i className="fa fa-star" />me
												<i className="fa fa-star" />
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-6">
										<label className="single-input-inner style-bg">
											<span className="label">Enter Your Name</span>
											<input type="text" placeholder="Your name here...." />
										</label>
									</div>
									<div className="col-lg-6">
										<label className="single-input-inner style-bg">
											<span className="label">Enter Your MAil</span>
											<input type="text" placeholder="Your email here...." />
										</label>
									</div>
									<div className="col-12">
										<label className="single-input-inner style-bg">
											<span className="label">Enter Your Messege</span>
											<textarea placeholder="Enter your messege here...." defaultValue={""} />
										</label>
									</div>
									<div className="col-12 mb-4">
										<button className="btn btn-base radius-btn">Submit Now</button>
									</div>
								</div>
							</div>
						</form> */}
            </div>
          </div>
          <div className="col-lg-4 mt-3">
            <aside className="sidebar-area">
              <div className="widget widget-author text-center">
                <h4 className="widget-title">About me</h4>
                <div className="thumb">
                  <img

                    src={
                      publicUrl + "assets/img/logo.png"
                    }
                    alt="img"
                  />
                </div>
                <div className="details">
                  {/* <h5>{mypropertyDetails?.user?.name}</h5> */}

                  {isAuthenticated ? (
                    <>
                      {/* <p>Email:{mypropertyDetails?.user?.email}</p> */}
                      <h5>{mypropertyDetails?.user?.agency?.name}</h5>
                      {/* <p>{mypropertyDetails?.user?.agency?.description}</p> */}
                      <a
                        href={`mailto:sales@theboulevard.us?subject=${mypropertyDetails.title}}&body='Hey there is need info about ${mypropertyDetails.title}`}
                      >
                        <p className="font-weight-bold">
                          Email: sales@theboulevard.us
                        </p>

                      </a>
                      <a href={`tel:+1916793-4048`}>
                        <p className="font-weight-bold">
                          Contact: +1 (916) 793-4048
                        </p>

                      </a>

                      {/* <div className="thumb">
												<img src={mypropertyDetails?.user?.agency?.logo?.original_url ? mypropertyDetails?.user?.agency?.logo?.original_url : publicUrl + "assets/img/agent/1.png"} alt="img" />
											</div> */}
                    </>
                  ) : (
                    <Link to={"/sign-in"}>Show details</Link>
                  )}
                  {/* <p></p> */}
                  {/* <ul>
                    <li>
                      <a href="#">
                        <i className="fas fa-browser" aria-hidden="true" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fab fa-linkedin-in" aria-hidden="true" />
                      </a>
                    </li> */}
                  {/* <li><a href="#"><i className="fab fa-instagram" aria-hidden="true" /></a></li> */}
                  {/* <li><a href="#"><i className="fab fa-twitter" aria-hidden="true" /></a></li> */}
                  {/* </ul> */}
                </div>
              </div>
              <div className="widget widget-category">
                <h5 className="widget-title">Category</h5>
                <ul>
                  {/* {categoryList.map((category, index) => (
                    <li key={index}>
                      <Link to="/property">
                        {category.name}
                        <span>2</span>
                      </Link>
                    </li>
                  ))} */}

                  <li>
                    {/* <Link to="/property"> */}
                    {mypropertyDetails.category?.name}
                    {/* <span>2</span> */}
                    {/* </Link> */}
                  </li>
                  {/* <li><Link to="/property">Industrial <span>20</span></Link></li>
                  			<li><Link to="/property">Retail <span>21</span></Link></li>
                  			<li><Link to="/property">Resturant <span>21</span></Link></li>
                  			<li><Link to="/property">Shopping Center <span>1</span></Link></li>
                  			<li><Link to="/property">MultiFmaily <span>31</span></Link></li>
                  			<li><Link to="/property">Specialty <span>25</span></Link></li>
                  			<li><Link to="/property">Health Care <span>11</span></Link></li>
                  			<li><Link to="/property">Hospitality <span>10</span></Link></li>
                  			<li><Link to="/property">Sports & Entertainment <span>3</span></Link></li>
                  			<li><Link to="/property">Land <span>21</span></Link></li>
                  			<li><Link to="/property">Residential Income <span>27</span></Link></li> */}
                </ul>
              </div>
              {/* <div className="widget widget-place">
                <h5 className="widget-title">Place</h5>
                <ul>
                  <li>
                    New York <span>26</span>
                  </li>
                  <li>
                    Love Road <span>20</span>
                  </li>
                  <li>
                    Beach Side <span>21</span>
                  </li>
                  <li>
                    Golden city <span>31</span>
                  </li>
                </ul>
              </div> */}
              <div className="widget widget-news">
                <h5 className="widget-title">Popular Feeds</h5>
                {blogs.slice(0, 3).map((blog, index) => (
                  <div className="single-news-wrap media">
                    <div className="thumb">
                      <img
                        style={{ height: "86px", width: "86px" }}
                        src={
                          blog.images[0]?.original_url
                            ? blog.images[0]?.original_url
                            : publicUrl + "assets/img/project/10.png"
                        }
                        alt="img"
                      />
                    </div>
                    <div className="media-body align-self-center">
                      <h6>
                        <Link to={{
                          pathname: `/blog-details`,
                          state: {
                            blogDetails: blog
                          }
                        }}>{blog.title}</Link>
                      </h6>
                      {/* <p className="date">
                        {renderHTML(blog.description.slice(0, 20))}
                      </p> */}
                    </div>
                  </div>
                ))}
                {/* <div className="single-news-wrap media">
                  <div className="thumb">
                    <img
                      style={{ height: "86px", width: "86px" }}
                      src={publicUrl + "assets/img/blog/12.png"}
                      alt="img"
                    />
                  </div>
                  <div className="media-body align-self-center">
                    <h6>
                      <Link to="/blog-details">
                        Dolor eorem ipsum sit amet Lorem ipsum
                      </Link>
                    </h6>
                    <p className="date">
                      <i className="far fa-calendar-alt" />
                      25 Aug 2020
                    </p>
                  </div>
                </div>
                <div className="single-news-wrap media">
                  <div className="thumb">
                    <img
                      style={{ height: "86px", width: "86px" }}
                      src={publicUrl + "assets/img/1.png"}
                      alt="img"
                    />
                  </div>
                  <div className="media-body align-self-center">
                    <h6>
                      <Link to="/blog-details">
                        Responsive Web And Desktop Develope
                      </Link>
                    </h6>
                    <p className="date">
                      <i className="far fa-calendar-alt" />
                      25 Aug 2020
                    </p>
                  </div>
                </div>
                <div className="single-news-wrap media">
                  <div className="thumb">
                    <img
                      style={{ height: "86px", width: "86px" }}
                      src={publicUrl + "assets/img/5.png"}
                      alt="img"
                    />
                  </div>
                  <div className="media-body align-self-center">
                    <h6>
                      <Link to="/blog-details">
                        Admin Web is Django Highlig Models
                      </Link>
                    </h6>
                    <p className="date">
                      <i className="far fa-calendar-alt" />
                      25 Aug 2020
                    </p>
                  </div>
                </div> */}
              </div>
              {/* <div className="widget widget-tags">
							<h5 className="widget-title">Popular Tags</h5>
							<div className="tagcloud">
								<Link to="/blog">Popular</Link>
								<Link to="/blog">Art</Link>
								<Link to="/blog">Business</Link>
								<Link to="/blog">Design</Link>
								<Link to="/blog">Creator</Link>
								<Link to="/blog">CSS</Link>
								<Link to="/blog">Planing</Link>
								<Link to="/blog">Creative</Link>
							</div>
						</div> */}
            </aside>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
