import { baseUrl } from "../services/config"


export const headers = {
    'Content-Type': 'application/json',
}
export const api = {
    login: `${baseUrl}/login`,
    signup: `${baseUrl}/register`,
    profile: `${baseUrl}/profile`,
    myproperty:`${baseUrl}/property`

}