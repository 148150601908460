import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import './footer.css'



const Footer_v2 = (props) => {



  useEffect(() => {
    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);
  }, []);




  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "Footer logo";

  return (
    <footer
      className=""
      style={{ background: "url(" + publicUrl + "assets/img/footerbg.jpeg)", backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}
    >
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-4">
              {/* <Link to="/"><img src={publicUrl + "assets/img/logo.png"} alt="img" /></Link> */}
              <h1 style={{ color: "white", fontSize: "2rem" }}>
                Follow us on social media
              </h1>
            </div>
            <div className="col-lg-6 col-md-8 text-md-right mt-3 mt-md-0">
              <ul className="social-area">
                <li>
                  <a target={'_blank'} href="https://www.facebook.com/Blvdcre">
                    <i className="fab fa-facebook-f" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a target={'_blank'} href="https://www.twitter.com/solverwp/">
                    <i className="fab fa-twitter" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a target={'_blank'} href="https://www.instagram.com/solverwp/">
                    <i className="fab fa-instagram" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a target={'_blank'} href="https://www.skype.com/solverwp/">
                    <i className="fab fa-skype" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a target={'_blank'} href="https://www.pinterest.com/solverwp/">
                    <i className="fab fa-pinterest-p" aria-hidden="true" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-middle">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="widget widget_about">
                <div className="col-lg-12 col-md-12 ">
                  <Link to="/">
                    <img src={publicUrl + "assets/img/logo.png"} alt="img" />
                  </Link>
                  <p
                    className="mt-2"
                    style={{
                      fontWeight: "bolder",
                      color: "black",
                      fontSize: 18,
                      textAlign: 'justify'
                    }}
                  >
                    As a first ever for the online commercial real estate
                    sector, BoulevardCRE is now introducing its very own branded
                    online platform for easy and reliable transactions.
                    Introducing the new marketplace, BoulevardCRE hopes to
                    revolutionize the way you buy your next commercial property
                    or business.
                  </p>
                  {/* <h1 style={{color: 'white', fontSize: '2rem'}}>Follow us on social media</h1> */}
                </div>
                {/* <h4 className="widget-title">Contact Us</h4>
									<div className="details">
										<p><i className="fas fa-map-marker-alt" />Pakistan, Punjab, Lahore</p>
										<p><i className="fas fa-phone-volume" />+92 (316) 312-0000</p>
										<p><i className="fas fa-envelope" /> contact@blvdnow.com</p>
									</div> */}
              </div>
            </div>
            <div className="for-mobile-footer col-lg-2 col-md-4">
              <div className="widget widget_nav_menu">
                <h4
                  className="widget-title"
                  style={{ fontWeight: "bolder", color: "black" }}
                >
                  Quick link
                </h4>
                <ul style={{ listStyle: 'none' }}>
                  <li style={{ color: "black" }}>
                    <Link to="/about">About Us</Link>
                  </li>
                  <li style={{ color: "black" }}>
                    <Link to="/commercial-listing">Property</Link>
                  </li>
                  <li style={{ color: "black" }}>
                    <Link to="/add-property">Add Property</Link>
                  </li>
                  <li style={{ color: "black" }}>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-6">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title">Categories</h4>
                <ul>
                  <li>
                    <Link to="/blog">Arts &amp; Design</Link>
                  </li>
                  <li>
                    <Link to="/blog">Business</Link>
                  </li>
                  <li>
                    <Link to="/blog">Computer Science</Link>
                  </li>
                  <li>
                    <Link to="/blog">Data Science</Link>
                  </li>
                </ul>
              </div>
            </div> */}
            <div className="for-mobile-footer col-lg-4 col-md-4">
              <div className="widget widget_nav_menu">
                <h4 className="widget-title" style={{fontWeight:"bold",color:"black"}}>Contact Us</h4>
                <ul style={{listStyle:'none'}}>
                  <li>
                    <p style={{ color: "black" }}>
                      <i className="fas fa-phone-volume mr-1" />
                      +1 (916) 793-4048
                    </p>
                  </li>
                  <li>
                    <p style={{ color: "black" }}>
                      <i className="fas fa-envelope mr-1" />{" "}
                      info@theboulevard.us
                    </p>
                  </li>
                  <li>
                    <p style={{ color: "black" }}>
                      <i className="fas fa-map-marker-alt mr-1" />
                      4080 Truxel Road, Suite 100, Sacramento. CA. US 95834
                    </p>
                  </li>
                </ul>
                {/* <div className="details">
										<p><i className="fas fa-map-marker-alt" />Pakistan, Punjab, Lahore</p>
										<p><i className="fas fa-phone-volume" />+92 (316) 312-0000</p>
										<p><i className="fas fa-envelope" /> contact@blvdnow.com</p>
									</div> */}
                {/* <h4 className="widget-title">Newslatter</h4>
									<div className="details">
										<p>Lorem ipsum dolor sit amet,</p>
										<div className="footer-subscribe-inner">
											<input type="text" placeholder="Your Mail" />
											<a className="btn btn-base" href="#">Subscribe</a>
										</div>
									</div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <p>©{new Date().getFullYear().toString()}, Copy Right By Boulevard. All Rights Reserved</p>
            </div>
            <div className="col-lg-6 text-lg-right">
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>

                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer_v2;
