import React from 'react'
import { useParams } from 'react-router-dom';
import CustomNavbar from '../../components/global-components/custom-navbar';
import UpdateProperty from '../../components/update-property/UpdateProperty';
import PageHeader from '../../components/global-components/page-header';
import Footer from '../../components/global-components/footer-v2';


const EditProperty = (props) => {



    const { id } = useParams();

    console.log(id);



    return (
        <div>
            <CustomNavbar />
            <PageHeader headertitle="Update Listing" />
            <UpdateProperty />
            <Footer />
        </div>
    )
}

export default EditProperty