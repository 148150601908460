import { combineReducers } from 'redux'
import ProfileReducer from './Profile.Reducer'
import UserReducer from './User.Reducer'

export default combineReducers({
    User: UserReducer,
    MyProfile: ProfileReducer,
})



