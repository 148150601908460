import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { baseUrl } from "../../services/config";
import { BeatLoader } from "react-spinners";
import { appColors } from "../../utils/colors";
import { BsBuilding } from 'react-icons/bs'

let demoimg = `https://t4.ftcdn.net/jpg/04/70/29/97/360_F_470299797_UD0eoVMMSUbHCcNJCdv2t8B2g1GVqYgs.jpg`;

const Property = () => {
	const history = useHistory();

	const authToken = localStorage.getItem("userToken");

	const [loading, setloading] = useState(false);
	const [AllProperties, setAllProperties] = useState([]);

	useEffect(() => {
		getAllProperties();
	}, []);

	const getAllProperties = () => {
		setloading(true);
		axios
			.get(`${baseUrl}/list/for-you-properties`, {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			})
			.then((res) => {
				console.log(res.data.payload);
				setAllProperties(res.data.payload.data);
				setloading(false);
			})
			.catch((err) => {
				setloading(false);
				console.log(err);
			});
	};

	let publicUrl = process.env.PUBLIC_URL + "/";

	let imgurl = `${publicUrl}assets/img/no-image.jpg`;

	const filterData = (type) => {
		getAllProperties();

		const filteredData = AllProperties.filter((item) => item.purpose == type);

		setAllProperties(filteredData);
	};

	// const filteredData = AllProperties.filter(item => item.purpose == 2)

	const ViewProperty = (id) => {
		history.push(`/property-details/${id}`);
	};

	return (
		<div className="propartes-area pd-top-50 pd-bottom-90 ">
			<div className="container">
				<div className="section-title text-center">
					{/* <h6>Our Po</h6> */}
					<h2>All Listings</h2>
				</div>
				{/* <div className="mgd-tab-inner text-center">
          <ul className="nav nav-tabs" id="myTab" role="tablist">
             <li onClick={() => filterData(2)} className="nav-item">
						<a className="nav-link active" id="rent1-tab" data-toggle="tab" href="#rent1" role="tab" aria-controls="rent1" aria-selected="true">Rent</a>
					</li> 
            <li className="nav-item">
              <span
                className="nav-link"
                id="sell1-tab"
                href="#sell1"
                role="tab"
                aria-controls="sell1"
                aria-selected="false"
                style={{ backgroundColor: "#ffb769", color: "white" }}
              >
                Sell
              </span>
            </li>
          </ul>
        </div>
		*/}
				{loading && (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
						}}
					>
						<BeatLoader color={appColors.bluecolor} />
					</div>
				)}
				<div className="tab-content" id="myTabContent">
					<div
						className="tab-pane fade show active our-sell"
						id="rent1"
						role="tabpanel"
						aria-labelledby="rent1-tab"
					>
						<div className="row listing-card">
							{AllProperties.slice(0, 9).map((property, index) => (
								<div
									key={index}
									//   style={{ height: 400, width: 350 }}
									className="col-lg-4 col-md-6 card-centring"
								>
									<div className="single-product-wrap style-bottom">
										<Link to={`/property-details/${property.id}`}>
											<div className="thumb">
												<img
													style={{
														height: "195px",
														width: "350px",
													}}
													src={
														property.image
															? property.image?.original_url
															: imgurl
													}
													alt="img"
												/>
												<div className="product-wrap-details">
													<div className="media">
														<div className="author">
															<img
																style={{ height: 50, width: 50, objectFit: 'contain' }}
																src={
																	publicUrl + "assets/img/logo.png"
																}
																alt="img"
															/>
														</div>
														<div className="media-body">
															<h6>
																<Link to="/property">
																	{property?.user?.name}
																</Link>
															</h6>
															<p>
																<img
																	src={
																		publicUrl +
																		"assets/img/icon/location-alt.png"
																	}
																	alt="img"
																/>
																{property.address}{" "}
															</p>
														</div>
														{/* <a className="fav-btn float-right" href="#">
                              <i className="far fa-heart" />
                            </a> */}
													</div>
												</div>
											</div>
										</Link>
										<div className="product-details-inner">
											<h4>
												<Link
													to={`/property-details/${property.id}`}
												// onClick={() => ViewProperty(property.id)}
												>
													{property?.title.length > 15
														? property?.title.substr(0, 15) + "..."
														: property?.title}
												</Link>
											</h4>
											<ul className="meta-inner">
												<li>
													<img
														src={publicUrl + "assets/img/icon/location2.png"}
														alt="img"
													/>
													{property?.city?.name}
												</li>
												<li>
													<Link>{property.category.name}</Link>
												</li>
											</ul>
											{/* <p>{}</p> */}
										</div>
										<div className="product-meta-bottom">
											<span className="price">$ {property.price}</span>
											<div className="float-right d-inline-block">
												<ul style={{ listStyle: 'none' }}>
													{/* <li>
                          <img
                            src={publicUrl + "assets/img/icon/triangle.png"}
                            alt="img"
                          />
                          2
                        </li>
                        <li>
                          <img
                            src={publicUrl + "assets/img/icon/bed.png"}
                            alt="img"
                          />
                          3
                        </li> */}
													<li>
														<BsBuilding color="black" />{" "}
														{property.size} sq ft
													</li>
												</ul>
											</div>
											{/* <span>Featured</span>
                      <span>1 year ago</span> */}
										</div>
									</div>
								</div>
							))}
							<div className="col-12 d-flex justify-content-center">
								<button
									className="btn btn-danger bg-danger"
									onClick={() => history.push(`/commercial-listing`)}
								>
									View more
								</button>
							</div>
							{/* <div className="col-lg-4 col-md-6">
							<div className="single-product-wrap style-bottom">
								<div className="thumb">
									<img src={publicUrl + "assets/img/project/2.png"} alt="img" />
									<div className="product-wrap-details">
										<div className="media">
											<div className="author">
												<img src={publicUrl + "assets/img/author/2.png"} alt="img" />
											</div>
											<div className="media-body">
												<h6><Link to="/property">PreeSoft</Link></h6>
												<p><img src={publicUrl + "assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
											</div>
											<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
										</div>
									</div>
								</div>
								<div className="product-details-inner">
									<h4><Link to="/property-details">Gorgeous Villa</Link></h4>
									<ul className="meta-inner">
										<li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" />New York</li>
										<li><Link to="/property">For Sell</Link></li>
									</ul>
									<p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
								</div>
								<div className="product-meta-bottom">
									<span className="price">$ 80,650.00</span>
									<span>For sale</span>
									<span>1 year ago</span>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-product-wrap style-bottom">
								<div className="thumb">
									<img src={publicUrl + "assets/img/project/3.png"} alt="img" />
									<div className="product-wrap-details">
										<div className="media">
											<div className="author">
												<img src={publicUrl + "assets/img/author/3.png"} alt="img" />
											</div>
											<div className="media-body">
												<h6><Link to="/property">PreeSoft</Link></h6>
												<p><img src={publicUrl + "assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
											</div>
											<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
										</div>
									</div>
								</div>
								<div className="product-details-inner">
									<h4><Link to="/property-details">Daily Property</Link></h4>
									<ul className="meta-inner">
										<li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" />New York</li>
										<li><Link to="/property">For Sell</Link></li>
									</ul>
									<p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
								</div>
								<div className="product-meta-bottom">
									<span className="price">$ 80,650.00</span>
									<span>For sale</span>
									<span>1 year ago</span>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-product-wrap style-bottom">
								<div className="thumb">
									<img src={publicUrl + "assets/img/project/4.png"} alt="img" />
									<div className="product-wrap-details">
										<div className="media">
											<div className="author">
												<img src={publicUrl + "assets/img/author/4.png"} alt="img" />
											</div>
											<div className="media-body">
												<h6><Link to="/property">PreeSoft</Link></h6>
												<p><img src={publicUrl + "assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
											</div>
											<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
										</div>
									</div>
								</div>
								<div className="product-details-inner">
									<h4><Link to="/property-details">Farm House</Link></h4>
									<ul className="meta-inner">
										<li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" />New York</li>
										<li><Link to="/property">For Sell</Link></li>
									</ul>
									<p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
								</div>
								<div className="product-meta-bottom">
									<span className="price">$ 80,650.00</span>
									<span>For sale</span>
									<span>1 year ago</span>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-product-wrap style-bottom">
								<div className="thumb">
									<img src={publicUrl + "assets/img/project/5.png"} alt="img" />
									<div className="product-wrap-details">
										<div className="media">
											<div className="author">
												<img src={publicUrl + "assets/img/author/5.png"} alt="img" />
											</div>
											<div className="media-body">
												<h6><Link to="/property">PreeSoft</Link></h6>
												<p><img src={publicUrl + "assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
											</div>
											<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
										</div>
									</div>
								</div>
								<div className="product-details-inner">
									<h4><Link to="/property-details">Beach Side House</Link></h4>
									<ul className="meta-inner">
										<li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" />New York</li>
										<li><Link to="/property">For Sell</Link></li>
									</ul>
									<p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
								</div>
								<div className="product-meta-bottom">
									<span className="price">$ 80,650.00</span>
									<span>For sale</span>
									<span>1 year ago</span>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-product-wrap style-bottom">
								<div className="thumb">
									<img src={publicUrl + "assets/img/project/6.png"} alt="img" />
									<div className="product-wrap-details">
										<div className="media">
											<div className="author">
												<img src={publicUrl + "assets/img/author/6.png"} alt="img" />
											</div>
											<div className="media-body">
												<h6><Link to="/property">PreeSoft</Link></h6>
												<p><img src={publicUrl + "assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
											</div>
											<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
										</div>
									</div>
								</div>
								<div className="product-details-inner">
									<h4><Link to="/property-details">Gorgeous Hotel</Link></h4>
									<ul className="meta-inner">
										<li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" />New York</li>
										<li><Link to="/property">For Sell</Link></li>
									</ul>
									<p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
								</div>
								<div className="product-meta-bottom">
									<span className="price">$ 80,650.00</span>
									<span>For sale</span>
									<span>1 year ago</span>
								</div>
							</div>


						
						</div> */}
						</div>
					</div>

					{/* rent end */}

					{/* sale tab start */}
					<div
						className="tab-pane fade"
						id="sell1"
						role="tabpanel"
						aria-labelledby="sell1-tab"
					>
						<div className="row">
							{AllProperties.slice(0, 9).map((property, index) => (
								<div
									key={index}
									style={{ height: 400, width: 350 }}
									className="col-lg-4 col-md-6"
								>
									<div className="single-product-wrap style-bottom">
										<Link to="/property">
											<div className="thumb">
												<img
													style={{
														height: "195px",
														width: "350px",
													}}
													src={
														property.image
															? property.image.original_url
															: imgurl
													}
													alt="img"
												/>
												<div className="product-wrap-details">
													<div className="media">
														<div className="author">
															<img
																src={publicUrl + "assets/img/author/4.png"}
																alt="img"
															/>
														</div>
														<div className="media-body">
															<h6>
																<Link to="/property">
																	{property?.user?.name}
																</Link>
															</h6>
															<p>
																<img
																	src={
																		publicUrl +
																		"assets/img/icon/location-alt.png"
																	}
																	alt="img"
																/>
																{property?.title}
															</p>
														</div>
														<a className="fav-btn float-right" href="#">
															<i className="far fa-heart" />
														</a>
													</div>
												</div>
											</div>
										</Link>
										<div className="product-details-inner">
											<h4>
												<Link to="/property-details">Farm House</Link>
											</h4>
											<ul className="meta-inner">
												<li>
													<img
														src={publicUrl + "assets/img/icon/location2.png"}
														alt="img"
													/>
													{property?.city?.name}
												</li>
												<li>
													<Link to="/property">For Sell</Link>
												</li>
											</ul>
											<p>{property.description}</p>
										</div>
										<div className="product-meta-bottom">
											<span className="price">$ 80,650.00</span>
											<span>For sale</span>
											<span>1 year ago</span>
										</div>
									</div>
								</div>
							))}
							{/* <div className="col-lg-4 col-md-6">
							<div className="single-product-wrap style-bottom">
								<div className="thumb">
									<img src={publicUrl + "assets/img/project/5.png"} alt="img" />
									<div className="product-wrap-details">
										<div className="media">
											<div className="author">
												<img src={publicUrl + "assets/img/author/5.png"} alt="img" />
											</div>
											<div className="media-body">
												<h6><Link to="/property">PreeSoft</Link></h6>
												<p><img src={publicUrl + "assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
											</div>
											<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
										</div>
									</div>
								</div>
								<div className="product-details-inner">
									<h4><Link to="/property-details">Beach Side House</Link></h4>
									<ul className="meta-inner">
										<li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" />New York</li>
										<li><Link to="/property">For Sell</Link></li>
									</ul>
									<p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
								</div>
								<div className="product-meta-bottom">
									<span className="price">$ 80,650.00</span>
									<span>For sale</span>
									<span>1 year ago</span>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-product-wrap style-bottom">
								<div className="thumb">
									<img src={publicUrl + "assets/img/project/6.png"} alt="img" />
									<div className="product-wrap-details">
										<div className="media">
											<div className="author">
												<img src={publicUrl + "assets/img/author/6.png"} alt="img" />
											</div>
											<div className="media-body">
												<h6><Link to="/property">PreeSoft</Link></h6>
												<p><img src={publicUrl + "assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
											</div>
											<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
										</div>
									</div>
								</div>
								<div className="product-details-inner">
									<h4><Link to="/property-details">Gorgeous Hotel</Link></h4>
									<ul className="meta-inner">
										<li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" />New York</li>
										<li><Link to="/property">For Sell</Link></li>
									</ul>
									<p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
								</div>
								<div className="product-meta-bottom">
									<span className="price">$ 80,650.00</span>
									<span>For sale</span>
									<span>1 year ago</span>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-product-wrap style-bottom">
								<div className="thumb">
									<img src={publicUrl + "assets/img/project/1.png"} alt="img" />
									<div className="product-wrap-details">
										<div className="media">
											<div className="author">
												<img src={publicUrl + "assets/img/author/1.png"} alt="img" />
											</div>
											<div className="media-body">
												<h6><Link to="/property">PreeSoft</Link></h6>
												<p><img src={publicUrl + "assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
											</div>
											<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
										</div>
									</div>
								</div>
								<div className="product-details-inner">
									<h4><Link to="/property-details">Daily Apartment</Link></h4>
									<ul className="meta-inner">
										<li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" />New York</li>
										<li><Link to="/property">For Sell</Link></li>
									</ul>
									<p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
								</div>
								<div className="product-meta-bottom">
									<span className="price">$ 80,650.00</span>
									<span>For sale</span>
									<span>1 year ago</span>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-product-wrap style-bottom">
								<div className="thumb">
									<img src={publicUrl + "assets/img/project/2.png"} alt="img" />
									<div className="product-wrap-details">
										<div className="media">
											<div className="author">
												<img src={publicUrl + "assets/img/author/2.png"} alt="img" />
											</div>
											<div className="media-body">
												<h6><Link to="/property">PreeSoft</Link></h6>
												<p><img src={publicUrl + "assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
											</div>
											<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
										</div>
									</div>
								</div>
								<div className="product-details-inner">
									<h4><Link to="/property-details">Gorgeous Villa</Link></h4>
									<ul className="meta-inner">
										<li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" />New York</li>
										<li><Link to="/property">For Sell</Link></li>
									</ul>
									<p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
								</div>
								<div className="product-meta-bottom">
									<span className="price">$ 80,650.00</span>
									<span>For sale</span>
									<span>1 year ago</span>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6">
							<div className="single-product-wrap style-bottom">
								<div className="thumb">
									<img src={publicUrl + "assets/img/project/3.png"} alt="img" />
									<div className="product-wrap-details">
										<div className="media">
											<div className="author">
												<img src={publicUrl + "assets/img/author/3.png"} alt="img" />
											</div>
											<div className="media-body">
												<h6><Link to="/property">PreeSoft</Link></h6>
												<p><img src={publicUrl + "assets/img/icon/location-alt.png"} alt="img" />New York real estate </p>
											</div>
											<a className="fav-btn float-right" href="#"><i className="far fa-heart" /></a>
										</div>
									</div>
								</div>
								<div className="product-details-inner">
									<h4><Link to="/property-details">Daily Property</Link></h4>
									<ul className="meta-inner">
										<li><img src={publicUrl + "assets/img/icon/location2.png"} alt="img" />New York</li>
										<li><Link to="/property">For Sell</Link></li>
									</ul>
									<p>Lorem ipsum dolor consectetur iicing elit, sed do eius Lorem ipsum dolo amet, costur adipisicing eiusmod.</p>
								</div>
								<div className="product-meta-bottom">
									<span className="price">$ 80,650.00</span>
									<span>For sale</span>
									<span>1 year ago</span>
								</div>
							</div>
						</div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Property;
