import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../services/config';
import './blog.style.css'
const LatestNews = () => {

	let publicUrl = process.env.PUBLIC_URL + '/'

	// let imagealt = 'image'

	const [blogs, setblogs] = useState([])


	useEffect(() => {
		getBlogs()
	}, [])

	const getBlogs = () => {
		axios.get(`${baseUrl}/blogs`)
			.then(res => {
				console.log("blogs", res.data.payload)
				if (res.data) {
					setblogs(res.data.payload)
				}
			})
			.catch(err => {
				console.log(err)
			})
	}



	return (
		<div className="blog-area pd-bottom-90 ">
			<div className="container mb-3">
				<div className="section-title text-center mt-5">
					<h6>Blog &amp; News</h6>
					<h2>News Feeds</h2>
				</div>
				<div className="row justify-content-center flex-wrap-nowrap">

					{
						blogs.slice(0, 3).map((blog, index) => (
							<div key={index} className="blog-for-mobile col-lg-4 col-md-6 card-centring">
								<div style={{ height: 250, width: 350 }} className="single-product-wrap style-bottom-radius">
									<Link to={{
										pathname: `/blog-details`,
										state: {
											blogDetails: blog
										}
									}}>
										<div className="thumb">
											<img style={{ height: 250, width: 350 }} src={blog.images[0]?.original_url ? blog.images[0]?.original_url : publicUrl + "assets/img/project/10.png"} alt="img" />
										</div>
									</Link>
									<div className="product-details-inner">
										<ul className="meta-inner">
											<li><i className="fa fa-user" />By Admin</li>
											{/* <li><i className="fa fa-calendar-alt" />March 9 , 2020</li> */}
										</ul>
										<p><Link to={{
											pathname: `/blog-details`,
											state: {
												blogDetails: blog
											}
										}}>{blog.title.substr(0, 40) + '...'}</Link></p>
										{/* <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} dangerouslySetInnerHTML={{__html: `${blog.description}`}}></div> */}
										<Link className="btn btn-base read-more" to="/blog-details"><i className="la la-arrow-right" /></Link>
									</div>
								</div>
							</div>

						))
					}
					{/* <div className="col-lg-4 col-md-6">
						<div className="single-product-wrap style-bottom-radius">
							<div className="thumb">
								<img src={publicUrl + "assets/img/project/11.png"} alt="img" />
							</div>
							<div className="product-details-inner">
								<ul className="meta-inner">
									<li><i className="fa fa-user" />By Admin</li>
									<li><i className="fa fa-calendar-alt" />Marce 9 , 2020</li>
								</ul>
								<h4><Link to="/blog-details">Daily Apartment</Link></h4>
								<p>Daily ipsum dolor  consectetur adipisicing elit, sed do eius Lorem ipsum dolo ame conse.</p>
								<Link className="btn btn-base read-more" to="/blog-details"><i className="la la-arrow-right" /></Link>
							</div>
						</div>
					</div>
					<div className="col-lg-4 col-md-6">
						<div className="single-product-wrap style-bottom-radius">
							<div className="thumb">
								<img src={publicUrl + "assets/img/project/12.png"} alt="img" />
							</div>
							<div className="product-details-inner">
								<ul className="meta-inner">
									<li><i className="fa fa-user" />By Admin</li>
									<li><i className="fa fa-calendar-alt" />Marce 9 , 2020</li>
								</ul>
								<h4><Link to="/blog-details">Hotel Resort</Link></h4>
								<p>Resort ipsum dolor  consectetur adipisicing elit, sed do eius Lorem ipsum dolo ame conse.</p>
								<Link className="btn btn-base read-more" to="/blog-details"><i className="la la-arrow-right" /></Link>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</div>

	)

}

export default LatestNews;
